import { createState, useState, self } from '@hookstate/core';
import { useChimeContext, MeetingStatusCode } from "../context/ChimeContext";
import { detect } from "detect-browser";
import DeviceType from '../types/DeviceType';
import { useEffect } from 'react';
import { defaultLogger as logger } from "../../globalStates/AppState";

// used to store user's selected device in localStorage
const AudioInputStorageKey = "virtualGuide-audioInput";
const AudioOutputStorageKey = "virtualGuide-audioOutput";
const VideoInputStorageKey = "virtualGuide-videoInput";

const browserCheckResult = detect()
const isFirefox = browserCheckResult && browserCheckResult.type === "browser" && browserCheckResult.name === "firefox"

interface DeviceState {
  videoInputDevices: MediaDeviceInfo[]
  audioInputDevices: MediaDeviceInfo[]
  audioOutputDevices: MediaDeviceInfo[]
  currentVideoInputDevice: DeviceType | null
  currentAudioInputDevice: DeviceType | null
  currentAudioOutputDevice: DeviceType | null
}

const state = createState({
  videoInputDevices: [],
  audioInputDevices: [],
  audioOutputDevices: [],
  currentVideoInputDevice: null,
  currentAudioInputDevice: null,
  currentAudioOutputDevice: null,
} as DeviceState)

let initialized = false
export const useDevices = state[self].map(s => () => {
  const state = useState(s)
  const chime = useChimeContext();
  const meetingStatus = chime.getMeetingStatus().meetingStatus

  useEffect(() => {
    if (meetingStatus !== MeetingStatusCode.Succeeded)
      return
    if (state[self].get().currentAudioInputDevice)
      chime.chooseAudioInputDevice(state[self].get().currentAudioInputDevice!.value)
    if (state[self].get().currentAudioOutputDevice)
      chime.chooseAudioOutputDevice(state[self].get().currentAudioOutputDevice!.value)
    if (state[self].get().currentVideoInputDevice)
      chime.chooseVideoInputDevice(state[self].get().currentVideoInputDevice!.value)
  },
    // eslint-disable-next-line
    [meetingStatus])

  return ({
    currentAudioInputDevice: () => { return state[self].get().currentAudioInputDevice },
    currentAudioOutputDevice: () => { return state[self].get().currentAudioOutputDevice },
    currentVideoInputDevice: () => { return state[self].get().currentVideoInputDevice },
    audioInputDevices: () => { return state[self].get().audioInputDevices },
    audioOutputDevices: () => { return state[self].get().audioOutputDevices },
    videoInputDevices: () => { return state[self].get().videoInputDevices },
    setAudioInputDevice: (deviceId: string, label: string) => {
      state[self].set(prevState => {
        prevState.currentAudioInputDevice = { value: deviceId, label: label }
        return prevState
      })
      localStorage.setItem(AudioInputStorageKey, deviceId)
      chime.chooseAudioInputDevice(deviceId)
    },
    setAudioOutputDevice: (deviceId: string, label: string) => {
      state[self].set(prevState => {
        prevState.currentAudioOutputDevice = { value: deviceId, label: label }
        return prevState
      })
      localStorage.setItem(AudioOutputStorageKey, deviceId)
      chime.chooseAudioOutputDevice(deviceId)
    },
    setVideoInputDevice: (deviceId: string, label: string) => {
      state[self].set(prevState => {
        prevState.currentVideoInputDevice = { value: deviceId, label: label }
        return prevState
      })
      localStorage.setItem(VideoInputStorageKey, deviceId)
      chime.chooseVideoInputDevice(deviceId)
    },
    ensureDevices: async () => {
      if (initialized)
        return
      const videoInputDevices: MediaDeviceInfo[] = []
      const audioInputDevices: MediaDeviceInfo[] = []
      const audioOutputDevices: MediaDeviceInfo[] = []
      let currentVideoInputDevice: DeviceType | null = null
      let currentAudioInputDevice: DeviceType | null = null
      let currentAudioOutputDevice: DeviceType | null = null
      try {
        const astream = await navigator.mediaDevices.getUserMedia({ audio: true })
        astream.getTracks().forEach((x) => x.stop())
      } catch (e) {
        logError(e)
      }
      let noVideo = false
      try {
        const vstream = await navigator.mediaDevices.getUserMedia({ video: true })
        vstream.getTracks().forEach((x) => x.stop())
      } catch (e) {
        noVideo = true
        logError(e)
      }
      try {
        const data = await navigator.mediaDevices.enumerateDevices()
        const dataFilter = data.filter((x) => x.label !== "")
        if (dataFilter.length) {
          videoInputDevices.push(...dataFilter.filter((x) => x.kind === "videoinput"))
          audioInputDevices.push(...dataFilter.filter((x) => x.kind === "audioinput"))
          audioOutputDevices.push(...dataFilter.filter((x) => x.kind === "audiooutput"))
        }
      } catch (e) {
        logError(e)
      }

      if (audioInputDevices.length) {
        const audioInputStorage = localStorage.getItem(AudioInputStorageKey)
        const aidIndex = audioInputDevices.findIndex((x) => x.deviceId === audioInputStorage && x.kind === "audioinput")
        const aidSelected = aidIndex === -1 ? 0 : aidIndex
        if (audioInputDevices[aidSelected])
          currentAudioInputDevice = { value: audioInputDevices[aidSelected].deviceId, label: audioInputDevices[aidSelected].label }
      }

      if (videoInputDevices.length && !noVideo) {
        const videoInputStorage = localStorage.getItem(VideoInputStorageKey)
        const vidIndex = videoInputDevices.findIndex((x) => x.deviceId === videoInputStorage && x.kind === "videoinput")
        const vidSelected = vidIndex === -1 ? 0 : vidIndex
        if (videoInputDevices[vidSelected])
          currentVideoInputDevice = { value: videoInputDevices[vidSelected].deviceId, label: videoInputDevices[vidSelected].label }
          localStorage.setItem(VideoInputStorageKey, currentVideoInputDevice?.value!)
      }

      if (!isFirefox && audioOutputDevices.length) {
        const audioOutputStorage = localStorage.getItem(AudioOutputStorageKey)
        const aodIndex = audioOutputDevices.findIndex((x) => x.deviceId === audioOutputStorage && x.kind === "audiooutput")
        const aodSelected = aodIndex === -1 ? 0 : aodIndex
        if (audioOutputDevices[aodSelected])
          currentAudioOutputDevice = { value: audioOutputDevices[aodSelected].deviceId, label: audioOutputDevices[aodSelected].label }
      }

      if (initialized)
        return
      initialized = true
      state[self].set({
        videoInputDevices: videoInputDevices,
        audioInputDevices: audioInputDevices,
        audioOutputDevices: audioOutputDevices,
        currentVideoInputDevice: currentVideoInputDevice,
        currentAudioInputDevice: currentAudioInputDevice,
        currentAudioOutputDevice: currentAudioOutputDevice,
      })
    }
  })
})
function logError(error: Error) {
  // eslint-disable-next-line
  console.error(error);
  // hopefully this works enough
  logger.error({ message: "ChimeSdkWrapper ", error })
};