import moment, { Moment } from "moment";
import 'moment-timezone'
import { EventDate } from "./backendServices/Types";
import * as _ from "lodash"

export const eventTimezoneName = 'Europe/Berlin'

export interface Timezone {
  timezones: string[]
  utcDifference: number
  name: string
}

export function isToday(dateMoment: Moment): boolean {
  const today = moment.tz(eventTimezoneName).startOf("day")
  return dateMoment.isSame(today, "d")
}

export function isYesterday(dateMoment: Moment): boolean {
  const yesterday = moment.tz(eventTimezoneName).subtract(1, "days").startOf("day")
  return dateMoment.isSame(yesterday, "d")
}

export function isTomorrow(dateMoment: Moment): boolean {
  const tomorrow = moment.tz(eventTimezoneName).add(1, "days").startOf("day")
  return dateMoment.isSame(tomorrow, "d")
}

export function isAfterToday(dateMoment: Moment): boolean {
  const today = moment.tz(eventTimezoneName).startOf("day")
  return dateMoment.isAfter(today, "d")
}

export function isBeforeToday(dateMoment: Moment): boolean {
  const today = moment.tz(eventTimezoneName).startOf("day")
  return dateMoment.isBefore(today, "d")
}

export const getTimezoneOffest = () => {
  const localTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
  const eventTimeOffset = (moment.tz(eventTimezoneName) as unknown as { _offset: number })._offset
  const localTimeOffset = localTimezoneName ? (moment.tz(localTimezoneName) as unknown as { _offset: number })._offset : moment(moment.tz(moment.tz.guess()).format()).utcOffset()

  let timezoneMinutesDifference = Math.abs(eventTimeOffset - localTimeOffset)

  if (eventTimeOffset > localTimeOffset)
    timezoneMinutesDifference = -timezoneMinutesDifference

  return timezoneMinutesDifference
}

export function momentWithoutTimezoneFromTimezonedMoment(momentWithTimezone: Moment, timezone: string): Moment {
  return moment(momentWithTimezone.tz(timezone).format('YYYY-MM-DDTHH:mm:ss'))
}

let timeZones: Timezone[] = []
export function getTimezones(): Timezone[] {
  if (timeZones.length > 0)
    return timeZones
  var momentTimezones = moment.tz.names().filter(tz => tz.indexOf("/") > -1 && tz.indexOf("GMT") < 0 && tz.indexOf("Etc") < 0)
  var timezones = _.groupBy(momentTimezones.map(mTz => {
    const hourDifference = (moment().tz("Europe/London").utcOffset() + moment().tz(mTz).utcOffset()) / 60
    return { utcDifference: hourDifference, timezone: mTz, area: mTz.slice(0, mTz.indexOf("/")) }
  }), item => { return [item.area, item.utcDifference] })
  var timezonesGrouped: Timezone[] = []
  _.forEach(timezones, tzGroupedList => {
    var groupedTimezones: string[] = []
    tzGroupedList.forEach(it => groupedTimezones.push(it.timezone))
    var names = groupedTimezones.map(it => it.slice(it.indexOf("/") + 1))
    timezonesGrouped.push({ timezones: groupedTimezones, utcDifference: tzGroupedList[0].utcDifference, name: tzGroupedList[0].area + ` UTC(${tzGroupedList[0].utcDifference > 0 ? "+" : ""}${tzGroupedList[0].utcDifference}) - ` + names })
  })
  timeZones = timezonesGrouped.sort((a, b) => a.utcDifference > b.utcDifference ? 1 : -1)
  return timeZones
}

export function findTimezoneName(timezoneName: string): string {

  return getTimezones().find(it => it.timezones.indexOf(timezoneName) > -1)?.name ?? ""

}

export function isEventDateLive(eventdate: EventDate) {
  const localTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
  const now = momentWithoutTimezoneFromTimezonedMoment(moment(), localTimezoneName)
  const start = moment(eventdate.date + " " + eventdate.start)
  const end = moment(eventdate.date + " " + eventdate.end)

  if (now.isBetween(start, end))
    return true
  return false
}

export function getTimeToLiveString(eventDate: EventDate, strings: any, language: string) {
  if (isEventDateLive(eventDate)) {
    return strings.receptionPage.nowLive;
  } else if (isTomorrow(moment(eventDate.date))) {
    return strings.receptionPage.liveTomorrow;
  } else if (isToday(moment(eventDate.date))) {
    const now = moment.tz(eventTimezoneName)
    const start = moment(eventDate.date + " " + eventDate.start)
    const duration = moment.duration(start.diff(now))
    return "Live " + duration.locale(language).humanize(true)
  } else {
    return strings.receptionPage.liveOn + " " + moment(eventDate.date).format(strings.eventTiming.eventDaysFormatPatternShort);
  }
}