
import { saveAs } from 'file-saver'

import { EventAttributes, createEvents } from 'ics'
import { CalendarEntry } from '../backendServices/BackendServices'
import { LocalizedString } from "../branding/branding";
import { defaultLogger as logger } from "../globalStates/AppState"

export interface CalendarEntryExprtBranding {
    organizer: LocalizedString
    attendees: LocalizedString
}
export const saveEvents = (strings: any, meetings: CalendarEntry[]) => {
    const events = meetings.map(x => toEvent(strings, x))
    const name = (meetings.length === 1 ? meetings[0].title : 'meetings') + '.ics'
    const result = createEvents(events)
    if (result.error) {
        logger.error("ICal saveEvents", result.error)
    } else {
        logger.info("ICal saveEvents", result.value)
        const blob = new Blob([result.value!], { type: "text/calendar" })
        saveAs(blob, name)
    }
}

const toEvent: ((strings: any, meeting: CalendarEntry) => EventAttributes) = (strings, meeting) => {
    const start = new Date(meeting.start)
    const end = new Date(meeting.end)
    var description = strings.organizer + ': ' + meeting.user.name + '\n\n' + strings.attendees

    meeting.participants.items.filter(x => x.userId !== meeting.userId).forEach(x => description += '\n' + x.user.name)
    if (meeting.description) {
        description += '\n\n' + meeting.description
    }

    const event: EventAttributes = {
        start: [start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()],
        startInputType: 'utc',
        end: [end.getUTCFullYear(), end.getUTCMonth() + 1, end.getUTCDate(), end.getUTCHours(), end.getUTCMinutes()],
        endInputType: 'utc',
        title: meeting.title,
        description: description
    }
    return event
}
