import { createState, useState, self } from '@hookstate/core';
import { appStrings } from '../AppStrings';
import branding from '../branding/branding';

const localStorageLanguageKey = 'virtualGuide-language'

export function getActiveLanguage(): string {
  var language;
  try {
    language = localStorage.getItem(localStorageLanguageKey)
  } catch (securityError) {
    // No Cookies! Oh noes! Nothing works!
  }
  if (!language) {
    language = navigator.language.slice(0, 2)
  }

  const availableLanguages = branding.availableLanguages
  if (availableLanguages.indexOf(language) > -1) {
    return language
  } else {
    return availableLanguages[0]
  }
}

let strings = {
  availableLanguages: branding.availableLanguages,
  language: getActiveLanguage(),
  strings: appStrings
}

export const useLanguageState = createState(strings)[self].map(s => () => {
  const state = useState(s)
  return ({
    setLanguage: (language: string) => {
      state[self].set(prevState => {
        if (prevState.availableLanguages.indexOf(language) > -1) {
          prevState.language = language
        }
        try {
          localStorage.setItem(localStorageLanguageKey, prevState.language)
        } catch (error) {
          // No local storage :( can not save language
        }
        return prevState
      })
    },
    getLanguage: () => {
      return state[self].value.language
    },
    getStrings: () => {
      const language = state[self].value.language
      const languageIndex = branding.availableLanguages.indexOf(language)
      const strings = state[self].value.strings
      if (languageIndex >= 0) {
        return strings[languageIndex]
      }
      return strings[0]
    },
    getAvailableLanguages: () => {
      return branding.availableLanguages
    }
  })
})

export function replacePlaceholder(text: string, replacements: { [placeholderKey: string]: string }) {
  if (!replacements || text.indexOf("${") < 0)
    return text
  let newText = text
  for (const key in replacements) {
    if (replacements[key])
      newText = newText.replaceAll("${" + key + "}", replacements[key])
  }
  return newText
}
