import { self } from '@hookstate/core';
import React, { useEffect, useState, Suspense } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { ThemeProvider } from 'styled-components';
import './App.css';
import {
  BackendServiceError,
  createAppDevice,
  DataPrivacyDoc,
  getDataPrivacyDocs,
  TokenResponse,
  updateUserValues,
  loadUserData,
  SpeakerResponse, getPresenceByUserId, UserResponse, getCategoriesByTopic
} from './backendServices/BackendServices';

// https://blog.agney.dev/styled-components-&-typescript/
import { useLoggedInState } from './globalStates/LoggedInUser'
import { useAppState } from './globalStates/AppState'
import { useThemeState } from './globalStates/Theme'
import ContentAreaErrorBoundary from './contentArea/errorPages/ContentAreaErrorBoundary'
import { useLanguageState } from './globalStates/LanguageState'
import CenteredLoader from './ui/CenteredLoader'
import { useNotificationContext } from './conference/context/NotificationContext'
import { detect } from "detect-browser";

import Amplify, { Auth } from 'aws-amplify'
import SideIconBar from './navigationArea/SideIconBar/SideIconBar';
import NotificationCenter from './ui/NotificationCenter';
import ConferenceOverlay from "./conference/ConferenceOverlay";
import awsmobile from "./aws-exports";
import { EventType, useUsersPresence } from './globalStates/ConnectedUsers';
import { videoRoomPageRoute } from './navigationArea/RoutePaths';
import { useRouteMatch, useHistory } from 'react-router-dom';
import PictureInPictureVideoPlayer from './contentArea/videoPlayer/PictureInPictureVideoPlayer';
import branding from './branding/branding';
import { useCategoriesState } from './globalStates/CategoriesState';
import Alert from './ui/CrsAlert';
import { useAlertState } from './globalStates/AlertState';

// Amplify.Logger.LOG_LEVEL = 'DEBUG'
Amplify.configure({
  ...awsmobile,
  Auth: {
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: true
  },
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        // use the id token instead of the access token to be able to access the users' custom claims like 'custom:topic'
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  }
})

const LoginRegistrationSite = React.lazy(() => import('./contentArea/loginregistration/LoginRegistrationSite'))
const CommunicationArea = React.lazy(() => import('./communicationArea/CommunicationArea'))
const ContentArea = React.lazy(() => import('./contentArea/ContentArea'))

interface AppSiteProps {
  logout: (beConnectionToken: string) => void
  profileId?: string
}
const AppSiteRoot = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;

  .shadow_method{
  background-color: rgb(18, 176, 41);
  box-shadow:inset 0 0 0 99999px rgba(255,255,255,0.2);
}∏
.shadow_method:hover{
  box-shadow:none;
}

`

const DarkenOverlay = styled.div`
  display: flex;
  position: absolute;
  opacity: 0.3;
  background-color: ${branding.darkenOverlayColor ?? "black"};
  width: 100%;
  height: 100%;
  z-index: 1;

  & > *{
    flex-shrink: 0;
  }
`
const USER_REFRESH_INTERVAL = 5 * 60 * 1000

const AppSite: React.FC<AppSiteProps> = (props) => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [trigger, setTrigger] = useState(0)
  const timeIntervalWrite = 5 * 60 * 1000
  const languageState = useLanguageState()
  const strings = languageState.getStrings()
  const [receptionRoute, setReceptionRoute] = useState(window.location.pathname === "/" ? "LOBBY" : strings.receptionPage.receptionPageMyHeaderTitle)
  const browserInfo = detect()
  const audioFileFormat = (browserInfo?.name === "safari") ? "caf" : "opus"
  const notificationContext = useNotificationContext()
  const appState = useAppState()
  const userPresence = useUsersPresence()
  const userLink = useLoggedInState()
  const categoriesLink = useCategoriesState()
  const history = useHistory()


  useEffect(() => {
    const refreshUserDataInterval = setInterval(() => {
      if (userLink.user() && userLink.user()!.profileId) {
        loadUserData({ loggedInUserId: userLink.user()!.profileId, targetProfileId: userLink.user()!.profileId }).then((res) => {
          if ((res as BackendServiceError).httpStatus) {
            // TODO error handling
          } else {
            userLink.setType((res as SpeakerResponse).content.type)
          }
        })
      }
    }, USER_REFRESH_INTERVAL)
    if (userLink.user() && userLink.user()!.profileId && !isUserEdited()) {
      history.push("/myprofile?mode=EDIT")
    }
    // Fetching categories info and storing them inside of the global state
    getCategories()
    return () => clearInterval(refreshUserDataInterval)
    // eslint-disable-next-line
  }, [])

  const getCategories = () => {
    getCategoriesByTopic().then(res => {
      categoriesLink.setCategoriesState({ categories: res.content })
    })
  }

  function isUserEdited(): boolean {
    if (userLink.user()?.firstName !== undefined && userLink.user()?.lastName !== undefined) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {

    const intervalAlive = setInterval(async () => {
      updateUserValues({ id: props.profileId!, lastConnected: new Date().toISOString() })
    }, timeIntervalWrite)

    if (props.profileId)
      return () => clearInterval(intervalAlive)
  }, [props.profileId]) //eslint-disable-line

  useEffect(() => {
    appState.setIsMyHandRaised(false)
    appState.setMissedCallNotification(false, "", "")
    appState.setShowBookmarkNotification(false)

    if (props.profileId) {
      notificationContext.init(props.profileId)
      return () => { notificationContext.unsubscribe() }
    }
  }, [props.profileId]) //eslint-disable-line

  useEffect(() => {
    const abortController = new AbortController();
    if (props.profileId) {
      getPresenceByUserId(props.profileId).then(result => {
        if (!abortController.signal.aborted) {
          if (result as UserResponse)
            userPresence.updatePresenceState(props.profileId as string, EventType.INIT)
        }
      })
    }
    return () => abortController.abort()
  }, [props.profileId]) //eslint-disable-line


  // Picture-In-Picture-Video-Player properties
  const controls: boolean = true
  const isVideoPage = useRouteMatch(videoRoomPageRoute)
  const channel = appState.liveStreamChannel

  return (
    <AppSiteRoot lang={languageState.getLanguage()}>

      <ConferenceOverlay audioFileFormat={audioFileFormat} />
      {channel && !isVideoPage && <PictureInPictureVideoPlayer channel={channel} controls={controls}></PictureInPictureVideoPlayer>}

      <SideIconBar navToggle={(open) => setNavbarOpen(open)} isNavOpen={navbarOpen} myToggle={receptionRoute} receptionPageToggle={(route) => setReceptionRoute(route)} setTrigger={(value) => setTrigger(value)} />
      <ContentAreaErrorBoundary>
        {navbarOpen && <DarkenOverlay />}
        {props.profileId && <ContentArea profileId={props.profileId!} changeRoute={(route) => setReceptionRoute(route)} receptionRoute={receptionRoute} trigger={trigger} />}
      </ContentAreaErrorBoundary>
      <CommunicationArea logout={props.logout} />
      <NotificationCenter receptionPageToggle={(route) => setReceptionRoute(route)} setTrigger={(value) => setTrigger(value)} />
    </AppSiteRoot>
  )
}

interface SiteProps {
  loggedIn: boolean,
  dataPrivacyDoc: DataPrivacyDoc | undefined,
  loadingStatus: AppLoadingStatus
}


function Site(props: SiteProps) {
  const userLink = useLoggedInState()

  // FIXME? Site is wrapped in CSS Transition. props change only after the transition is done. But this is to late. If we do it after the transition react renders the whole start page below the transition after a logout!
  if (userLink.isLoggedIn) {
    return <AppSite logout={(beConnectionToken) => userLink.setUserState({ jwtToken: beConnectionToken, user: undefined })} profileId={userLink.user()?.profileId!} />
  } else {
    return <LoginRegistrationSite dataPrivacyDoc={props.dataPrivacyDoc} loadingStatus={props.loadingStatus} />
  }
}

const StyledTransitionGroup = styled(TransitionGroup)`
  position: relative;
  width: 100%;
  height: 100%;
`

export enum AppLoadingStatus {
  LOADING,
  SUCCESS,
  FAILURE
}


interface AppProps {
}
const App: React.FC<AppProps> = (props) => {
  const size = useWindowSize();
  const themeLink = useThemeState(size.width)
  const userLink = useLoggedInState()
  const languageState = useLanguageState()
  const [dataPrivacy, setDataPrivacy] = useState<DataPrivacyDoc | undefined>(undefined)
  const [loadingStatus, setLoadingStatus] = useState<AppLoadingStatus>(AppLoadingStatus.LOADING)
  const alertState = useAlertState();


  useEffect(() => {
    (async () => {
      try {
        if (!userLink.jwtToken()) {
          const resp = await createAppDevice()
          if ((resp as BackendServiceError).httpStatus) {
            setLoadingStatus(AppLoadingStatus.FAILURE)
            return
          } else {
            const tokenResp = resp as TokenResponse
            userLink.updateToken(tokenResp.beConnectionToken)
          }
        }

        if (!userLink.isLoggedIn) {
          const resp = await getDataPrivacyDocs()
          if ((resp as BackendServiceError).httpStatus) {
            setLoadingStatus(AppLoadingStatus.FAILURE)
            return
          } else {
            setDataPrivacy(resp as DataPrivacyDoc)
          }
        }

        setLoadingStatus(AppLoadingStatus.SUCCESS)
      } catch {
        setLoadingStatus(AppLoadingStatus.FAILURE)
      }
    })();
  }, [languageState.getLanguage(), userLink.jwtToken()]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={themeLink[self].get()}>
      <StyledTransitionGroup>
        <CSSTransition key={`${userLink.isLoggedIn}`}
          classNames="siteAnimation"
          timeout={1600}>
          <Suspense fallback={<CenteredLoader></CenteredLoader>}>
            {alertState.isAlertActive() && <Alert />}
            <Site loggedIn={userLink.isLoggedIn} dataPrivacyDoc={dataPrivacy} loadingStatus={loadingStatus} />
          </Suspense>
        </CSSTransition>
      </StyledTransitionGroup>
    </ThemeProvider>
  )
}

export default App;


function useWindowSize() {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

