import { createState, useState, self } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import moment from "moment";
import { EntityType } from '../backendServices/Types';

type State = {
    [key in EntityType]: { [key: string]: { fav: boolean; lastChange: Date | string; }; };
} & {
    lastSyncTime: string;
};

function getPrefix(type: EntityType) {
    switch (type) {
        case "organization":
            return "id_orga_"
        case "product":
            return "id_prod_"
        case "person":
            return "id_pers_"
        case "eventdate":
            return "id_evtd_"
        case "trademark":
            return "id_trad_"
        case "news":
            return "id_news_"
        default:
            break;
    }
}

function replacer(key: string, value: string) {
    if (key === "lastChange")
        return undefined;
    return value;
}

export const useFavoriteState = createState(({} as State))[self].map(s => () => {
    const state = useState(s)
    state[self].attach(Persistence('virtualGuide-favorites'))

    return ({
        is: (type: EntityType, id: string) => {
            return state[self].get()[type] && state[self].get()[type][id] && state[self].get()[type][id].fav
        },
        add: (type: EntityType, id: string) => {
            state[self].set(prevState => {
                if (!prevState[type])
                    prevState[type] = {}

                prevState[type][id] = { fav: true, lastChange: new Date().toISOString() }
                return prevState
            })
        },
        remove: (type: EntityType, id: string) => {
            state[self].set(prevState => {
                if (!prevState[type])
                    prevState[type] = {}

                prevState[type][id] = { fav: false, lastChange: new Date().toISOString() }
                return prevState
            })
        },
        toggle: (type: EntityType, id: string) => {
            state[self].set(prevState => {
                if (!prevState[type])
                    prevState[type] = {}
                const currentFav = prevState[type][id] && prevState[type][id].fav

                prevState[type][id] = { fav: !currentFav, lastChange: new Date().toISOString() }
                return prevState
            })
        },
        get: (type: EntityType) => {
            let tempArray: Array<string> = [];
            let favorites = state[self].get()[type]
            if (favorites) {
                const jsonString = JSON.stringify(favorites, replacer);
                const jsonObject = JSON.parse(jsonString);
                var prefix = getPrefix(type);

                for (var key in jsonObject) {
                    if (jsonObject.hasOwnProperty(key)) {
                        if (state[self].get()[type][key].fav)
                            tempArray.push(prefix + key);
                    }
                }

                return tempArray.join(",");
            }
            return "";
        },
        getLastSyncTime: () => {
            const lastSyncTime = state[self].get().lastSyncTime
            return lastSyncTime ? lastSyncTime : moment(new Date(+0)).format("YYYY-MM-DD HH:mm:ss")
        },
        setLastSyncTime: (lastSyncTime: Date) => {
            state[self].set(prevState => {
                prevState.lastSyncTime = moment(lastSyncTime).format("YYYY-MM-DD HH:mm:ss")
                return prevState
            })
        },
        reset: () => {
            state[self].set(prevState => {
                prevState = {} as State
                return prevState
            })
        }
    })
})
