import styled from "styled-components"
import { useLanguageState } from "../globalStates/LanguageState"
import React from "react"
import branding from "../branding/branding"


const LanguageTogglerRoot = styled.div<{ color?: string, fontSize?: string, margin?: string }>`
    display: inline-block;
    font-size: ${props => props.fontSize ?? "1.4rem"};
    text-transform: uppercase;
    margin: ${props => props.margin ?? "0 1rem"};
    color: ${props => props.color ?? "#fff"};
    cursor: pointer;
    font-family: ${branding.font1};
    user-select: none;
`

interface LanguageTogglerProps {
    color?: string
    fontSize?: string
    margin?: string
    fullToggler?: boolean
}

const LanguageToggler: React.FC<LanguageTogglerProps> = (props) => {
    const languageState = useLanguageState()
    const availableLanguages = languageState.getAvailableLanguages()
    const selectedLanguage = languageState.getLanguage()

    let languageToShow = ""
    if (selectedLanguage === availableLanguages[0]) {
        if (availableLanguages[1]) {
            languageToShow = availableLanguages[1]
        }
        else {
            languageToShow = availableLanguages[0]
        }
    }
    else {
        languageToShow = availableLanguages[0]
    }

    const toggleLanguage = (e: any) => {
        let selectedLanguageIndex = availableLanguages.findIndex(l => l === selectedLanguage)
        if (availableLanguages[selectedLanguageIndex + 1]) {
            languageToShow = availableLanguages[selectedLanguageIndex - 1]
            languageState.setLanguage(availableLanguages[selectedLanguageIndex + 1])
        } else {
            languageState.setLanguage(availableLanguages[0])
            languageToShow = availableLanguages[1]
        }

        e.stopPropagation()
    }
    if (availableLanguages && availableLanguages.length > 1) {
        return (
            <>
                {!props.fullToggler &&
                    <LanguageTogglerRoot onClick={(e: any) => toggleLanguage(e)} color={props.color} fontSize={props.fontSize} margin={props.margin}>
                        <span style={{ fontWeight: "bold" }}>{languageToShow} </span>
                    </LanguageTogglerRoot>
                }
                {props.fullToggler &&
                    <LanguageTogglerRoot onClick={(e: any) => toggleLanguage(e)} color={props.color} fontSize={props.fontSize} margin={props.margin}>
                        <span style={{ fontWeight: "bold" }}>{languageToShow} </span> / {languageToShow === availableLanguages[0] ? availableLanguages[1] : availableLanguages[0]}
                    </LanguageTogglerRoot>
                }
            </>
        )
    } else {
        return (
            <>
            </>
        )
    }
}
export default LanguageToggler
