import { createState, self, State, useState } from '@hookstate/core';
import { MeetingSessionStatus, VideoTileState, DataMessage } from 'amazon-chime-sdk-js';
import ChimeSdkWrapper from '../ChimeSdkWrapper';
import ViewMode from '../enums/ViewMode';
import RosterType from '../types/RosterType';
import RosterAttendeeType, { rosterAttendeeTypeEquals } from '../types/RosterAttendeeType';
import { BackendServiceError, MeetingKind, startLive, ChannelResponse, stopLive, getChannelInfo, lockChannel, unlockChannel, StopReason } from '../../backendServices/BackendServices';
import { useHistory } from 'react-router-dom';
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from '../../globalStates/LoggedInUser';
import { useEffect } from 'react';
import { defaultLogger as logger } from "../../globalStates/AppState"
import DeviceType from '../types/DeviceType';
import { useContactState } from '../../communicationArea/ContactState'
import branding from '../../branding/branding';
import { useAlertState } from "../../globalStates/AlertState";
import { CrsAlertType } from "../../ui/CrsAlert";
import EGActiveSpeakerPolicy from './EGActiveSpeakerPolicy';
import { throttle } from 'lodash';

const sdk = new ChimeSdkWrapper()

enum DataMessageType {
    KICK = 1,
    BAN = 2,
    MUTE = 3,
    RAISEHAND = 4,
    CHANNEL_STATUS = 5,
    TIMELIMITCHANGED = 6,
}

export enum ChannelStatus {
    PREPARING = 1,
    ON_AIR = 2,
    OFF_AIR = 3
}


export enum LocalVideoStatus {
    Disabled = 1,
    Loading = 2,
    Enabled = 3
}

export enum MeetingStatusCode {
    Loading = 1,
    Succeeded = 2,
    Disconnected = 3,
    Failed = 4,
    Full = 5,
    TimeUp = 6,
    Kicked = 7,
    Banned = 8,
    Ended = 9,
    GreenroomLive = 10,
}

export interface MeetingStatus {
    meetingStatus: MeetingStatusCode
    errorMessage?: string
}

export interface ShallowVideoTileState {
    tileId: number | null
    isContent: boolean
    localTile: boolean
    boundAttendeeId: string | null
    active: boolean
}

interface StateValues {
    kind: MeetingKind
    name: string
    viewMode: ViewMode
    meetingStatus: MeetingStatus
    muted: boolean
    mutedByMod: boolean
    handRaised: boolean
    hasWebcam: boolean
    meetingSecondsLeft: number | null
    screensharing: boolean
    volume: number
    shareScreenTile: ShallowVideoTileState | null
    localTile: ShallowVideoTileState | null
    localVideoStatus: LocalVideoStatus
    roster: { [attendeeId: string]: RosterAttendeeType }
    activeSpeakers: string[]
    remoteTiles: ShallowVideoTileState[]
    meetingChangeAccepted: boolean
    channelStatus?: ChannelStatus
    isLocked: boolean
}

const getStartValues = (): StateValues => {
    return {
        name: "",
        kind: "virtualCafe",
        muted: false,
        mutedByMod: false,
        handRaised: false,
        hasWebcam: false,
        meetingSecondsLeft: null,
        volume: 100,
        localVideoStatus: LocalVideoStatus.Disabled,
        shareScreenTile: null,
        screensharing: false,
        localTile: null,
        remoteTiles: [],
        viewMode: ViewMode.Room,
        meetingStatus: { meetingStatus: MeetingStatusCode.Loading },
        roster: {},
        activeSpeakers: [],
        meetingChangeAccepted: false,
        channelStatus: undefined,
        isLocked: false
    }
}
const state = createState<StateValues>(getStartValues())
let timeUpIntervallId: number | null


export interface ChimeContext {
    getExternalMeetingId: () => string | null,
    getName: () => string,
    getKind: () => MeetingKind,
    getViewMode: () => ViewMode,
    getMeetingStatus: () => MeetingStatus,
    getTimeLeft: () => number | null,
    getMaxDuration: () => number | null,
    isScreenShareEnabled: () => boolean,
    isLocalScreenSharingStarted: () => boolean,
    isMod: () => boolean,
    getShareScreenTile: () => ShallowVideoTileState | null,
    getLocalTile: () => ShallowVideoTileState | null,
    getLocalVideoStatus: () => LocalVideoStatus,
    getRemoteTiles: () => ShallowVideoTileState[],
    isMuted: () => boolean,
    isMutedByMod: () => boolean,
    setMutedByMod: (mutedByMod: boolean) => void,
    isHandRaised: () => boolean,
    getVolume: () => number,
    setVolume: (volume: number) => void,
    bindVideoElement: (tileId: number, videoElement: HTMLVideoElement) => void,
    unbindVideoElement: (tileId: number) => void,
    bindAudioElement: (audioElement: React.MutableRefObject<null>) => void,
    toggleLocalVideoTile: () => Promise<void>,
    realtimeMuteLocalAudio: () => void,
    realtimeUnmuteLocalAudio: () => void,
    chooseVideoInputDevice: (deviceId: string) => Promise<void>,
    chooseAudioInputDevice: (deviceId: string) => Promise<void>,
    chooseAudioOutputDevice: (deviceId: string) => Promise<void>,
    stopContentShare: () => void,
    startContentShareFromScreenCapture: (sourceId?: string | undefined, frameRate?: number | undefined) => Promise<void>,
    leaveRoom: (meetingStatus?: MeetingStatus | undefined) => Promise<void>,
    createRoom: (externalMeetingId: string, currentAudioInputDevice: DeviceType | null, currentAudioOutputDevice: DeviceType | null, currentVideoInputDevice: DeviceType | null) => Promise<void>,
    getLocalAttendeeId: () => string | null,
    getRoster: () => {
        [attendeeId: string]: RosterAttendeeType;
    },
    getActiveSpeakers: () => string[],
    getAttendee: (attendeeId: string) => RosterAttendeeType,
    getNumAttendees: () => number,
    getMaxAttendees: () => number,
    hasMaxAttendees: () => boolean,
    createOrJoinMeeting: (name: string, kind?: MeetingKind) => void,
    gotoCurrentMeeting: () => void,
    kick: (attendeeId: string, reason: string) => void,
    ban: (attendeeId: string, reason: string) => void,
    mute: (attendeeId: string) => void,
    raiseHand: (attendeeId: string, raiseHand: boolean) => void,
    hasWebcam: () => boolean,
    setIsMeetingChangeAccepted: (accepted: boolean) => void,
    getIsMeetingChangeAccepted: () => boolean,
    startLive: () => void,
    stopLive: (reason?: StopReason) => Promise<boolean>,
    lockChannel: (authorizedUsers: string[]) => Promise<boolean>,
    unlockChannel: () => Promise<boolean>,
    getChannelStatus: () => ChannelStatus | undefined
    isLocked: () => boolean
}

const useStateWrapper = (chime: State<StateValues>) => {
    const strings = useLanguageState().getStrings();
    const history = useHistory()
    const remoteTilesState = useState(chime.remoteTiles)
    const rosterState = useState(chime.roster)
    const meetingSecondsLeft = useState(chime.meetingSecondsLeft)
    const loggedInUser = useLoggedInState()
    const contactState = useContactState()
    const loggedIn = loggedInUser.isLoggedIn
    const alertState = useAlertState()


    useEffect(() => {
        if (!loggedIn && chime.name.get()) {
            leaveRoom()
        }
    },
        // eslint-disable-next-line
        [loggedIn])

    const getShallowVideoTileState = (videoTileState: VideoTileState | ShallowVideoTileState): ShallowVideoTileState => {
        return {
            active: videoTileState.active,
            tileId: videoTileState.tileId,
            isContent: videoTileState.isContent,
            localTile: videoTileState.localTile,
            boundAttendeeId: videoTileState.boundAttendeeId,
        }
    }

    const observer = {
        audioVideoDidStop: (_: MeetingSessionStatus): void => {
            if (chime[self].value.meetingStatus.meetingStatus === MeetingStatusCode.Succeeded)
                leaveRoom({ meetingStatus: MeetingStatusCode.Disconnected })
        },
        videoTileDidUpdate: (tileState: VideoTileState): void => {
            if (
                !tileState.boundAttendeeId ||
                !tileState.tileId
            ) {
                return;
            }
            const newTileState = getShallowVideoTileState(tileState)

            if (newTileState.isContent) {
                chime[self].merge({ viewMode: ViewMode.ScreenShare, shareScreenTile: newTileState })
            } else if (newTileState.localTile) {
                chime[self].merge({ viewMode: ViewMode.Room, localTile: newTileState })
            } else if (tileState.boundAttendeeId && tileState.tileId && !newTileState.isContent && !newTileState.localTile) {
                const oldTiles = remoteTilesState[self].get()
                let newTiles: ShallowVideoTileState[] = []
                let contains = false
                for (let i = 0; i < oldTiles.length; i++) {
                    if (oldTiles[i].tileId === newTileState.tileId) {
                        contains = true;
                        newTiles = newTiles.concat(newTileState)
                    } else {
                        newTiles = newTiles.concat(getShallowVideoTileState(oldTiles[i]))
                    }
                }
                if (!contains) {
                    newTiles = newTiles.concat(newTileState)
                }
                remoteTilesState[self].set(newTiles)
            }
        },
        videoTileWasRemoved: (tileId: number): void => {
            if (chime[self].value.shareScreenTile?.tileId === tileId) {
                chime[self].merge({ viewMode: ViewMode.Room })
            }
            const oldTiles = remoteTilesState[self].get()
            const newTiles = []
            let removed: boolean = false
            for (let i = 0; i < oldTiles.length; i++) {
                if (oldTiles[i].tileId !== tileId) {
                    newTiles.push(getShallowVideoTileState(oldTiles[i]))
                } else {
                    removed = true
                }
            }
            // Change only needed if we removed a tile
            if (removed)
                remoteTilesState[self].set(newTiles)
        },
    }

    const contentShareObserver = {
        contentShareDidStop: () => {
            chime[self].merge({ screensharing: false, viewMode: ViewMode.Room })
        }
    }

    const rosterUpdate = (newRoster: RosterType) => {
        /*
        // For testing of all the tiles
        newRoster["1"] = { name: "Max Dubiel" }
        newRoster["2"] = { name: "Hendrik Weißbrod" }
        newRoster["3"] = { name: "Alex Bork" }
        newRoster["4"] = { name: "Alex Merkle" }
        newRoster["5"] = { name: "Julian Tan" }
        newRoster["6"] = { name: "Gustavo Niewöhner" }
        newRoster["7"] = { name: "Michael Gust" }
        newRoster["8"] = { name: "Carsten Kirschner" }
        newRoster["9"] = { name: "Amila Handzic" }
        newRoster["10"] = { name: "Jozo Skoko" }
        newRoster["11"] = { name: "Niloofar Rashvanloo" }
        newRoster["12"] = { name: "Johan Tomberg" }
        newRoster["13"] = { name: "Kristian Skobic" }
        newRoster["14"] = { name: "Waldemar Tomber" }
        newRoster["15"] = { name: "Meris Gutosic" }
        newRoster["16"] = { name: "Haris Heric" }
        newRoster["17"] = { name: "Haris Heric1" }
        newRoster["18"] = { name: "Haris Heric2" }
        newRoster["19"] = { name: "Haris Heric3" }
        */

        let hasChanges = false
        const oldRoster = rosterState[self].value
        const newRosterKeys = Object.keys(newRoster)
        const oldRosterKeys = Object.keys(oldRoster)
        // If the old and new roster list are of different length -> update the view
        const difference = newRosterKeys.filter(x => !oldRosterKeys.includes(x)).concat(oldRosterKeys.filter(x => !newRosterKeys.includes(x)))
        if (difference.length > 0) {
            hasChanges = true
        } else {
            // check if there are changes between the new and old update that we are interested in -> update
            for (let key of newRosterKeys) {
                const newRosterEntry = newRoster[key]
                const oldRosterEntry = oldRoster[key]
                if (!rosterAttendeeTypeEquals(newRosterEntry, oldRosterEntry)) {
                    hasChanges = true
                    break
                }
            }
        }

        if (hasChanges) {
            rosterState[self].set(newRoster)
        }
    }


    const setMeetingStatus = (meetingStatus: MeetingStatus) => {
        chime.meetingStatus[self].merge(meetingStatus)
    }

    const mutedUpdate = (localMuted: boolean) => {
        chime[self].merge({ muted: localMuted })
    }

    const setVideoStatus = (videoStatus: LocalVideoStatus) => {
        chime[self].merge({ localVideoStatus: videoStatus })
    }

    const leaveRoom = async (meetingStatus?: MeetingStatus) => {
        sdk.unsubscribeFromRosterUpdate(rosterUpdate)
        sdk.audioVideo?.removeObserver(observer)
        sdk.audioVideo?.removeContentShareObserver(contentShareObserver)
        sdk.audioVideo?.unsubscribeFromActiveSpeakerDetector(activeSpeakerCallback)
        sdk.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(sdk.attendeeId!)
        sdk.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(sdk.meetingId!)
        await sdk.leaveRoom(meetingStatus)
        window.onbeforeunload = null
        if (timeUpIntervallId) {
            clearTimeout(timeUpIntervallId)
            timeUpIntervallId = null
        }
        const values = getStartValues()
        values.meetingStatus = meetingStatus ? meetingStatus : { meetingStatus: MeetingStatusCode.Ended }
        chime[self].set(values)
    }

    const toggleLocalVideoTile = async () => {
        if (!chime[self].value.hasWebcam)
            return
        const deviceId = localStorage.getItem("virtualGuide-videoInput")
        if (chime[self].value.localVideoStatus === LocalVideoStatus.Disabled) {
            setVideoStatus(LocalVideoStatus.Loading)
            try {
                sdk.audioVideo?.startLocalVideoTile()
                setVideoStatus(LocalVideoStatus.Enabled)
            } catch (error) {
                // eslint-disable-next-line
                logger.error({ message: "Chime Context choose video input device failed", errorMessage: error.message, errorStack: error.stack })
                alertState.show({ message: strings.globalStatePopupTexts.errorNoCameraPermission, type: CrsAlertType.DANGER, duration: 3000 })
                setVideoStatus(LocalVideoStatus.Disabled)
            }
        } else if (chime[self].value.localVideoStatus === LocalVideoStatus.Enabled) {

            setVideoStatus(LocalVideoStatus.Loading)
            sdk.audioVideo?.stopLocalVideoTile()
            setVideoStatus(LocalVideoStatus.Disabled)
            if (deviceId)
                sdk.chooseVideoInputDevice(deviceId) // When camera is disabled video device detach so video device needs to be attached again
        }
    }

    const receiveAttendeeDataMessage = (dataMessage: DataMessage) => {
        const messageData = dataMessage.json()
        const messageDataType: DataMessageType = messageData.type
        if (!messageDataType)
            return
        if (dataMessage.senderAttendeeId)
            switch (messageDataType) {
                case DataMessageType.MUTE:
                    sdk.audioVideo?.realtimeMuteLocalAudio()
                    chime[self].merge({ muted: true, mutedByMod: true })
                    break
                case DataMessageType.KICK:
                    leaveRoom({ meetingStatus: MeetingStatusCode.Kicked, errorMessage: messageData.data })
                    break
                case DataMessageType.BAN:
                    leaveRoom({ meetingStatus: MeetingStatusCode.Banned, errorMessage: messageData.data })
                    break
            }
    }

    const receiveMeetingDataMessage = (dataMessage: DataMessage) => {
        const messageData = dataMessage.json()
        const messageDataType: DataMessageType = messageData.type
        if (!messageDataType)
            return
        switch (messageDataType) {
            case DataMessageType.RAISEHAND:
                if (messageData.attendeeId) {
                    handleHandRaisedState(messageData.attendeeId, messageData.data)
                }
                break
            case DataMessageType.CHANNEL_STATUS:
                handleChannelStatusState(messageData.data)
                break
            case DataMessageType.TIMELIMITCHANGED:
                sdk.meetingMaxDuration = messageData.meetingMaxDuration
                sdk.meetingTimeLeft = messageData.meetingTimeLeft
                startMeetingTimer(messageData.meetingTimeLeft)
                break
        }
    }

    const handleHandRaisedState = (attendeeId: string, raiseHand: boolean) => {
        if (attendeeId === sdk.localAttendeeId)
            chime[self].merge({ handRaised: raiseHand })
        else
            rosterState[self].set(prev => {
                if (prev[attendeeId]) {// If the roster is not loaded yet
                    prev[attendeeId].handRaised = raiseHand
                }
                return prev
            })
    }

    const handleChannelStatusState = (channelStatus: ChannelStatus) => {
        chime[self].set(prev => {
            prev.channelStatus = channelStatus
            return prev
        })
    }

    const handleLockedState = (isLocked: boolean) => {
        chime[self].set(prev => {
            prev.isLocked = isLocked
            return prev
        })
    }

    const fetchChannelStatusForGreenRoom = (kind: MeetingKind, externalMeetingId: string) => {
        if (kind === "greenroom") {
            getChannelInfo(externalMeetingId).then((resp) => {
                if ((resp as BackendServiceError).httpStatus) {
                    const error = resp as BackendServiceError
                    logger.error({ message: "Green room " + externalMeetingId + " channel status could not be retrieved.", errorMessage: error.httpStatusText })
                } else {
                    const channelResponse = resp as ChannelResponse
                    const newChannelStatus = channelResponse.isLive ? ChannelStatus.ON_AIR : ChannelStatus.OFF_AIR
                    handleChannelStatusState(newChannelStatus)
                    handleLockedState(channelResponse.isLocked)
                }
            })
        }
    }

    const startMeetingTimer = (meetingTimeLeft: number | null) => {
        if (timeUpIntervallId) {
            clearInterval(timeUpIntervallId)
            timeUpIntervallId = null
        }
        if (meetingTimeLeft) {
            meetingSecondsLeft[self].set(meetingTimeLeft / 1000)
            timeUpIntervallId = window.setInterval(() => {
                const newSecondsLeft = meetingSecondsLeft[self].get()!! - 1
                if (newSecondsLeft <= 0) {
                    leaveRoom({ meetingStatus: MeetingStatusCode.TimeUp })
                } else {
                    meetingSecondsLeft[self].set(newSecondsLeft)
                }
            }, 1000)
        } else {
            meetingSecondsLeft[self].set(null)
        }
    }


    const activeSpeakerCallback = throttle((activeSpeakers: string[]) => {
        chime[self].set(prev => {
            prev.activeSpeakers = activeSpeakers
            for (let rosterEntryKey of Object.keys(prev.roster)) {
                prev.roster[rosterEntryKey].speaking = activeSpeakers.indexOf(rosterEntryKey) >= 0
            }
            return prev
        })
    }, 1000)

    return ({
        getExternalMeetingId: () => {
            return sdk.externalMeetingId ? sdk.externalMeetingId : null
        },
        getName: () => {
            return chime.name.get()
        },
        getKind: () => {
            return chime.kind.get()
        },
        getViewMode: () => {
            return chime[self].value.viewMode
        },
        getMeetingStatus: () => {
            return chime[self].value.meetingStatus
        },
        getTimeLeft: () => {
            return meetingSecondsLeft[self].value
        },
        getMaxDuration: () => {
            if (chime.kind.get() === "showroom") {
                return parseInt(branding.showroomMeetingDuration)
            }
            return sdk.meetingMaxDuration
        },
        isScreenShareEnabled: () => {
            return chime[self].value.viewMode === ViewMode.ScreenShare
        },
        isLocalScreenSharingStarted: () => {
            return chime[self].value.screensharing
        },
        isMod: () => {
            return sdk.userRole === "moderator"
        },
        getShareScreenTile: () => {
            return chime[self].value.shareScreenTile
        },
        getLocalTile: () => {
            return chime[self].value.localTile
        },
        getLocalVideoStatus: () => {
            return chime[self].value.localVideoStatus
        },
        getRemoteTiles: () => {
            return chime[self].value.remoteTiles
        },
        isMuted: () => {
            return chime[self].value.muted
        },
        isMutedByMod: () => {
            return chime[self].value.mutedByMod
        },
        setMutedByMod: (mutedByMod: boolean) => {
            chime[self].merge({ mutedByMod: mutedByMod })
        },
        isHandRaised: () => {
            return chime[self].value.handRaised
        },
        hasWebcam: () => {
            return chime[self].value.hasWebcam
        },
        getVolume: () => {
            return chime[self].value.volume
        },
        setVolume: (volume: number) => {
            chime[self].merge({ volume: volume })
        },
        bindVideoElement: (tileId: number, videoElement: HTMLVideoElement) => {
            sdk.audioVideo?.bindVideoElement(tileId, videoElement)
        },
        unbindVideoElement: (tileId: number) => {
            sdk.audioVideo?.unbindVideoElement(tileId)
        },
        bindAudioElement: (audioElement: React.MutableRefObject<null>) => {
            if (!audioElement || !audioElement.current) {
                logger.warn("ChimeContext AudioElement doesn't exist")
                return;
            }
            sdk.audioVideo?.bindAudioElement(audioElement.current!)
        },
        toggleLocalVideoTile: toggleLocalVideoTile,
        realtimeMuteLocalAudio: () => {
            sdk.audioVideo?.realtimeMuteLocalAudio()
            chime[self].merge({ muted: true })
        },
        realtimeUnmuteLocalAudio: () => {
            sdk.audioVideo?.realtimeUnmuteLocalAudio()
            chime[self].merge({ muted: false, mutedByMod: false })
        },
        chooseVideoInputDevice: (deviceId: string) => {
            return sdk.chooseVideoInputDevice(deviceId)
        },
        chooseAudioInputDevice: (deviceId: string) => {
            return sdk.chooseAudioInputDevice(deviceId);
        },
        chooseAudioOutputDevice: (deviceId: string) => {
            return sdk.chooseAudioOutputDevice(deviceId);
        },
        stopContentShare: () => {
            sdk.audioVideo?.stopContentShare();
            chime[self].merge({ screensharing: false, viewMode: ViewMode.Room })
        },
        startContentShareFromScreenCapture: async (sourceId?: string | undefined, frameRate?: number | undefined) => {
            await sdk.audioVideo?.startContentShareFromScreenCapture(sourceId, frameRate)
            chime[self].merge({ screensharing: true })
        },
        leaveRoom: leaveRoom,
        createRoom: async (externalMeetingId: string, currentAudioInputDevice: DeviceType | null, currentAudioOutputDevice: DeviceType | null, currentVideoInputDevice: DeviceType | null) => {
            try {
                if (!loggedInUser.user()?.profileId)
                    return
                if (chime.name.get() !== externalMeetingId && chime[self].value.meetingStatus.meetingStatus === MeetingStatusCode.Succeeded) {
                    await leaveRoom()
                }
                setMeetingStatus({ meetingStatus: MeetingStatusCode.Loading })
                try {
                    await sdk.createOrJoinRoom(loggedInUser.user()!.profileId, externalMeetingId, contactState)
                } catch (error) {
                    if ((error as BackendServiceError).httpStatus) {
                        // Custom error code from the backend.
                        if (error.httpStatus === 444) {
                            if (error.responseJson?.errorCode === "meetingFull") {
                                setMeetingStatus({ meetingStatus: MeetingStatusCode.Full })
                                return
                            } else if (error.responseJson?.errorCode === "meetingTimeUp") {
                                setMeetingStatus({ meetingStatus: MeetingStatusCode.TimeUp })
                                return
                            }
                        } else if (error.httpStatus === 401) {
                            if (error.responseJson?.errorCode === "banned") {
                                setMeetingStatus({ meetingStatus: MeetingStatusCode.Banned, errorMessage: error.responseJson?.errorMessage })
                                return
                            }
                            else if (error.responseJson?.errorCode === "channelIsLive") {
                                setMeetingStatus({ meetingStatus: MeetingStatusCode.GreenroomLive, errorMessage: error.responseJson?.errorMessage })
                                return
                            }
                        }
                        logger.error({ message: "ChimeContext Create or Join Room Failed", errorMessage: error.httpStatusText })
                        setMeetingStatus({ errorMessage: error.httpStatusText, meetingStatus: MeetingStatusCode.Failed })
                        return
                    }
                }


                sdk.audioVideo?.addObserver(observer);
                sdk.audioVideo?.addContentShareObserver(contentShareObserver);
                if (currentAudioInputDevice)
                    await sdk.audioVideo?.chooseAudioInputDevice(currentAudioInputDevice.value)
                if (currentAudioOutputDevice)
                    await sdk.audioVideo?.chooseAudioOutputDevice(currentAudioOutputDevice.value)
                if (currentVideoInputDevice) {
                    chime[self].merge({ hasWebcam: true })
                    await sdk.audioVideo?.chooseVideoInputDevice(currentVideoInputDevice.value)
                } else {
                    chime[self].merge({ hasWebcam: false })
                }
                await sdk.joinRoom();
                sdk.subscribeToRosterUpdate(rosterUpdate);
                sdk.audioVideo?.realtimeSubscribeToMuteAndUnmuteLocalAudio(mutedUpdate);
                // Topic is validated with regex {a-zA-Z-0-9-_}{1,36} (e.g. meeting/attendee id are valid)
                sdk.audioVideo?.realtimeSubscribeToReceiveDataMessage(sdk.attendeeId!, receiveAttendeeDataMessage)
                sdk.audioVideo?.realtimeSubscribeToReceiveDataMessage(sdk.meetingId!, receiveMeetingDataMessage)
                sdk.audioVideo?.subscribeToActiveSpeakerDetector(new EGActiveSpeakerPolicy(), activeSpeakerCallback)


                chime[self].merge({ name: externalMeetingId.substr(3), kind: getMeetingKindFromExternalMeetingId(externalMeetingId) })
                fetchChannelStatusForGreenRoom(chime.kind.get(), chime.name.get())
                setMeetingStatus({ meetingStatus: MeetingStatusCode.Succeeded })
                toggleLocalVideoTile()

                startMeetingTimer(sdk.meetingTimeLeft)
                if (sdk.timeLimitChanged) {
                    const delay = 5000
                    // 5000 milliseconds wait, because we do not know yet how we can detect that the realtimeSend is ready to really send. Without this delay, the message will not go out currently
                    setTimeout(() => {
                        sdk.audioVideo?.realtimeSendDataMessage(sdk.meetingId!, { type: DataMessageType.TIMELIMITCHANGED, meetingTimeLeft: (sdk.meetingTimeLeft! - delay), meetingMaxDuration: sdk.meetingMaxDuration })
                    }, delay)
                }

                // Recommend using "onbeforeunload" over "addEventListener"
                window.onbeforeunload = async (event: BeforeUnloadEvent) => {
                    // Prevent the window from closing immediately
                    // eslint-disable-next-line
                    event.returnValue = true
                }
            } catch (error) {
                // eslint-disable-next-line
                logger.error({ message: "Chime Context create room failed", errorMessage: error.message, errorStack: error.stack })
                setMeetingStatus({ meetingStatus: MeetingStatusCode.Failed, errorMessage: error.message })
            }
        },
        getLocalAttendeeId: () => {
            return sdk.localAttendeeId
        },
        getRoster: () => {
            return rosterState[self].value
        },
        getActiveSpeakers: () => {
            return chime[self].value.activeSpeakers
        },
        getAttendee: (attendeeId: string) => {
            return rosterState[self].value[attendeeId]
        },
        getNumAttendees: () => {
            return Object.keys(rosterState[self].get()).length
        },
        getMaxAttendees: () => {
            return sdk.maxAttendees
        },
        hasMaxAttendees: () => {
            return Object.keys(rosterState[self].get()).length >= sdk.maxAttendees
        },
        createOrJoinMeeting(name: string, kind?: MeetingKind) {
            if (kind)
                history.push(getUrlForMeeting(name, kind))
            else
                history.push(getUrlForMeetingFromExternalMeetingId(name))
        },
        gotoCurrentMeeting() {
            if (sdk.externalMeetingId)
                history.push(getUrlForMeetingFromExternalMeetingId(sdk.externalMeetingId))
        },
        kick(attendeeId: string, reason: string) {
            sdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.KICK, data: reason })
        },
        ban(attendeeId: string, reason: string) {
            sdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.BAN, data: reason })
        },
        mute(attendeeId: string) {
            sdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.MUTE })
        },
        raiseHand(attendeeId: string, raiseHand: boolean) {
            sdk.audioVideo?.realtimeSendDataMessage(sdk.meetingId!, { type: DataMessageType.RAISEHAND, attendeeId: attendeeId, data: raiseHand })
            // Setting this values extra, because the sender does not receive his broadcasted message
            handleHandRaisedState(attendeeId, raiseHand)
        },
        setIsMeetingChangeAccepted: (accepted: boolean) => {
            chime.meetingChangeAccepted[self].merge(accepted);
        },
        getIsMeetingChangeAccepted: () => {
            return chime.meetingChangeAccepted[self].value;
        },
        startLive() {
            const channelId = sdk.externalMeetingId?.substr(3)
            if (channelId) {
                handleChannelStatusState(ChannelStatus.PREPARING)
                sdk.audioVideo?.realtimeSendDataMessage(sdk.meetingId!, { type: DataMessageType.CHANNEL_STATUS, data: ChannelStatus.PREPARING })
                startLive(channelId).then(response => {
                    if ((response as BackendServiceError).httpStatus) {
                        handleChannelStatusState(ChannelStatus.OFF_AIR)
                        sdk.audioVideo?.realtimeSendDataMessage(sdk.meetingId!, { type: DataMessageType.CHANNEL_STATUS, data: ChannelStatus.OFF_AIR })
                    } else {
                        const channelResponse = response as ChannelResponse
                        const newChannelStatus = channelResponse.isLive ? ChannelStatus.ON_AIR : ChannelStatus.OFF_AIR
                        const estimatedTimeUntilLive = branding.greenroomGoLiveFollowupDelaySec * 1000 // TODO use time to live value returned from backend on going live instead
                        setTimeout(() => {
                            handleChannelStatusState(newChannelStatus)
                            sdk.audioVideo?.realtimeSendDataMessage(sdk.meetingId!, { type: DataMessageType.CHANNEL_STATUS, data: newChannelStatus })
                        }, estimatedTimeUntilLive)
                    }
                })
            }
        },
        async stopLive(reason: StopReason = "default") {
            const channelId = sdk.externalMeetingId?.substr(3)
            if (channelId) {
                const response = await stopLive(channelId, reason ?? "default")
                if ((response as BackendServiceError).httpStatus) {
                    handleChannelStatusState(ChannelStatus.ON_AIR)
                    sdk.audioVideo?.realtimeSendDataMessage(sdk.meetingId!, { type: DataMessageType.CHANNEL_STATUS, data: ChannelStatus.ON_AIR })
                    return false
                } else {
                    const channelResponse = response as ChannelResponse
                    const newChannelStatus = channelResponse.isLive ? ChannelStatus.ON_AIR : ChannelStatus.OFF_AIR
                    handleChannelStatusState(newChannelStatus)
                    sdk.audioVideo?.realtimeSendDataMessage(sdk.meetingId!, { type: DataMessageType.CHANNEL_STATUS, data: newChannelStatus })
                    return true
                }
            }
            return false
        },
        async lockChannel(authorizedUsers: string[]) {
            const channelId = sdk.externalMeetingId?.substr(3)
            if (channelId) {
                const response = await lockChannel(channelId, authorizedUsers)
                if ((response as BackendServiceError).httpStatus) {
                    return false
                } else {
                    const channelResponse = response as ChannelResponse
                    handleLockedState(channelResponse.isLocked)
                    return true
                }
            }
            return false
        },
        async unlockChannel() {
            const channelId = sdk.externalMeetingId?.substr(3)
            if (channelId) {
                const response = await unlockChannel(channelId)
                if ((response as BackendServiceError).httpStatus) {
                    return false
                } else {
                    const channelResponse = response as ChannelResponse
                    handleLockedState(channelResponse.isLocked)
                    return true
                }
            }
            return false
        },
        getChannelStatus() {
            return chime[self].value.channelStatus
        },
        isLocked() {
            return chime[self].value.isLocked
        }
    })
}

export const useChimeContext = (): ChimeContext => useState(state)[self].map(useStateWrapper)

export function getUrlForMeeting(name: string, kind: MeetingKind) {
    return getUrlForMeetingFromExternalMeetingId(getExternalMeetingId(name, kind))
}

function getUrlForMeetingFromExternalMeetingId(externalMeetingId: string) {
    return `/meeting/${externalMeetingId}/createorjoin`
}

export function getExternalMeetingId(name: string, kind: MeetingKind) {
    return getMeetingKindPrefix(kind) + name
}

export function getMeetingKindPrefix(kind: MeetingKind) {
    switch (kind) {
        case "call": return "cl_"
        case "showroom": return "sr_"
        case "virtualCafe": return "vc_"
        case "calenderEntry": return "ce_"
        case "greenroom": return "gr_"
        case "roundtable": return "rt_"
        default: return ""
    }
}

export function getMeetingKindFromExternalMeetingId(externalMeetingId: string) {
    const prefix = externalMeetingId.substr(0, externalMeetingId.indexOf("_"))
    let kind: MeetingKind = "virtualCafe"
    switch (prefix) {
        case "cl": kind = "call"; break;
        case "sr": kind = "showroom"; break;
        case "vc": kind = "virtualCafe"; break;
        case "ce": kind = "calenderEntry"; break;
        case "gr": kind = "greenroom"; break;
        case "rt": kind = "roundtable"; break;
    }
    return kind
}
