import React, { useState, Fragment, useEffect } from "react";
import styled from "styled-components";
import CenteredLoader from "../ui/CenteredLoader";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import { AvatarWithPresenceState } from "../ui/AvatarWithPresenceState";
import branding, { LocalizedString } from "../branding/branding";
import { CommunicationCenterDisplayMode, useAppState, defaultLogger as logger } from "../globalStates/AppState";
import SettingsTab from "./SettingsTab";
import NetworkingTab from "./NetworkingTab";
import ChatsTab from "./ChatsTab"
import ScheduleTab, { ScheduleListType } from "./ScheduleTab";
import NotificationsTab from "./NotificationsTab";
import { useMeetingContext } from "../conference/context/MeetingContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLanguageState } from "../globalStates/LanguageState";
import { getUnreadCounterUser, UnreadObject } from "../backendServices/BackendServices";
import { API, graphqlOperation } from "aws-amplify";
import { onUpdateUnreadCounterById } from "../graphql/subscriptions";
import { IconCCNetworking, IconCCChats, IconCCSchedule, IconCCNotifications, IconNetworkingToggleOpen, IconNetworkingToggleClose } from "../ui/Icons"

/* &>* height: 59px because there is a border of 1px and we need to match the 60px of the topbar */
const NavigationAreaRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  color: ${branding.primaryColor ?? "black"};
  border-bottom: 1px solid;
  border-color: ${branding.mainInfoColor ?? "black"};
  min-height: 60px;
  max-width: 100%;
  
  &>* {
    height: 59px; 
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .networkingCollapsed & {
    border-bottom: 1px solid hsla(0, 0%, 0%, 0%);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    &>* {
      flex-grow: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
`

const CommunicationAreaNavigationItem = styled.div`
  cursor: pointer;
  max-width: 40px;
  width: 20px;
  margin-left: 25px;
  text-align: center;
`

const CommunicationAreaNavigationItemAvatar = styled.div`
  cursor: pointer;
  max-width: 40px;
  width: 20px;
  margin-left: 25px;
  margin-right: 20px;
  text-align: center;
`

const DummyFlexItem = styled.div`
  display: none;
  flex-grow: 1 !important;
  .networkingCollapsed & {
    display: block;
  }
`
const AvatarRootArea = styled.div`
  padding-left: 22px; 
`

const CommunicationAreaRoot = styled.div`

  width: 320px;
  min-width: 320px;

  & input::placeholder {
    color: ${branding.communicationAreaSearchPlaceholderColor ?? "black"};
  }

  /* transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out; */

  &:not(.active) {
    min-width: 60px;
    width: 60px;
  }

  display: flex;
  flex-direction: column;

  font-size: 0.9rem;
  background-color: #fff;

  .chatlist-scrollref {
    background-color: ${branding.chatListBackgroundCommunicationAreaColor ?? "white"} !important;
  }

`

export interface CommunicationAreaBranding {
  communicationAreaTitle: LocalizedString
  reloadButtonText: LocalizedString
  relevantListErrorMessage: LocalizedString
  requestListErrorMessage: LocalizedString
  contactListErrorMessage: LocalizedString
  chatListErrorMessage: LocalizedString
  chatErrorMessage: LocalizedString
  chatMessageInputHint: LocalizedString
  chatStartNewChat: LocalizedString
  chatToolFavorite: LocalizedString
  chatToolCalendarEntry: LocalizedString
  chatToolVideoChat: LocalizedString
  chatToolRecommend: LocalizedString
  chatToolMute: LocalizedString
  chatToolUnmute: LocalizedString
  chatToolBlock: LocalizedString
  activateNetworkingText: LocalizedString
  relevantTabEmpty: LocalizedString
  requestsTabEmpty: LocalizedString
  contactsTabEmpty: LocalizedString
  blockedTabEmpty: LocalizedString
  allChatsTabEmpty: LocalizedString
  contactChatsTabEmpty: LocalizedString
  scheduleTabEmpty: LocalizedString
  scheduleTabNoResult: LocalizedString
  relevantTabTitle: LocalizedString
  requestsTabTitle: LocalizedString
  contactsTabTitle: LocalizedString
  chatTabTitle: LocalizedString
  peopleTabTitle: LocalizedString
  scheduleTabTitle: LocalizedString
  callsTabTitle: LocalizedString
  notificationsTabTitle: LocalizedString
  allCallsTabTitle: LocalizedString
  missedCallsTabTitle: LocalizedString
  allPeopleTabTitle: LocalizedString
  allChatsTabTitle: LocalizedString
  contactChatsTabTitle: LocalizedString
  blockedChatsTabTitle: LocalizedString
  allScheduleTabTitle: LocalizedString
  pendingScheduleTabTitle: LocalizedString
  declinedScheduleTabTitle: LocalizedString
  silentChat: LocalizedString
  settingsTitle: LocalizedString
  closeSettingsTitle: LocalizedString
  onSign: LocalizedString
  offSign: LocalizedString
  chatOnOffTitle: LocalizedString
  audioVideoActivateTitle: LocalizedString
  noSearchResultsTitle: LocalizedString
  scheduleListSectionHeaderAccepted: LocalizedString
  scheduleListSectionHeaderPast: LocalizedString
  scheduleListSectionHeaderRequests: LocalizedString
  scheduleListSectionHeaderDeclined: LocalizedString
  scheduleListSectionHeaderNow: LocalizedString
  personCompanyLink: LocalizedString
  blockedContactsTitle: LocalizedString
  myOrganizationTitle: LocalizedString
  showAsContactTitle: LocalizedString
  standByForShowroom: LocalizedString
  logOutTitle: LocalizedString
  livechat: LocalizedString
  poll: LocalizedString
  qanda: LocalizedString
  relevantTabRelevantSectionTitle: LocalizedString
  relevantTabAllSectionTitle: LocalizedString
  requestsTabAwaitingReplySectionTitle: LocalizedString
  requestsTabMyPendingRequestsSectionTitle: LocalizedString
  requestsTabMyOrganizationRequestsSectionTitle: LocalizedString
  requestsTabMyExhibitorRequestsSectionTitle: LocalizedString
  contactsTabFavoritesSectionTitle: LocalizedString
  contactsTabMyPersonalContactsSectionTitle: LocalizedString
  contactsTabMyOrganizationsContactsSectionTitle: LocalizedString
  requestAcceptTitle: LocalizedString
  requestIgnoreTitle: LocalizedString
  requestDeclineTitle: LocalizedString
  exportVCards: LocalizedString
  exportICals: LocalizedString,
  blockingSectionTitle: LocalizedString
  blockedSectionTitle: LocalizedString
  scheduleMeetingText: LocalizedString
  userReportText: LocalizedString
  userReportTextTitle: LocalizedString
  claimAccountText: LocalizedString
  sendReportText: LocalizedString
  reportDescriptionText: LocalizedString
  reportText: LocalizedString
  reportHint: LocalizedString
  incomingText: LocalizedString
  outcomingText: LocalizedString
  notificationRequestTabTitle: LocalizedString
  notificationChatTabTitle: LocalizedString
  notificationCallTabTitle: LocalizedString
  notificationMeetingTabTitle: LocalizedString
  notificationEventTabTitle: LocalizedString
  notificationEmptyMessage: LocalizedString
  messageReceived: LocalizedString
  searchBarTextPerson: LocalizedString
  badgeUnreadCounterBgColor: string
  badgeUnreadCounterBorderColor: string
  organizerLogoHeight: string
  organizerLogoMarginLeft: string
  inactiveItemColor: string
  titleTextColor: string
  relevantTabDisableFavorites: boolean
  timezone: LocalizedString
  timezonePickerText: LocalizedString
}

export enum NetworkingListType {
  RELEVANT,
  REQUESTS,
  CONTACTS
}

interface CommunicationAreaProps {
  logout: (beConnectionToken: string) => void,
}

const CommunicationArea: React.FunctionComponent<CommunicationAreaProps> = (props) => {
  const appState = useAppState()
  const meeting = useMeetingContext()
  const [unreadArray, setUnreadArray] = useState({ requests: 0, contacts: 0, conversations: 0, schedules: 0 })
  const userLink = useLoggedInState();
  const loggedInUserId = userLink.user()?.profileId;

  function newUnreadObject(unread: UnreadObject) {
    return {
      requests: (unread.requests) ? unread.requests : 0,
      contacts: (unread.contacts) ? unread.contacts : 0,
      conversations: (unread.conversations) ? unread.conversations : 0,
      schedules: (unread.schedules && unread.schedules > 0) ? unread.schedules : 0
    }
  }

  useEffect(() => {

    let params = new URLSearchParams(window.location.search);
    let communicationArea = params.get('communicationArea');
    let type = params.get('type') // Used to navigate instantly to schedule type area in case for email link visiting

    if (communicationArea && type) {
      switch (type) {
        case "PENDING":
          appState.setShowScheduleTab(ScheduleListType.PENDING)
          break;
        case "DECLINED":
          appState.setShowScheduleTab(ScheduleListType.DECLINED)
          break;
        default:
          appState.setShowScheduleTab(ScheduleListType.CONFIRMED)
          break;
      }
    }
    (async () => {
      if (loggedInUserId) {
        const unreadRecord = await getUnreadCounterUser(loggedInUserId) as UnreadObject
        if (!unreadRecord) {
          logger.warn("## Error, only in Dev Environment: can't find users unread record because the unreadTable is not valid initialized! -> use https://dmexco-int-event-cloud.com/rest/admin/userpool/DMEXCO/filldynamodbusertable with your environment, look in readme whithin messe.backend")
          return
        }
        setUnreadArray(newUnreadObject(unreadRecord))

        const subscriptionUpdate = (await API.graphql(graphqlOperation(onUpdateUnreadCounterById, { id: loggedInUserId })) as any).subscribe({
          next: (resp: any) => {
            const values = resp.value.data?.onUpdateUnreadCounterById
            if (values) {
              setUnreadArray(newUnreadObject(values))
            }
          }
        })
        return () => {
          try {
            subscriptionUpdate.unsubscribe()
          } catch (error) {
            logger.error({ message: "CommunicationArea.tsx Unsubscribe failed", errorMessage: error.message, errorStack: error.stack })
          }
        }
      }
    })()
    //eslint-disable-next-line
  }, [loggedInUserId])

  return (
    <div style={{ height: "100%", borderLeft: "1px solid " + branding.mainInfoColor ?? "black" }}>
      <CommunicationAreaRoot style={{ height: appState.isNetworkingOpen() ? "95%" : "100%" }} className={appState.isNetworkingOpen() || meeting.getIncomingInvites().length > 0 || meeting.getOutgoingInvites().length > 0 ? "active" : "networkingCollapsed"}>
        <NavigationArea unreadArray={unreadArray} />
        {appState.isNetworkingOpen() && <Content logout={props.logout} unreadArray={unreadArray} />}
        {appState.isNetworkingOpen() && <TabFooter />}
      </CommunicationAreaRoot>
    </div>
  );
};

const NetworkingToggleIcon = styled.div`
    z-index: 2;
    transform: rotate(0deg);
    transition: transform 0.8s, right 0.3s ease-in-out;
    background-color: hsla(0, 0%, 100%, 0%);
    color: ${branding.mainInfoColor ?? "black"};

    .networkingCollapsed & {
      margin-right: 25px;
    } 

`

const IconContainer = styled.div<{ margin?: string }>`
  display: inline-block;
  position: relative;
  margin-left: ${props => props.margin};
`

const BadgeUnreadCounter = styled.span`
  position: relative;
  bottom: 12px;
  right: 10px;
  background-color: ${branding.communicationArea.badgeUnreadCounterBgColor ?? "#000"};
  padding: 0.4em 0.6em;
  border-radius: 1rem;
  border: 1px solid ${branding.communicationArea.badgeUnreadCounterBorderColor ?? "#fff"};
  font-size: 10px;
`
interface UnReadArrayProps {
  unreadArray: {
    requests: number
    contacts: number
    conversations: number
    schedules: number
  }
}
const NavigationArea: React.FC<UnReadArrayProps> = (props) => {
  const userState = useLoggedInState()
  const appState = useAppState()
  const eventKey = appState.communicationCenterDisplayMode
  const strings = useLanguageState().getStrings()

  if (appState.communicationCenterDisplayMode === undefined) {
    appState.setShowPeopleTab();
  }

  const itemClasses = (targetEventKey: CommunicationCenterDisplayMode) => {
    return (targetEventKey === eventKey) ? "selected" : ""
  }

  function isDisplayMode(mode: CommunicationCenterDisplayMode): boolean {
    if (!appState.isNetworkingOpen()) {
      return true
    }

    return appState.communicationCenterDisplayMode === mode
  }

  return (
    <NavigationAreaRoot
      onClick={() => {
        if (!appState.isNetworkingOpen()) {
          appState.toggleNetworking()
        }
      }}
    >
      {!appState.isNetworkingOpen() &&
        <OverlayTrigger
          placement={appState.isNetworkingOpen() ? "bottom" : "left"}
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="settings-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
              {strings.communicationArea.settingsTitle}
            </Tooltip>}>
          <CommunicationAreaNavigationItemAvatar
            style={{ marginBottom: "25px" }}
            className={itemClasses(CommunicationCenterDisplayMode.SETTINGS)}
            onClick={() => { appState.setCommunicationCenterDisplayMode(CommunicationCenterDisplayMode.SETTINGS) }}
          >
            <AvatarRootArea>
              <AvatarWithPresenceState
                avatarSize={30}
                badgeSize={10}
                badgeRight={25}
                badgeTop={18}
                showAvatarBadge={true}
                userId={userState.user()?.profileId}
                initPresenceByList={false}
                userType={userState.user()?.type}
                content={{ pictureUrl: userState.user()?.logoUrl, alt: ([userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")) }} >
              </AvatarWithPresenceState>
            </AvatarRootArea>
          </CommunicationAreaNavigationItemAvatar>
        </OverlayTrigger>
      }

      <OverlayTrigger
        placement={appState.isNetworkingOpen() ? "bottom" : "left"}
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="people-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
            {strings.communicationArea.peopleTabTitle}
          </Tooltip>}>
        <CommunicationAreaNavigationItem
          className={itemClasses(CommunicationCenterDisplayMode.NETWORKING)}
          onClick={() => { appState.setCommunicationCenterDisplayMode(CommunicationCenterDisplayMode.NETWORKING) }}
        >
          <IconContainer margin={!appState.isNetworkingOpen() && (props.unreadArray.requests > 0 || props.unreadArray.contacts > 0) ? "-5px" : (!appState.isNetworkingOpen() && (props.unreadArray.requests === 0 || props.unreadArray.contacts === 0)) ? "-30px" : (appState.isNetworkingOpen() && (props.unreadArray.requests > 0 || props.unreadArray.contacts > 0) ? "20px" : "0px")}>
            {IconCCNetworking({
              fill: isDisplayMode(CommunicationCenterDisplayMode.NETWORKING) ?
                (branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor) :
                branding.communicationArea.inactiveItemColor
            })}
          </IconContainer>
          {(props.unreadArray.requests + props.unreadArray.contacts <= 0) ? null :
            <h6 style={{ fontSize: "14px" }}>
              <BadgeUnreadCounter className="badge badge-pill badge-dark" style={{
                backgroundColor: isDisplayMode(CommunicationCenterDisplayMode.NETWORKING) ?
                  (branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor) :
                  branding.communicationArea.inactiveItemColor
              }}>{props.unreadArray.requests + props.unreadArray.contacts}
              </BadgeUnreadCounter>
            </h6>}

        </CommunicationAreaNavigationItem>
      </OverlayTrigger>

      <OverlayTrigger
        placement={appState.isNetworkingOpen() ? "bottom" : "left"}
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="chat-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
            {strings.communicationArea.chatTabTitle}
          </Tooltip>}>
        <CommunicationAreaNavigationItem
          className={itemClasses(CommunicationCenterDisplayMode.CHATS)}
          onClick={() => { if (appState.communicationCenterDisplayMode === CommunicationCenterDisplayMode.CHATS) appState.setShowChatsTab(null); else appState.setShowChatsTab(); }}
        >
          <IconContainer margin={!appState.isNetworkingOpen() && props.unreadArray.conversations > 0 ? "-5px" : (!appState.isNetworkingOpen() && props.unreadArray.conversations === 0) ? "-30px" : (appState.isNetworkingOpen() && props.unreadArray.conversations > 0 ? "20px" : "0px")}>
            {IconCCChats({
              fill: isDisplayMode(CommunicationCenterDisplayMode.CHATS) ?
                (branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor) :
                branding.sideIconBar.inactiveItemColor
            })}
          </IconContainer>
          {(props.unreadArray.conversations <= 0) ? null :
            <h6>
              <BadgeUnreadCounter className="badge badge-pill badge-dark" style={{
                backgroundColor: isDisplayMode(CommunicationCenterDisplayMode.CHATS) ?
                  (branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor) :
                  branding.sideIconBar.inactiveItemColor
              }}>{props.unreadArray.conversations}
              </BadgeUnreadCounter>
            </h6>}
        </CommunicationAreaNavigationItem>
      </OverlayTrigger>

      <OverlayTrigger
        placement={appState.isNetworkingOpen() ? "bottom" : "left"}
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="schedule-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
            {strings.communicationArea.scheduleTabTitle}
          </Tooltip>}>
        <CommunicationAreaNavigationItem
          className={itemClasses(CommunicationCenterDisplayMode.SCHEDULE)}
          onClick={() => { appState.setCommunicationCenterDisplayMode(CommunicationCenterDisplayMode.SCHEDULE) }}
        >
          <IconContainer margin={!appState.isNetworkingOpen() && props.unreadArray.schedules > 0 ? "-5px" : (!appState.isNetworkingOpen() && props.unreadArray.schedules === 0) ? "-30px" : (appState.isNetworkingOpen() && props.unreadArray.schedules > 0 ? "20px" : "0px")}>
            {IconCCSchedule({
              fill: isDisplayMode(CommunicationCenterDisplayMode.SCHEDULE) ?
                (branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor) :
                branding.sideIconBar.inactiveItemColor
            })}
          </IconContainer>
          {(props.unreadArray.schedules <= 0) ? null :
            <h6>
              <BadgeUnreadCounter className="badge badge-pill badge-dark" style={{
                backgroundColor: isDisplayMode(CommunicationCenterDisplayMode.SCHEDULE) ?
                  (branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor) :
                  branding.sideIconBar.inactiveItemColor
              }}>{props.unreadArray.schedules}
              </BadgeUnreadCounter>
            </h6>}
        </CommunicationAreaNavigationItem>
      </OverlayTrigger>

      <OverlayTrigger
        placement={appState.isNetworkingOpen() ? "bottom" : "left"}
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="notifications-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
            {strings.communicationArea.notificationsTabTitle}
          </Tooltip>}>
        <CommunicationAreaNavigationItem
          className={itemClasses(CommunicationCenterDisplayMode.NOTIFICATIONS)}
          onClick={() => { appState.setCommunicationCenterDisplayMode(CommunicationCenterDisplayMode.NOTIFICATIONS) }}
        >
          <IconContainer margin={!appState.isNetworkingOpen() ? "-26px" : "0px"}>
            {IconCCNotifications({
              fill: isDisplayMode(CommunicationCenterDisplayMode.NOTIFICATIONS) ?
                (branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor) :
                branding.sideIconBar.inactiveItemColor
            })}
          </IconContainer>
        </CommunicationAreaNavigationItem>
      </OverlayTrigger>

      <DummyFlexItem />

      {
        !appState.isNetworkingOpen() &&
        <CommunicationAreaNavigationItem
          onClick={appState.toggleNetworking}
        >
          <NetworkingToggleIcon>

            {IconNetworkingToggleOpen({ fill: branding.sideIconBar.sideIconColorDark })}
          </NetworkingToggleIcon>
        </CommunicationAreaNavigationItem>
      }

      {appState.isNetworkingOpen() &&
        <OverlayTrigger
          placement={appState.isNetworkingOpen() ? "bottom" : "left"}
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="settings-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
              {strings.communicationArea.settingsTitle}
            </Tooltip>}>
          <CommunicationAreaNavigationItemAvatar
            className={itemClasses(CommunicationCenterDisplayMode.SETTINGS)}
            onClick={() => { appState.setCommunicationCenterDisplayMode(CommunicationCenterDisplayMode.SETTINGS) }}
          >
            <AvatarRootArea>
              <AvatarWithPresenceState
                avatarSize={30}
                badgeSize={10}
                badgeRight={25}
                badgeTop={18}
                showAvatarBadge={true}
                userId={userState.user()?.profileId}
                initPresenceByList={false}
                userType={userState.user()?.type}
                content={{ pictureUrl: userState.user()?.logoUrl, alt: ([userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")) }} >
              </AvatarWithPresenceState>
            </AvatarRootArea>
          </CommunicationAreaNavigationItemAvatar>
        </OverlayTrigger>
      }
    </NavigationAreaRoot >
  )
}

interface ContentProps {
  logout: (beConnectionToken: string) => void,
  unreadArray: {
    requests: number
    contacts: number
    conversations: number
    schedules: number
  }
}

const Content: React.FunctionComponent<ContentProps> = (props) => {
  const appState = useAppState()

  switch (appState.communicationCenterDisplayMode) {
    case CommunicationCenterDisplayMode.NETWORKING: return <NetworkingTab requests={props.unreadArray.requests} contacts={props.unreadArray.contacts} />
    case CommunicationCenterDisplayMode.CHATS: return <ChatsTab conversations={props.unreadArray.conversations} />
    case CommunicationCenterDisplayMode.SCHEDULE: return <ScheduleTab schedules={props.unreadArray.schedules} />
    case CommunicationCenterDisplayMode.NOTIFICATIONS: return <NotificationsTab />
    case CommunicationCenterDisplayMode.SETTINGS: return <SettingsTab logout={props.logout} />
  }
  return <Fragment />
}

export const SearchBarWidth = "35ch";

export const TabContentArea = styled.div`
  margin-top: 15px;
  width: 100%;
`;

export const TabEmpty = styled.div`
  padding-top: 30px;
  margin-left: 7%;
  margin-right: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family:${branding.font1};
  font-size: 15px;
  color: ${branding.mainInfoColor ?? "black"};
`;

export const MultiSwitchRoot = styled.div`
  z-index: 0;
  margin-top: 20px;
`;

export const TabRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 80%;
  flex-grow: 1;
  .person-details {
    color: ${branding.mainInfoColor ?? "black"};
  }
  .expanded {
    color: ${branding.primaryColor ?? "black"};
  }
`;

export const SubTabRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  height: calc(100vh - 225px);
  overflow-x: hidden;
`;

export const NextPageLoader = styled(CenteredLoader)`
    height: 120px;
    min-height: 120px;
`;

export default CommunicationArea;


/*********************************************************************************************
 * Profile image
 **********************************************************************************************/
const ProfileImageContainer = styled.div`
    padding-bottom:10px;
    padding-right:4px;
    margin-top:0px;


    & .profile-img{
          width: 30px;
        height: 30px;
        border-radius:50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #f7f7fa;
    }
`

const ProfileImageBiggerContainer = styled.div`
    padding-bottom:10px;
    padding-right:4px;
    margin-top:0px;


    & .profile-img{
        width: 50px;
        height: 50px;
        border-radius:50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #f7f7fa;
    }
`

const TabFooterRoot = styled.div`
  position: fixed;
  background: #fff;
  height: 60px;
  width: 350px;
  bottom: 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  border-top: 1px solid ${branding.sideIconBar.inactiveItemColor};
`

const NetworkingToggleRoot = styled.div`
  cursor: pointer;
  float: left;
  max-width: 40px;
  width: 20px;
  text-align: center;
  margin-top: 15px;
  margin-left: 20px;
`

const NetworkingToggleIconFooter = styled.div`
    z-index: 2;
    transform: rotate(0deg);
    transition: transform 0.8s, right 0.3s ease-in-out;
    background-color: hsla(0, 0%, 100%, 0%);
    color: ${branding.mainInfoColor ?? "black"};

    /* margin: 15px 10px 0 15px; */
    &:hover .NavItem {
      fill: gray;
    }
    .networkingCollapsed & {
      transform: rotate(180deg) scale(1);
      margin-right: 20px;
    } 
`


const FooterLogo = styled.img` 
    position: relative;
    height: ${branding.communicationArea.organizerLogoHeight ?? "20px"};
    margin-top: 15px; 
    margin-left: ${branding.communicationArea.organizerLogoMarginLeft ?? "80px"};
    object-fit: contain;
`

export const TabFooter: React.FunctionComponent = () => {
  const appState = useAppState()
  const logo: string = "/branding/organizer_logo.png"

  return <TabFooterRoot>
    <NetworkingToggleRoot
      onClick={appState.toggleNetworking}
    >
      <NetworkingToggleIconFooter>
        {IconNetworkingToggleClose({ fill: branding.sideIconBar.sideIconColorDark })}
      </NetworkingToggleIconFooter>

    </NetworkingToggleRoot>
    <FooterLogo src={logo} />
  </TabFooterRoot>
}

interface ProfileImageProps {
  imageUrl: string,
  profileId: string,
  biggerContainer: boolean,
  setLogoUrl?: Function
}

export function ProfileImage(props: ProfileImageProps) {

  const [imagePreview] = useState('');
  const [imageLoading] = useState(false);

  return props.biggerContainer ?
    <ProfileImageBiggerContainer>
      <div className="profile-img" style={{ backgroundImage: `url("${imagePreview || props.imageUrl}")` }}>
        {imageLoading && <CenteredLoader />}
      </div>
    </ProfileImageBiggerContainer> :
    <ProfileImageContainer>
      <div className="profile-img" style={{ backgroundImage: `url("${imagePreview || props.imageUrl}")` }}>
        {imageLoading && <CenteredLoader />}
      </div>
    </ProfileImageContainer>
}

