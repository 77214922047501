import styled from "styled-components";
import branding from "../../../branding/branding";
import { TopBarMode } from "../../../navigationArea/TopBar";

export const DetailPageContentRoot = styled.div`
    padding: 0px;
    font-family: ${branding.font1};

    @media(max-width: 1400px) {
       padding: 0px 100px;
    }

`

export const HeaderTitle = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  margin-top: 5px;
  flex: 1;
  align-items: center;
  font-size: 28px;
  font-family: ${branding.font1};

  @media(max-width: 1600px) {
       font-size: 24px;
  }

`

export const FormattedDateExtensionDiv = styled.div`
    border: 1px solid ${branding.mainInfoColor};
    /* border-radius: 5px; */
    display: inline-block; 
    padding: 5px; 
    margin-left: 10px;
    white-space: nowrap;
`

export const FormattedDatePar = styled.p`
    display: inline;
    font-size: 18px;

    @media (max-width: 1600px) {
        font-size: 14px;   
    }
`


export const ScrollContainerRoot = styled.div`
    z-index: 0;
    & .ScrollbarsCustom-Scroller{
        margin-right: -30px !important;
    }

    & .ScrollbarsCustom-Content{
        padding: 0 !important;
    }

    & .ScrollbarsCustom{
        z-index: 0;
    }
`



/* #region  Company info and description section */

export const aspectRatio = "25%"; //4:1
export const aspectRatioShrinked = "220px";
interface DetailsRootProps {
    paddingBottom: string
    windowSize: number
    isNetworkingOpen: boolean
    mode?: TopBarMode
}

export const DetailsRoot = styled.div<DetailsRootProps>`
    ${props => {
        if (props.mode === TopBarMode.ABOVE_PAGE)
            return `

            .networkingCollapsed  {
                background-color: transparent;
                color: white;
                border: none;
                position: relative;
            } 

            .active:first-child  {
                color: ${branding.secondaryTextColor ?? "white"} !important;
                background-color: transparent;
                border: none;
                position: relative;
            } 

            .active:first-child:after {
                content: " ";
                border-bottom: 1px solid white;
                width: ${(props.paddingBottom !== aspectRatio || (!props.isNetworkingOpen && props.windowSize < 1050) || (props.isNetworkingOpen && props.windowSize < 1300 && props.windowSize > 1100)) ? "98%" : "0"};
                display: block;
                position: absolute;
                top: 95%;
                left: 1%;
                transition: width linear .5s;
            }

            .networkingCollapsed:after {
                content: " " ;
                border-bottom: 1px solid white;
                width: ${(props.paddingBottom !== aspectRatio || (!props.isNetworkingOpen && props.windowSize < 1050) || (props.isNetworkingOpen && props.windowSize < 1300 && props.windowSize > 1100)) ? "98%" : "0"};
                display: block;
                position: absolute;
                top: 95%;
                left: 1%;
                transition: width linear .5s;
            }
            `
    }}




    .MuiTypography-body1 a{
        color: #FFF;
    }

    .user-banner-root{
        z-index: 1;
    }

    @media (max-width: 1400px) {
       /* height:${props => props.paddingBottom !== aspectRatio ? "180px" : aspectRatioShrinked};   */
    }

    
`
/* #endregion */