import eventState, { EventPhase } from './globalStates/EventState'

export const isEventAnnouncementPhase: boolean = eventState.phase === EventPhase.EVENT_ANNOUNCEMENT

export const isTicketSalePhase: boolean = eventState.phase === EventPhase.TICKET_SALE

export const isOnboardingPhase: boolean = eventState.phase === EventPhase.ONBOARDING

export const isExplorationPhase: boolean = eventState.phase === EventPhase.EXPLORATION

export const isSoftOpeningPhase: boolean = eventState.phase === EventPhase.SOFT_OPENING

export const isLivePhase: boolean = eventState.phase === EventPhase.LIVE

export const isPostEventPhase: boolean = eventState.phase === EventPhase.POST_EVENT

export const isExplorationOrPostEventPhase : boolean = eventState.phase === EventPhase.EXPLORATION || eventState.phase === EventPhase.POST_EVENT