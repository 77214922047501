import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from "react"
import styled from "styled-components"
import Scrollbar, { ScrollbarProps } from "react-scrollbars-custom";
import ScrollBarComponent from "./ScrollBar"
import { debounce } from "lodash";

const ScrollContainerRoot = styled(Scrollbar)`
    & .ScrollbarsCustom-Track {
        opacity: 0.4;
        transition: opacity 0.4s ease-in-out;
        visibility: hidden; 
    }
    &:hover .ScrollbarsCustom-Track {
        opacity: 1;
        visibility: visible;
        overflow-y: auto;
    }
`

export type ScrollContainerProps = ScrollbarProps & {
}
const ScrollContainer: React.FC<ScrollContainerProps> = (props) => {
    return React.createElement(ScrollContainerRoot, { ...props, disableTracksWidthCompensation: true, scrollerProps: props.ref }, props.children)
}
export default ScrollContainer

const ScrollContainerStyled = styled(ScrollContainer) <{ width?: string, white?: string, padding?: string, containerwidth?: string }>`

  ${props => props.containerwidth ? `width: ${props.containerwidth} !important;}` : ""};
  & .ScrollbarsCustom-Content {
    padding: 0 15px 60px ${props => props.padding} !important;
    width: ${props => props.width};
  }

  & .ScrollbarsCustom-Track {
      z-index: 2;
  }

  .ScrollbarsCustom-Thumb {
      background-color: ${props => props.white === "true" ? "white !important" : ""};
  }
`;

const RoundScrollableDiv = styled.div<{ width?: string, height?: string }>`
    height: ${props => props.height ? props.height : "auto;"};
    width: ${props => props.width ? props.width : "100%;"};
    overflow: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    ::-webkit-scrollbar {
        display: none;
    }
`

const HorizontalRoundScrollableDiv = styled.div<{ width: string, height?: string }>`
    height: ${props => props.height ? props.height : "auto;"};
    width: ${props => props.width ? props.width : "100%;"};
    overflow: auto;
`

const RoundScrollbarParent = styled.div<{ startScrolling: boolean }>`
    position: absolute;
    width: calc(100% - 100px);
    top: ${props => props.startScrolling ? "30px" : "170px"};
    z-index: 5;
`

const HorizontalScrollbarKiller = styled.div`
    position: absolute;
    width: 100%;
    height: 15px;
    background: #FFF;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
`

interface ContentScrollContainerProps {
    adjustForHeaderWith?: string
    noScrollX?: boolean
    width?: string
    containerWidth?: string
    padding?: string
    scrollType?: string
    white?: string
    handleScroll?(value: number): void
    roundHorizontalScrollbar?: boolean
    startPosition?: number
    historyState?: any
    setScrollX?(sx: number): void
    setScrollY?(sy: number): void
}
export const ContentScrollContainer: React.FC<ContentScrollContainerProps> = (props) => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState(props.startPosition ?? 0);
    const [value, setValue] = useState(props.startPosition ?? 0);
    const maxScrollRange = scrollRef.current?.scrollHeight! - scrollRef.current?.clientHeight!;
    let lastY = props.roundHorizontalScrollbar ? scrollRef?.current?.scrollTop! : 0;
    const historyState = window.history.state && window.history.state.state !== undefined ? window.history.state.state : null
    const onScrollEnd = useCallback(debounce((currY: any) => {
        if(props.setScrollX) {
            props.setScrollX(scrollRef.current?.scrollLeft!)
        }
        if(props.setScrollY) {
            props.setScrollY(currY)
        }
    },250), [])

    useEffect(() => {
        if (props.roundHorizontalScrollbar) {
            scrollRef.current?.scrollTo(position, window.history.state && window.history.state.state !== undefined && window.history.state.state.scrollY ? window.history.state.state.scrollY : lastY);
        }  else {
            scrollRef.current?.scrollTo(0, position)
        }

        
        //eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [position])

    useLayoutEffect(() => {
        if(historyState && historyState.scrollYList)
            document.getElementsByClassName("ScrollbarsCustom-Scroller").item(0)?.scrollTo(0, historyState.scrollYList)
    }, []) //eslint-disable-line 

    if (props.roundHorizontalScrollbar) {
        return (
            <>
                <RoundScrollbarParent startScrolling={scrollRef?.current?.scrollTop! > 20}>
                    <ScrollBarComponent value={value} maxScrollRange={scrollRef.current?.scrollWidth! - scrollRef.current?.offsetWidth!}
                        onScroll={(e: any) => {
                            setPosition(e);
                            setValue(e);
                        }} />
                </RoundScrollbarParent>
                <HorizontalRoundScrollableDiv ref={scrollRef} onScroll={(e: any) => {
                    const currY = e.currentTarget.scrollTop;
                    const y = (currY > lastY) ? 'down' : ((currY === lastY) ? 'none' : 'up');
                    if (props.handleScroll && (y === 'down' || y === "up"))
                        props.handleScroll(e.currentTarget);
                    setValue(scrollRef.current?.scrollLeft!)
                    lastY = currY;
                    onScrollEnd(currY)
                }}
                    width={props.width!} height={props.adjustForHeaderWith ? "calc(100vh - " + props.adjustForHeaderWith + ")" : "100vh"}>
                    {props.children}
                    <HorizontalScrollbarKiller />
                </HorizontalRoundScrollableDiv>
            </>
        )
    } else
        return <>
            {props.scrollType === "round" ?
                <>
                    <RoundScrollableDiv ref={scrollRef} onScroll={() => setValue(scrollRef.current?.scrollTop!)} width={props.width} height={props.adjustForHeaderWith ? "calc(100vh - " + props.adjustForHeaderWith + ")" : "100vh"}>
                        {props.children}
                    </RoundScrollableDiv>
                    <ScrollBarComponent value={value} maxScrollRange={maxScrollRange ? maxScrollRange : 0} onScroll={(val: number) => { setPosition(val); setValue(val); }} isVertical={true}></ScrollBarComponent>
                </>
                :
                <ScrollContainerStyled  onScroll={(e: any) => { if (props.handleScroll) props.handleScroll(e);}} onScrollStop={(e: any) => {if(props.setScrollY) props.setScrollY(e.scrollTop)}} noScrollX={props.noScrollX} style={{
                    height: props.adjustForHeaderWith ? "calc(100vh - " + props.adjustForHeaderWith + ")" : "100vh"
                }}
                    width={props.width} white={props.white} padding={props.padding} containerwidth={props.containerWidth}>{props.children}</ScrollContainerStyled>
            }
        </>
}

ContentScrollContainer.defaultProps = {
    noScrollX: true,
    width: "100%",
    white: "false",
    padding: "15px"
} as Partial<ContentScrollContainerProps>
