import { API, graphqlOperation } from "aws-amplify";
import Picker from 'emoji-picker-react';
import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import Observable from 'zen-observable-ts';
import { ConversationType, OnConversationUpdatedLightSubscription, OnUserConversationCreatedByConversationLightSubscription, OnUserConversationDeletedByConversationLightSubscription } from "../API";
import { useLanguageState } from "../globalStates/LanguageState";
import { addParticipantsToGroupChatConversation, createPrivateChatConversation, deleteUserConversation, findChatConversation, findChatConversationById, getUserById, sendChatMessage, setMuteStatus, updateGroupChatConversationDescription, updateGroupChatConversationName, UserResponse, createCalendarEntryChatConversation } from "../backendServices/BackendServices"; // eslint-disable-line react-hooks/exhaustive-deps
import branding, { LocalizedString } from "../branding/branding";
import OutsideAlerter from "../conference/components/OutsideAlerter";
import { useAppState, defaultLogger as logger } from "../globalStates/AppState";
import { useLoggedInState, User } from "../globalStates/LoggedInUser";
import { onConversationUpdatedLight, onUserConversationCreatedByConversationLight, onUserConversationDeletedByConversationLight } from "../graphql/ownSubscriptions";
import CenteredLoader from "../ui/CenteredLoader";
import { IconEmojiDefault, IconEmojiSelected, IconSendMessage } from "../ui/Icons";
import { GroupChatHeader, PrivateChatHeader } from "./ChatPageHeader";
import ChatPageMessageList from "./ChatPageMessageList";
import ConversationDetails from "./ConversationDetails";

export interface ChatBranding {
    groupChat: LocalizedString
    youText: LocalizedString
    detailsText: LocalizedString
    newChatText: LocalizedString
    newGroupText: LocalizedString
    searchForPeopleText: LocalizedString
    participantsText: LocalizedString
    newGroupChatText: LocalizedString
    setGroupNameText: LocalizedString
    addGroupDescriptionText: LocalizedString
    findParticipantsText: LocalizedString
    startChatText: LocalizedString
    addParticipantsText: LocalizedString
    disableAddedText: LocalizedString
    exitGroupText: LocalizedString
    exitGroupChatText: LocalizedString
    cancelText: LocalizedString
    exitText: LocalizedString
    muteInstedText: LocalizedString
    closeText: LocalizedString
    noResultFoundText: LocalizedString
    titleMeeting: LocalizedString
    meetingCancelled: LocalizedString
}

export class ChatConversationParam {
    conversationType: ConversationType
    conversationId?: string
    opponentIds?: string[]

    private constructor(conversationType: ConversationType, conversationId?: string, opponentIds?: string[]) {
        this.conversationType = conversationType;
        this.conversationId = conversationId
        this.opponentIds = opponentIds
    }

    static conversationByConversationId = (conversationType: ConversationType, conversationId: string) => {
        return new ChatConversationParam(conversationType, conversationId)
    }

    /**
     * ChatPage tries to find the conversation ID via the field Conversation.memberIdsHash or creates a new one if no one exists
     * @param opponentId
     */
    static privateConversationByOpponentId(opponentId: string) {
        return new ChatConversationParam(ConversationType.PRIVATE, undefined, [opponentId])
    }

}


// 1 to 1 conversation (always exactly 2 participants)
export interface PrivateConversationParam {
    conversationId?: string // If not set, the ChatPage tries to find the conversation ID via the field Conversation.memberIdsHash
    opponentId?: string     // must be set if no conversationId is provided
}


export interface ConversationParticipant {
    id: string
    name: string
    pictureUrl?: string
    //===============
    position?: string
    organization?: string
}

const ChatContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
    width: 100%;
    height: 99%;
    display: flex;
    flex-direction: column;
    color: black;
    font-family: ${branding.font1};
`
const ChatBody = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`
const MessagePanel = styled.div`
    position: relative;
    display: flex;
    justify-content:space-between;
    align-items: flex-start;
    padding: 10px;

    &.light {
        border-top: 1px solid;
        border-color: ${branding.mainInfoColor ?? "black"};
        background: white;
    }
    &.dark {
        border-top: 1px solid;
        border-color: ${branding.mainInfoDarkColor ?? "white"};
        background: ${branding.darkenThemeBackgroundColor ?? "black"};
    }
`
const MessageInput = styled.textarea`
/* font-size: 12px; */
    font-size: 14px;
    line-height: 17px;
    flex-grow: 1;
    padding: 8px 8px;
    border: 1px solid transparent;
    resize: none;
    &:focus {
        outline: none;
    }
    &.light {
        background: white;
        color: ${branding.chatInputColor ?? "black"};
    }
    &.dark {
        background: ${branding.darkenThemeBackgroundColor ?? "black"};
        color: ${branding.chatDarkenInputColor ?? "white"};
    }
`


enum InitState {
    CONVERSATION_KNOWN, // conversation ID is known
    CONVERSATION_NEW, //
    UNCERTAIN, //
    INSUFFICIENT_PROPS
}

export enum DisplayStyle {
    DEFAULT,
    DEFAULT_DARK,
    PINBOARD,
    PINBOARD_DARK
}

export interface ChatPageProps {
    param?: ChatConversationParam // used when ChatPage is embedded outside the communication area 
    displayStyle?: DisplayStyle
}

const ChatPage: React.FC<ChatPageProps> = (props) => {
    const appState = useAppState()
    const conversationParam = props?.param ?? appState.communicationCenterDisplayParam
    if (conversationParam?.conversationType === ConversationType.PRIVATE) {
        return <PrivateChatPageContent {...conversationParam} />
    } else if (conversationParam?.conversationId && (conversationParam?.conversationType === ConversationType.GROUP || conversationParam?.conversationType === ConversationType.CALENDARENTRY || conversationParam?.conversationType === ConversationType.CALL)) {
        return <GroupChatPageContent conversationId={conversationParam.conversationId} conversationType={conversationParam.conversationType} />
    } else if (conversationParam?.conversationType === ConversationType.PUBLIC) {
        return <PublicChatPageContent conversationId={conversationParam.conversationId!} displayStyle={props.displayStyle} />
    }
    else return <div>Error</div>
}


function convertProfileToPaticipant(profile: User) {
    return { id: profile.profileId, name: [profile.firstName, profile.lastName].filter(Boolean).join(" "), pictureUrl: profile.logoUrl }
}

const PrivateChatPageContent: React.FunctionComponent<ChatConversationParam> = (params) => {

    const opponentIdParam = params.opponentIds ? params.opponentIds[0] : undefined

    const loggedInState = useLoggedInState()

    const [initState, setInitState] = useState(InitState.UNCERTAIN)
    const [conversationId, setConversationId] = useState(params.conversationId)
    const [userConversationId, setUserConversationId] = useState<string | undefined>(undefined)
    const [isMuted, setIsMuted] = useState(false)
    const [opponent, setOpponent] = useState<ConversationParticipant | undefined>(undefined)

    const profile = loggedInState.user()!
    const profileId = profile?.profileId || ''
    const participantMap = useRef<Map<string, ConversationParticipant>>(new Map([[profileId, convertProfileToPaticipant(profile)]]))

    useEffect(() => {

        setInitState(InitState.UNCERTAIN)
        setConversationId(params.conversationId)
        setUserConversationId(undefined)
        setIsMuted(false)
        setOpponent(undefined)

        if (!params.conversationId && !opponentIdParam) {
            setInitState(InitState.INSUFFICIENT_PROPS)
            return
        }

        async function fetchAndSetOpponent(opponentId: string): Promise<boolean> {
            const userResponse = await getUserById(opponentId)
            let user
            if (!(user = (userResponse as UserResponse)?.getUser)) {
                return false
            }
            const opponent = { id: user.id, name: user.name, pictureUrl: user.pictureUrl };
            setOpponent(opponent)
            participantMap.current.set(opponent.id, opponent)
            return true
        }

        (async () => {
            if (opponentIdParam) {
                if (!await fetchAndSetOpponent(opponentIdParam)) {
                    setInitState(InitState.INSUFFICIENT_PROPS)
                    return
                }
                const result = await findChatConversation(profileId, opponentIdParam)
                if (result) {
                    const { conversationId, userConversationId, isMuted } = result
                    setConversationId(conversationId)
                    setUserConversationId(userConversationId)
                    setIsMuted(isMuted)
                    setInitState(InitState.CONVERSATION_KNOWN)
                } else {
                    setInitState(InitState.CONVERSATION_NEW)
                }
            } else if (params.conversationId) {
                const result = await findChatConversationById(params.conversationId, profileId, 2)
                if (result) {
                    setUserConversationId(result.userConversationId)
                    setIsMuted(result.isMuted)
                    const opponent = result.opponents[0];
                    setOpponent(opponent)
                    participantMap.current.set(opponent.id, opponent)
                    setInitState(InitState.CONVERSATION_KNOWN)
                } else {
                    setInitState(InitState.CONVERSATION_NEW)
                }
            }
        })()

    }, [opponentIdParam, params.conversationId, profileId])

    const createNewConversation = useCallback(async () => {
        if (conversationId) return conversationId

        if (opponent) {
            const findConversationResult = await findChatConversation(profileId, opponent.id)
            if (findConversationResult) {
                const { conversationId, userConversationId, isMuted } = findConversationResult
                setConversationId(conversationId)
                setUserConversationId(userConversationId)
                setIsMuted(isMuted)
                setInitState(InitState.CONVERSATION_KNOWN)
                return conversationId
            }
            const createConversationResult = await createPrivateChatConversation(profileId, opponent.id)
            if (createConversationResult) {
                const { conversationId, userConversationId, isMuted } = createConversationResult
                setConversationId(conversationId)
                setUserConversationId(userConversationId)
                setIsMuted(isMuted)
                setInitState(InitState.CONVERSATION_KNOWN)
                return conversationId
            } else {
                throw new Error(`could not create a new conversation for user '${profileId}' and '${opponent.id}'`)
            }
        } else {
            throw new Error("could not create a new conversation because no opponent was given")
        }
    }, [conversationId, opponent, profileId])

    if (initState === InitState.UNCERTAIN) {
        return <CenteredLoader />
    }

    if (initState === InitState.INSUFFICIENT_PROPS) {
        throw new Error("Either 'conversationId' or 'opponentIds' must be specified")
    }

    return (
        <ChatContainer>
            <PrivateChatHeader isMuted={isMuted} setIsMuted={setIsMuted} userConversationId={userConversationId!} opponent={opponent!} />
            <ChatPageMessageList profileId={profileId} conversationId={conversationId} participants={participantMap.current} conversationType={params.conversationType} userConversationId={userConversationId} />
            <MessageDraftPanel profileId={profileId} conversationIdProvider={conversationId ?? createNewConversation} />
        </ChatContainer>
    )
}


interface UpdateConversationSubscription {
    value: {
        data: OnConversationUpdatedLightSubscription
    }
}
interface UserConversationCreatedSubscription {
    value: {
        data: OnUserConversationCreatedByConversationLightSubscription
    }
}
interface UserConversationDeletedSubscription {
    value: {
        data: OnUserConversationDeletedByConversationLightSubscription
    }
}

interface GroupChatPageContentProps {
    conversationId: string
    conversationType: ConversationType
}

const GroupChatPageContent: React.FunctionComponent<GroupChatPageContentProps> = (props) => {

    const appState = useAppState()
    const loggedInState = useLoggedInState()
    const profile = loggedInState.user()!
    const profileId = profile.profileId || ''
    const [initState, setInitState] = useState(InitState.UNCERTAIN)
    const [userConversationId, setUserConversationId] = useState<string | undefined>(undefined)
    const [conversationName, setConversationName] = useState<string | undefined>(undefined)
    const [conversationDesc, setConversationDesc] = useState<string | undefined>(undefined)
    const [isMuted, setIsMuted] = useState(false)
    const [opponents, setOpponents] = useState<ConversationParticipant[] | undefined>(undefined)
    const participantMap = useRef<Map<string, ConversationParticipant>>(new Map())
    const [showDetails, setShowDetails] = useState(false)

    // ===================
    // Subscriptions Start
    // ===================

    useEffect(() => {
        const onUpdateConversationHandle = (API.graphql(graphqlOperation(onConversationUpdatedLight, { id: props.conversationId })) as Observable<UpdateConversationSubscription>).subscribe({
            next: (resp: UpdateConversationSubscription) => {
                const updatedName = resp.value.data.onConversationUpdated?.name
                const updatedDesc = resp.value.data.onConversationUpdated?.description
                setConversationName(updatedName ?? undefined)
                setConversationDesc(updatedDesc ?? undefined)
            },
            error: (error) => {
                logger.error({ message: "ChatPage.tsx Subscription Error Conversation Update", errorMessage: error.message, errorStack: error.stack });
            },
            complete: () => {
                logger.info("Subscription Complete Conversation Update")
            }
        })
        return () => onUpdateConversationHandle.unsubscribe()
    }, [props.conversationId])

    useEffect(() => {
        const onCreateUserConversationHandle = (API.graphql(graphqlOperation(onUserConversationCreatedByConversationLight, { conversationId: props.conversationId })) as Observable<UserConversationCreatedSubscription>).subscribe({
            next: (resp: UserConversationCreatedSubscription) => {
                const user = resp.value.data.onUserConversationCreatedByConversation?.user
                if (user) {
                    const newOpponent = { id: user.id, name: user.name ?? "", pictureUrl: user.pictureUrl ?? undefined };
                    setOpponents(ops => ops?.find(op => op.id === user.id) ? ops : (ops ?? []).concat([newOpponent]))
                }
            },
            error: (error) => {
                logger.error({ message: "ChatPage.tsx Subscription Error UserConversation Create", errorMessage: error.message, errorStack: error.stack });
            },
            complete: () => {
                logger.info("Subscription Complete UserConversation Create")
            }
        })
        return () => onCreateUserConversationHandle.unsubscribe()
    }, [props.conversationId])

    useEffect(() => {
        const onDeleteUserConversationHandle = (API.graphql(graphqlOperation(onUserConversationDeletedByConversationLight, { conversationId: props.conversationId })) as Observable<UserConversationDeletedSubscription>).subscribe({
            next: (resp: UserConversationDeletedSubscription) => {
                const userId = resp.value.data.onUserConversationDeletedByConversation?.userId
                if (userId === profileId) {
                    appState.setShowChatsTab(null) // conversation left from another window
                } else {
                    setOpponents(ops => ops?.filter(op => op.id !== userId))
                }
            },
            error: (error) => {
                logger.error({ message: "ChatPage.tsx Subscription Error UserConversation Delete", errorMessage: error.message, errorStack: error.stack });
            },
            complete: () => {
                logger.info("Subscription Complete UserConversation Delete")
            }
        })
        return () => onDeleteUserConversationHandle.unsubscribe()
    }, [props.conversationId, profileId]) // eslint-disable-line react-hooks/exhaustive-deps

    // =================
    // Subscriptions End
    // =================

    useEffect(() => {

        // Different chat -> reset all states
        setInitState(InitState.UNCERTAIN)
        setUserConversationId(undefined)
        setIsMuted(false)
        setOpponents(undefined)
        setShowDetails(false)
        participantMap.current.clear();

        (async () => {
            const result = await findChatConversationById(props.conversationId, profileId, branding.configuration.calendarEntryParticipantLimit)
            let userConversationId = result?.userConversationId
            if (result) {
                setUserConversationId(userConversationId)
                setIsMuted(result.isMuted)
                setConversationName(result.name)
                setConversationDesc(result.description)
                setOpponents(result.opponents)
                setInitState(InitState.CONVERSATION_KNOWN)
            } else { // Conversation not found => lambda failed to create the conversation
                if (props.conversationType === ConversationType.CALENDARENTRY) {
                    const result = await createCalendarEntryChatConversation(props.conversationId, profileId)
                    if (result) {
                        setInitState(InitState.CONVERSATION_KNOWN)
                    }
                    return
                }
                throw new Error(`conversation '${props.conversationId}' not found`)
            }
        })()
        // eslint-disable-next-line
    }, [props.conversationId, profileId])

    useEffect(() => {
        const me = { id: profileId, name: [profile.firstName, profile.lastName].filter(Boolean).join(" "), pictureUrl: profile.logoUrl }
        participantMap.current.set(profileId, me)
        opponents?.forEach(op => participantMap.current.set(op.id, op))
    }, [opponents, profileId, profile.firstName, profile.lastName, profile.logoUrl])


    if (initState === InitState.UNCERTAIN) {
        return <CenteredLoader />
    }

    if (initState === InitState.INSUFFICIENT_PROPS) {
        throw new Error("Either 'conversationId' or 'opponentIds' must be specified")
    }

    const addOpponents = async (newOpponents: ConversationParticipant[]) => {
        const result = await addParticipantsToGroupChatConversation(props.conversationId, newOpponents.map(op => op.id), true)
        if (result) {
            setOpponents(ops => (ops ?? []).concat(newOpponents))
            return true
        }
        return false
    }

    const exitGroup = async () => {
        if (userConversationId) {
            const success = await deleteUserConversation(userConversationId)
            if (success) {
                appState.setShowChatsTab(null)
                return true
            } else {
                // TODO error handling
            }
        }
        return false
    }

    const toggleMuteGroup = async () => {
        if (userConversationId) {
            const isMutedNew = await setMuteStatus(userConversationId, !isMuted)
            if (isMutedNew !== undefined) {
                setIsMuted(isMutedNew)
                return true
            }
        }
        return false
    }

    const updateConversationName = async (newName: string) => {
        const trimmedName = newName.trim()
        const conv = await updateGroupChatConversationName(props.conversationId, trimmedName ? trimmedName : null)
        const updatedName = conv?.name ?? undefined
        setConversationName(updatedName)
        return updatedName
    }

    const updateConversationDesc = async (newDescription: string) => {
        const conv = await updateGroupChatConversationDescription(props.conversationId, newDescription.trim())
        const updatedDescription = conv?.description ?? undefined;
        setConversationDesc(updatedDescription)
        return updatedDescription
    }

    if (opponents === undefined) {
        return <CenteredLoader />
    }

    return (
        <ChatContainer>
            <GroupChatHeader conversationId={props.conversationId} conversationType={props.conversationType} conversationName={conversationName} conversationDescription={conversationDesc} isMuted={isMuted} toggleMuteGroup={toggleMuteGroup} userConversationId={userConversationId} opponents={opponents} showDetails={showDetails} setShowDetails={setShowDetails} />
            <ChatBody style={showDetails ? { display: "none" } : {}}>
                <ChatPageMessageList profileId={profileId} conversationId={props.conversationId} participants={participantMap.current} conversationType={ConversationType.GROUP} userConversationId={userConversationId} />
                <MessageDraftPanel profileId={profileId} conversationIdProvider={props.conversationId} />
            </ChatBody>
            {showDetails &&
                <ChatBody>
                    <ConversationDetails conversationId={props.conversationId}
                        conversationName={conversationName}
                        conversationDesc={conversationDesc}
                        conversationType={props.conversationType}
                        setConversationName={updateConversationName}
                        setConversationDesc={updateConversationDesc}
                        opponents={opponents ?? []}
                        closeDetails={() => setShowDetails(false)}
                        maxParticipants={branding.configuration.chatParticipantLimit ?? 10}
                        addOpponents={addOpponents}
                        exitGroup={exitGroup}
                        isMuted={isMuted}
                        muteGroup={toggleMuteGroup} />
                </ChatBody>
            }
        </ChatContainer>
    )
}


interface PublicChatPageContentProps {
    conversationId: string
    displayStyle?: DisplayStyle
}

const PublicChatPageContent: React.FunctionComponent<PublicChatPageContentProps> = (props) => {

    const loggedInState = useLoggedInState()
    const participantMap = useRef<Map<string, ConversationParticipant>>(new Map())

    const profile = loggedInState.user()!
    const profileId = profile.profileId || ''

    useEffect(() => {
        const me = { id: profileId, name: [profile.firstName, profile.lastName].filter(Boolean).join(" "), pictureUrl: profile.logoUrl }
        participantMap.current.set(profileId, me)
    }, [profileId, profile.firstName, profile.lastName, profile.logoUrl])

    return (
        <ChatContainer>
            <ChatPageMessageList profileId={profileId} conversationId={props.conversationId} participants={participantMap.current} conversationType={ConversationType.PUBLIC} displayStyle={props.displayStyle} />
            <MessageDraftPanel profileId={profileId} conversationIdProvider={props.conversationId} displayStyle={props.displayStyle} />
        </ChatContainer>
    )
}



const Button = styled.div`
  padding: 10px 10px;
  border-radius: 25px;
  cursor: pointer;

  &.invisible {
      visibility: hidden;
  }

  &.light {
    background-color: white;
  }

  &.dark {
    background-color: ${branding.darkenThemeBackgroundColor ?? "black"};
  }
`

const HoverButton = styled.div`
  padding: 10px 10px;
  transition: background-color 0.7s;
  border-radius: 25px;
  cursor: pointer;

  &.invisible {
      visibility: hidden;
  }

  &.light {
    background-color: white;
  }
  &.light:hover {
    background-color: ${branding.lightThemeHoverColor ?? "hsl(0,0%,80%)"};
  }

  &.dark {
    background-color: ${branding.darkenThemeBackgroundColor ?? "black"};
  }
  &.dark:hover {
    background-color: ${branding.darkenThemeHoverColor ?? "hsl(0,0,20%)"}
  }
`

const MessageSendButton = styled(HoverButton)`
    &.disabled {
        cursor: auto;
        opacity: 0.5;
    }
    &.light.disabled:hover { 
        background-color: ${branding.darkenThemeBackgroundColor ?? "white"}; 
    }
    &.dark.disabled:hover { 
        background-color: ${branding.darkenThemeBackgroundColor ?? "black"};
    }
`

const EmojiPickerWrapper = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 50px;
    right: 20px;

    &.dark div aside.emoji-picker-react {
        box-shadow: 0 5px 10px #101010;
    }
`

interface MessageDraftPanelProps {
    profileId: string,
    conversationIdProvider: string | (() => Promise<string>)
    displayStyle?: DisplayStyle
}

const MessageDraftPanel: React.FC<MessageDraftPanelProps> = (props) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()

    const [messageDraft, setMessageDraft] = useState("")
    const msgInputRef = useRef<HTMLTextAreaElement>(null)

    const [emojiPickerShowing, setEmojiPickerShowing] = useState(false)
    const [inputHeight, setInputHeight] = useState(0)

    const onMessageInputChanged = (messageDraft: string) => {
        setMessageDraft(messageDraft)
    }


    const sendMessageButtonPressed = async () => {
        if (!messageDraft.trim()) return

        // TODO show message as pending
        /* 
        const newMessage = {
            id: uniqueId(),
            authorId: loggedInState.user()!.profileId,
            content: messageDraft,
            timestamp: new Date(Date.now()),
            isSent: false,
            conversationId: "TODO"
        }
        setChatMessages(chatMessages.concat(newMessage))
        */
        setMessageDraft("")
        msgInputRef.current?.focus()

        let conversationId = typeof props.conversationIdProvider === "string" ? props.conversationIdProvider : await props.conversationIdProvider()
        const message = await sendChatMessage(conversationId, props.profileId, messageDraft.trim()) // eslint-disable-line @typescript-eslint/no-unused-vars

        // TODO mark message pending as sent, or do that in the subscription
    }

    useEffect(() => {
        setMessageDraft("")
        msgInputRef.current?.focus()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.conversationIdProvider])


    const onEmojiButtonPressed = () => {
        if (!emojiPickerShowing) // hiding is handled by click outside
            setEmojiPickerShowing(true)

        msgInputRef.current?.focus()
    }

    const onEmojiClick = (event: any, emojiObject: any) => {
        const textarea = msgInputRef.current
        if (!textarea) return
        var startPosition = textarea.selectionStart;
        var endPosition = textarea.selectionEnd;

        const updatedText = textarea.value.substr(0, startPosition) + emojiObject.emoji + textarea.value.substr(endPosition)
        setMessageDraft(updatedText)
        setEmojiPickerShowing(false)

        msgInputRef.current?.focus()
    }

    const isDark = props.displayStyle === DisplayStyle.DEFAULT_DARK || props.displayStyle === DisplayStyle.PINBOARD_DARK
    const themeClass = isDark ? "dark" : "light"
    const sendButtonClass = (!messageDraft.trim() ? "disabled " : " ") + themeClass
    const fillColor = isDark ? branding.sideIconBar.sideIconColorLight ?? "white" : branding.sideIconBar.sideIconColorDark ?? "black"

    return <MessagePanel className={themeClass}>
        {emojiPickerShowing && <EmojiPickerWrapper className={themeClass} style={{ bottom: (10 + inputHeight) + "px" }}>
            <OutsideAlerter onClickOutside={() => setEmojiPickerShowing(false)}>
                <Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} disableSkinTonePicker={true} disableSearchBar={true} />
            </OutsideAlerter>
        </EmojiPickerWrapper>}
        <AutoResizeTextArea reference={msgInputRef} onChange={onMessageInputChanged} value={messageDraft} hint={strings.communicationArea.chatMessageInputHint} onEnterPressed={sendMessageButtonPressed} className={themeClass} onInputHeightChanged={setInputHeight} />
        <Button onClick={onEmojiButtonPressed} style={{ paddingLeft: "11px", paddingRight: "9px" }} className={themeClass} >{emojiPickerShowing ? IconEmojiSelected({ fill: fillColor }) : IconEmojiDefault({ fill: fillColor })}</Button>
        <MessageSendButton onClick={sendMessageButtonPressed} style={{ paddingLeft: "11px", paddingRight: "9px" }} className={sendButtonClass}>{IconSendMessage({ fill: fillColor })}</MessageSendButton>
    </MessagePanel >
}



interface AutoResizeTextAreaProps {
    value: string
    onChange: (text: string) => void
    onEnterPressed: () => void
    onInputHeightChanged?: (height: number) => void
    reference: React.RefObject<HTMLTextAreaElement>
    hint?: string,
    className?: string
}

const AutoResizeTextArea: React.FunctionComponent<AutoResizeTextAreaProps> = (props) => {

    const textAreaRef = props.reference
    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateInputHeight(event.target, props.onInputHeightChanged)
        if (props.onChange) props.onChange(event.target.value)
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (props.onEnterPressed && event.keyCode === 13 && event.shiftKey === false) {
            // const text = textAreaRef.current?.value ?? ""
            // var isSingleLine = !(/\r|\n/.exec(text)); // Checking if the text is single line 
            event.preventDefault()
            props.onEnterPressed()
        }
    }


    useEffect(() => {
        updateInputHeight(textAreaRef?.current, props.onInputHeightChanged);
    }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MessageInput
            className={props.className}
            ref={textAreaRef}
            value={props.value}
            rows={1}
            maxLength={1000}
            onChange={onChange}
            placeholder={props.hint}
            onKeyDown={onKeyDown}
        />
    )
}

declare const InstallTrigger: any

function updateInputHeight(textArea: HTMLTextAreaElement | null, onInputHeightChanged: ((height: number) => void) | undefined) {
    if (textArea) {
        const isFirefox = typeof InstallTrigger !== 'undefined' // eslint-disable-line @typescript-eslint/no-unused-vars
        const extraPadding = 2//isFirefox ? -8 : 2 // doesnt work as intended yet
        textArea.style.height = 'auto';
        const targetHeight = Math.max(Math.min(textArea.scrollHeight + extraPadding, 100), 50);

        textArea.style.height = (targetHeight) + 'px';
        if (onInputHeightChanged) {
            onInputHeightChanged(targetHeight)
        }
        // const lines = textArea.value.match(/[^\n]*\n[^\n]*/gi)?.length
        // textArea.rows = lines ? lines : 1
    }
}

/**
 * Prefixes the given ID with the topic name
 * @param chatContextId Id of related component which the conversation is associated with (e.g. lounge/event id)
 */
export function calcConversationId(chatContextId: string) {
    return [branding.configuration.topicName, chatContextId].join("__")
}

export default ChatPage
