/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const emptySubscription = /* GraphQL */ `
  subscription EmptySubscription {
    _emptySubscription
  }
`;
export const onCalendarEntryParticipationCreated = /* GraphQL */ `
  subscription OnCalendarEntryParticipationCreated($userId: ID!) {
    onCalendarEntryParticipationCreated(userId: $userId) {
      id
      calendarEntryId
      calendarEntry {
        id
        title
        description
        start
        end
        userId
        participants {
          nextToken
        }
        organizationId
        status
        organizationIdStatus
        topicName
        userPoolName
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      userId
      status
      start
      userIdStatus
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const onCalendarEntryParticipationDeleted = /* GraphQL */ `
  subscription OnCalendarEntryParticipationDeleted($userId: ID!) {
    onCalendarEntryParticipationDeleted(userId: $userId) {
      id
      calendarEntryId
      calendarEntry {
        id
        title
        description
        start
        end
        userId
        participants {
          nextToken
        }
        organizationId
        status
        organizationIdStatus
        topicName
        userPoolName
        createdAt
        updatedAt
        user {
          id
          presenceStatus
          lastConnected
          name
          pictureUrl
          createdAt
          updatedAt
        }
      }
      userId
      status
      start
      userIdStatus
      createdAt
      updatedAt
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const onConversationUpdated = /* GraphQL */ `
  subscription OnConversationUpdated($id: ID!) {
    onConversationUpdated(id: $id) {
      id
      type
      name
      description
      userId
      mostRecentMessage
      mostRecentMessageCreatedAt
      memberIdsHash
      updatedAt
      createdAt
      messages {
        items {
          id
          authorId
          content
          conversationId
          isSent
          createdAt
          sotName
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          userId
          conversationId
          isMuted
          isChatOpen
          mostRecentMessageCreatedAt
          lastReadMessageCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onMeetingInviteReceived = /* GraphQL */ `
  subscription OnMeetingInviteReceived($inviteeId: ID!) {
    onMeetingInviteReceived(inviteeId: $inviteeId) {
      id
      meetingId
      meeting {
        id
        participants {
          nextToken
        }
        start
        end
        createdAt
        updatedAt
      }
      inviterId
      inviteeId
      status
      created
      createdAt
      updatedAt
      inviter {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
      invitee {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const onMeetingInviteChanged = /* GraphQL */ `
  subscription OnMeetingInviteChanged($id: ID!) {
    onMeetingInviteChanged(id: $id) {
      id
      meetingId
      meeting {
        id
        participants {
          nextToken
        }
        start
        end
        createdAt
        updatedAt
      }
      inviterId
      inviteeId
      status
      created
      createdAt
      updatedAt
      inviter {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
      invitee {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const onMessageCreated = /* GraphQL */ `
  subscription OnMessageCreated($conversationId: ID!) {
    onMessageCreated(conversationId: $conversationId) {
      id
      authorId
      content
      conversationId
      isSent
      createdAt
      sotName
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
        }
        members {
          nextToken
        }
      }
      author {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const onNotificationCreated = /* GraphQL */ `
  subscription OnNotificationCreated($userId: ID!) {
    onNotificationCreated(userId: $userId) {
      id
      userId
      type
      displayGroup
      content
      userIdDisplayGroup
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUnreadCounterById = /* GraphQL */ `
  subscription OnUpdateUnreadCounterById($id: ID!) {
    onUpdateUnreadCounterById(id: $id) {
      id
      requests
      contacts
      conversations
      schedules
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserById = /* GraphQL */ `
  subscription OnUpdateUserById($id: ID!) {
    onUpdateUserById(id: $id) {
      id
      presenceStatus
      lastConnected
      name
      pictureUrl
      outgoingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      incomingMeetings {
        items {
          id
          meetingId
          inviterId
          inviteeId
          status
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      conversations {
        items {
          id
          userId
          conversationId
          isMuted
          isChatOpen
          mostRecentMessageCreatedAt
          lastReadMessageCreatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUserConversationUpdated = /* GraphQL */ `
  subscription OnUserConversationUpdated($userId: ID!) {
    onUserConversationUpdated(userId: $userId) {
      id
      userId
      conversationId
      isMuted
      isChatOpen
      mostRecentMessageCreatedAt
      lastReadMessageCreatedAt
      createdAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
        }
        members {
          nextToken
        }
      }
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const onUserConversationDeleted = /* GraphQL */ `
  subscription OnUserConversationDeleted($userId: ID!) {
    onUserConversationDeleted(userId: $userId) {
      id
      userId
      conversationId
      isMuted
      isChatOpen
      mostRecentMessageCreatedAt
      lastReadMessageCreatedAt
      createdAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
        }
        members {
          nextToken
        }
      }
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const onUserConversationCreatedByConversation = /* GraphQL */ `
  subscription OnUserConversationCreatedByConversation($conversationId: ID!) {
    onUserConversationCreatedByConversation(conversationId: $conversationId) {
      id
      userId
      conversationId
      isMuted
      isChatOpen
      mostRecentMessageCreatedAt
      lastReadMessageCreatedAt
      createdAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
        }
        members {
          nextToken
        }
      }
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
export const onUserConversationDeletedByConversation = /* GraphQL */ `
  subscription OnUserConversationDeletedByConversation($conversationId: ID!) {
    onUserConversationDeletedByConversation(conversationId: $conversationId) {
      id
      userId
      conversationId
      isMuted
      isChatOpen
      mostRecentMessageCreatedAt
      lastReadMessageCreatedAt
      createdAt
      updatedAt
      conversation {
        id
        type
        name
        description
        userId
        mostRecentMessage
        mostRecentMessageCreatedAt
        memberIdsHash
        updatedAt
        createdAt
        messages {
          nextToken
        }
        members {
          nextToken
        }
      }
      user {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        outgoingMeetings {
          nextToken
        }
        incomingMeetings {
          nextToken
        }
        createdAt
        updatedAt
        conversations {
          nextToken
        }
      }
    }
  }
`;
