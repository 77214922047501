import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useNavigationSource } from "../../tracking/NavigationSource";
import { UserOrganizationVisitSource } from "../../backendServices/BackendServices";
import { EntityType } from "../../backendServices/Types";


export type DetailNavLinkType = EntityType | "user" | "coupon" | "program" | "channel" | "virtualCafeMeetingGroup"
const DetailNavLinkRoot = styled(NavLink)`
  cursor: pointer;
  ${(props) => (props.width ? `width: ${props.width};` : "")}
  &:hover, &:active, &:visited, &:link, &:focus {
    ${(props: DetailNavLinkProps) => (props.showLinkStyles ? "" : "color: inherit;text-decoration: none;")}
  }
`;
type DetailNavLinkProps = {
    id: string;
    name: string;
    type: DetailNavLinkType;
    showLinkStyles?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    source?: UserOrganizationVisitSource;
    width?: string;
};
export const DetailNavLink: React.FC<DetailNavLinkProps> = (props) => {
    const history = useHistory();
    const navigationSource = useNavigationSource();

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        // The meeting recorder has no profile page
        if (props.id === "recorder") return;

        if (props.onClick) {
            props.onClick(event);
        }
        if (!event.isDefaultPrevented()) {
            if (props.source) {
                navigationSource.set(props.source);
            }
            history.push({
                pathname: buildDetailLink(props.id, props.name, props.type),
                search: history.location.search,
            });
        }

        event.stopPropagation();
        event.preventDefault();
    };

    return (
        <DetailNavLinkRoot to={buildDetailLink(props.id, props.name, props.type)} className="detailNavLink" {...props} onClick={handleClick}>
            {props.children}
        </DetailNavLinkRoot>
    );
};

export const buildDetailLink = (id: string, name: string, type: DetailNavLinkType): string => {
    let nameForLink = name.replace(/[^a-z0-9]+/gi, "-");
    nameForLink = nameForLink.replace(/^[^a-z0-9]|[^a-z0-9]$/gi, "");

    const idAppendage = type === "person" ? "p-" + id : type === "user" ? "u-" + id : id
    const typeAppendage = type === "user" ? "person" : type

    if (type === "channel") {
        // Channel link is introduced on backend. There wont be branding for it.
        return "/" + typeAppendage + "/" + id;
        // return "/" + type + "/" + branding.thirdPartyResources.find(elem => elem.id === "slido")?.config.slidoRooms.find(elem => elem.id === id)?.id;
    } else if (type === "organization") {
        // https://jira.corussoft.de/browse/DEP-1023
        return "/company/" + nameForLink + "--" + idAppendage;
    } else if (type === "virtualCafeMeetingGroup") {
        return "/meetings/" + idAppendage;
    } else {
        return "/" + typeAppendage + "/" + nameForLink + "--" + idAppendage;
    }
};

