import React from 'react'
import styled from 'styled-components'
import branding from '../../branding/branding'

const CrsTimePickerColumnRoot = styled.div`
    width: 100%;
    text-align: center;
    height: 100%;
    overflow: auto;
`

const CrsTimePickerColumnTitle = styled.p`
    padding: .2rem 0;
    position: sticky;
    top: 0;
    left: 0;
    color: #8e8e93;
    background-color: #f7f7fa;
    z-index: 1;
`

const CrsTimePickerColumnItem = styled.div`
    padding: .4rem 0;
    cursor: pointer;
    &:hover {
        color: #fff;
        background-color: #000
    }

    &.active {
        color: #fff;
        background-color: ${branding.mainInfoColor};
    }
    &.disabled { 
        color: #c5c6c7;
        cursor: not-allowed;
        text-decoration: line-through;
    }
    
`

interface CrsTimePickerColumnProps {
    title: string
    values?: Array<{ value: string, disabled: boolean }>
    onValueSelected: (value: string) => void
    selectedValue: string | null
}

const CrsTimePickerColumn: React.FunctionComponent<CrsTimePickerColumnProps> = (props) => {
    return (
        <CrsTimePickerColumnRoot>
            <CrsTimePickerColumnTitle>{props.title}</CrsTimePickerColumnTitle>
            {props.values && props.values.map(item => {
                return <CrsTimePickerColumnItem
                    key={item.value}
                    className={[props.selectedValue === item.value ? 'active' : '', item.disabled ? 'disabled' : ''].join(' ')}
                    onClick={() => {
                        if (item.disabled)
                            return;
                        props.onValueSelected(item.value)
                    }
                    }>{item.value}</CrsTimePickerColumnItem>
            })}
        </CrsTimePickerColumnRoot>
    )
}

export default CrsTimePickerColumn