import { createState, useState, self } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import { useMemo } from 'react';
import { PresenceType } from '../backendServices/BackendServices';
import { PrivacyUserAnswer } from '../backendServices/Types';


export const localStorageKey = 'virtualGuide-loggedInUser'
var jwt = require("jsonwebtoken");
export interface User {
    profileId: string,
    email: string,
    firstName?: string,
    lastName?: string,
    infotext?: string,
    interests?: Array<String>,
    logoUrl?: string,
    matchActive?: boolean,
    middleName?: string,
    mobile?: string,
    phone?: string,
    position?: string,
    company?: string,
    areaOfResponsibility?: string,
    presence?: PresenceType,
    facebook?: string,
    linkedIn?: string,
    googleplus?: string,
    youTube?: string,
    twitter?: string,
    xing?: string,
    pinterest?: string,
    instagram?: string,
    type?: string,
    visible?: boolean,
    showroomStandby?: boolean,
    organizations?: Array<{ id: string, name: string }>,
    eventDates?: Array<{ id: string, name: string }>,
    events?: Array<{ id: string, name: string }>,
    invitingOrganization?: { id: string, name: string }
}


export interface UserState {
    user?: User,
    jwtToken?: string,
    privacyUserAnswer2?: PrivacyUserAnswer[]
}

export const useLoggedInState = createState({} as UserState)[self].map(s => () => {
    const state = useState(s)

    const jwtToken = state.jwtToken.get()
    const isLoggedIn = useMemo(() => {
        return jwt.decode(jwtToken)?.sotUserId !== undefined
    }, [jwtToken])

    state[self].attach(Persistence(localStorageKey))

    return ({
        isLoggedIn: isLoggedIn,
        user: () => {
            return state[self].get().user
        },
        jwtToken: () => {
            return state.jwtToken.get()
        },
        updateToken: (jwtToken: string) => {
            state[self].set(prevState => {
                prevState.jwtToken = jwtToken;
                return prevState
            })
        },
        setUser: (user?: User) => {
            state[self].set(prevState => {
                prevState.user = user
                return prevState
            })
        },
        setMatchActive: (matchActive: boolean) => {
            state[self].set(prevState => {
                prevState.user!!.matchActive = matchActive
                return prevState
            })
        },
        setFirstName: (firstName: string) => {
            state[self].set(prevState => {
                prevState.user!!.firstName = firstName
                return prevState
            })
        },
        setLastName: (lastName: string) => {
            state[self].set(prevState => {
                prevState.user!!.lastName = lastName
                return prevState
            })
        },
        setCompany: (company: string) => {
            state[self].set(prevState => {
                prevState.user!!.company = company
                return prevState
            })
        },
        setPosition: (position: string) => {
            state[self].set(prevState => {
                prevState.user!!.position = position
                return prevState
            })
        },
        setAreaOfResponsibility: (areaOfResponsibility: string) => {
            state[self].set(prevState => {
                prevState.user!!.areaOfResponsibility = areaOfResponsibility
                return prevState
            })
        },
        setMobile: (mobile: string) => {
            state[self].set(prevState => {
                prevState.user!!.mobile = mobile
                return prevState
            })
        },
        setPhone: (phone: string) => {
            state[self].set(prevState => {
                prevState.user!!.phone = phone
                return prevState
            })
        },
        setProfileImage: (imageUrl: string) => {
            state[self].set(prevState => {
                prevState.user!!.logoUrl = imageUrl
                return prevState
            })
        },
        setInterests: (interests: string[]) => {
            state[self].set(prevState => {
                prevState.user!!.interests = interests
                return prevState
            })
        },
        setOrganizations: (organizations: any) => {
            state[self].set(prevState => {
                prevState.user!!.organizations = organizations
                return prevState
            })
        },
        setEventDates: (eventDates: any) => {
            state[self].set(prevState => {
                prevState.user!!.eventDates = eventDates
                return prevState
            })
        },
        setEvents: (events: any) => {
            state[self].set(prevState => {
                prevState.user!!.events = events
                return prevState
            })
        },
        setInfotext: (infotext: string) => {
            state[self].set(prevState => {
                prevState.user!!.infotext = infotext
                return prevState
            })
        },
        setFacebook: (facebook: string) => {
            state[self].set(prevState => {
                prevState.user!!.facebook = facebook
                return prevState
            })
        },
        setLinkedin: (linkedIn: string) => {
            state[self].set(prevState => {
                prevState.user!!.linkedIn = linkedIn
                return prevState
            })
        },
        setGooglePlus: (googleplus: string) => {
            state[self].set(prevState => {
                prevState.user!!.googleplus = googleplus
                return prevState
            })
        },
        setYouTube: (youTube: string) => {
            state[self].set(prevState => {
                prevState.user!!.youTube = youTube
                return prevState
            })
        },
        setTwitter: (twitter: string) => {
            state[self].set(prevState => {
                prevState.user!!.twitter = twitter
                return prevState
            })
        },
        setXing: (xing: string) => {
            state[self].set(prevState => {
                prevState.user!!.xing = xing
                return prevState
            })
        },
        setPinterest: (pinterest: string) => {
            state[self].set(prevState => {
                prevState.user!!.pinterest = pinterest
                return prevState
            })
        },
        setInstagram: (instagram: string) => {
            state[self].set(prevState => {
                prevState.user!!.instagram = instagram
                return prevState
            })
        },
        setType: (type: string) => {
            state[self].set(prevState => {
                prevState.user!!.type = type
                return prevState
            })
        },
        setVisible: (visible: boolean) => {
            state[self].set(prevState => {
                prevState.user!!.visible = visible
                return prevState
            })
        },
        setShowroomStandby: (showroomStandby: boolean) => {
            state[self].set(prevState => {
                prevState.user!!.showroomStandby = showroomStandby
                return prevState
            })
        },
        setPrivacyUserAnswer: (privacyUserAnswer: PrivacyUserAnswer) => {
            state[self].set(prevState => {
                const arr = prevState.privacyUserAnswer2?.filter(answer => answer.questionId !== privacyUserAnswer.questionId) || []
                arr.push(privacyUserAnswer)
                prevState.privacyUserAnswer2 = arr
                return prevState
            })
        },
        setInvitingOrganization: (invitingOrganization: any) => {
            state[self].set(prevState => {
                prevState.user!!.invitingOrganization = invitingOrganization
                return prevState
            })
        },
        getPrivacyUserAnswer: (questionId: String) => {
            return state[self].get().privacyUserAnswer2?.find(answer => answer.questionId === questionId)
        },
        setUserState: (newState: { jwtToken?: string, user?: User, privacyUserAnswer2?: PrivacyUserAnswer[] }) => {
            state[self].set(newState)
        },
    })
})
