// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useChimeContext, MeetingStatusCode, ShallowVideoTileState } from './context/ChimeContext';
import { Button } from './components/Button';
import RemoteVideo from './components/RemoteVideo';
import { useMeetingContext } from './context/MeetingContext';
import { useLoggedInState } from '../globalStates/LoggedInUser';
import { MeetingParticipant, AttendeeData } from '../backendServices/BackendServices';
import { IconEndCall, IconAcceptCall, IconCalendarEntry } from '../ui/Icons';
import { useLanguageState } from '../globalStates/LanguageState';
import { meetingPageRoute } from '../navigationArea/RoutePaths';
import { throttle } from 'lodash';
import RosterAttendeeType from './types/RosterAttendeeType';
import ContentVideo from './components/ContentVideo';
import { Modal, Button as button } from 'react-bootstrap';
import useSound from 'use-sound';
import { HookOptions } from 'use-sound/dist/types';
import Controls from './components/Controls';
import Draggable from 'react-draggable';
import branding from "../branding/branding";

interface ConferenceOverlayProps {
  audioFileFormat: string
}

export default function ConferenceOverlay(props: ConferenceOverlayProps) {
  const loggedInUser = useLoggedInState()
  const meeting = useMeetingContext()
  const profileId = loggedInUser.user()?.profileId

  useEffect(() => {
    meeting.subscribeToCalls(profileId || '')
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return <>
    <CallRows {...props} />
    <VideoOverlay />
  </>
}
const CallRowsRoot = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1100;
`

function CallRows(props: ConferenceOverlayProps) {
  const [play, { stop }] = useSound(
    "/ringing." + props.audioFileFormat,
    {
      volume: 0.2,
      interrupt: true,
      loop: true,
    } as HookOptions // Typed, because HookOptions does not expose all underlying options https://github.com/goldfire/howler.js#options
  );
  const meeting = useMeetingContext()
  const outgoingInvites = meeting.getOutgoingInvites()
  const incomingInvites = meeting.getIncomingInvites()
  const numInvites = incomingInvites.length + outgoingInvites.length

  useEffect(() => {
    if (numInvites > 0)
      play()
    else
      stop()
  }, [numInvites]); // eslint-disable-line react-hooks/exhaustive-deps

  if (numInvites <= 0)
    return null

  return (<CallRowsRoot>
    {outgoingInvites.map((invite) => <CallRow key={invite.id} invite={invite} />)}
    {incomingInvites.map((invite) => <CallRow key={invite.id} invite={invite} incoming={true} />)}
  </CallRowsRoot>)
}

const CallEntryRoot = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: initial;
  align-items: center;
  height: 80px;
  width: 320px;
  padding: 15px 20px;
  cursor: pointer;
  color: #fff;
  background-color: #000;
  border-bottom: 2px solid #fff;
  margin-bottom: 10px;
  border-radius: 5px;

  & > :nth-child(1) {
    min-width: 40px;
  }

  & > :nth-child(3) {
    flex-grow: 1;
    padding: 0 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
`
const Text = styled.div`
  flex-grow: 1;
`
const CallType = styled.div`
  color: #808080;
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
`

const Name = styled.div`
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: #FFFFFF;    
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ButtonWrapper = styled.div<{ wide?: boolean }>`
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: ${props => props.wide ? "100px" : "45px"};
  text-align: right;

  & button {
    width: 40px;
    height: 40px;
    margin: 0;

    &:nth-child(2) {
      margin-left: 20px;
    }
  }
`
const MainModal = styled(Modal)`
.modal-header {
  display: inline-flex;
  border-bottom: none;
}

.modal-body{
  font-size: 17px;
  display: flex;
}

.modal-footer {
    justify-content: space-between;
    border: none;
}

.close{
  outline: 0;
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 46px;
  font-weight: 10;
  line-height: 1.66666667;
  color: #000;
  width: 30px;
  padding: 0 2px;
  background: none;
  border-style: none;
  margin-right: 1px;
  margin-top: -16px;
}


.btn-primary  {
  width: 40%;
  display: inline-block;
  /* color: white !important; */
  /* background-color: #000; */
  /* border: 2px solid black; */
  border-radius: 20px;
  
      /* :hover {
      background-color: #e8e8e8 !important; 
      color: black !important;
      } */
  }

  .btn-secondary  {
    display: inline-block;
    /* color: black !important;
    background-color: white; */
    border: none;
    /* border-color: white;
    border-radius: none; */
    }
`
const TitleImage = styled.div`
    font-size: 30px;
`
const Title = styled.div`
    margin-left: 20px;
    margin-top: 9px;
    font-size: 22px;
`
// const CancelButton = styled.div`
//     width: 60px;
//     height: 40px;
//     justify-content: left;
//     font-size: 18px;
//     border: none;
//     background: white;
//     color: #FFF;
//     border-radius: 25px;
//     cursor: pointer;
// `
// const ConfirmButton = styled.div`
//     width: 200px;
//     height: 40px;
//     cursor: pointer;
//     font-size: 18px;
    
// `
const CancelButton = styled.div`
    width: 30%;
    height: 30px;
    cursor: pointer;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
    
    &:hover{
        text-decoration: none;
    }
`
const ConfirmButton = styled(button)`
    width: 40%;
    height: 30px;
    color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
    background-color: ${branding.sayHelloModal.submitBtnPrimaryBgColor}!important;
    border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 20px;
    font-size: 12px;
    font-family: ${branding.font1};

    :hover {
        background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor} !important; 
    }
`
interface CallRowProps {
  invite: MeetingParticipant
  incoming?: boolean
}
function CallRow(props: CallRowProps) {
  const [showConfirmCall, setShowConfirmCall] = useState(false)
  const chime = useChimeContext()
  const meeting = useMeetingContext()
  const strings = useLanguageState().getStrings()

  const { invite, incoming } = props
  const inviter = props.incoming ? invite.inviter : invite.invitee

  return <CallEntryRoot key={invite.id}>
    <Text>
      <CallType>{incoming ? strings.conferenceTexts.incomingCall : strings.conferenceTexts.outgoingCall}</CallType>
      <Name>{inviter.name}</Name>
    </Text>
    <ButtonWrapper wide={incoming}>
      {incoming && <Button icon={IconAcceptCall({ fill: "#fff"})} tooltipPlacement="bottom" tooltip={strings.conferenceTexts.acceptCall} color="#fff" backgroundColor="#00B300" onClick={() => {
        if (chime.getMeetingStatus().meetingStatus === MeetingStatusCode.Succeeded)
          setShowConfirmCall(true)
        else
          meeting.acceptInvite(invite.id)
      }} />}
      {incoming && <Button icon={IconEndCall({fill: "#fff"})} tooltipPlacement="bottom" tooltip={strings.conferenceTexts.declineCall} color="#fff" backgroundColor="red" onClick={() => meeting.declineInvite(invite.id)} />}
      {!incoming && <Button icon={IconEndCall({fill: "#fff"})} tooltipPlacement="bottom" tooltip={strings.conferenceTexts.cancelCall} color="#fff" backgroundColor="red" onClick={() => meeting.cancelInvite(invite.id)} />}
    </ButtonWrapper>
    {props.incoming && showConfirmCall && <MainModal
      show={true}
      onHide={() => setShowConfirmCall(false)}
      backdrop="static"
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ display: "inline-flex" }}>
        <TitleImage>{IconCalendarEntry({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}</TitleImage>
          <Title>{strings.conferenceTexts.changingRoomConfirmationTitle}</Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{strings.conferenceTexts.changingRoomConfirmationText}</Modal.Body>
      <Modal.Footer>
      <CancelButton onClick={() => setShowConfirmCall(false)} className="d-flex align-items-center">{strings.globalTexts.cancel}</CancelButton>
        {/* <CancelButton>
          <BootstrapButton variant="secondary" onClick={() => setShowConfirmCall(false)}>
            {strings.globalTexts.cancel}
          </BootstrapButton>
        </CancelButton> */}
        <ConfirmButton type="submit" onClick={() => {
            chime.setIsMeetingChangeAccepted(true)
            meeting.acceptInvite(invite.id)
            setShowConfirmCall(false)
          }} className="d-flex align-items-center justify-content-center">
                       {strings.conferenceTexts.changingRoomConfirmationAccept ?? strings.globalTexts.confirm}
                </ConfirmButton>
        {/* <ConfirmButton>
          <BootstrapButton variant="primary" onClick={() => {
            chime.setIsMeetingChangeAccepted(true)
            meeting.acceptInvite(invite.id)
            setShowConfirmCall(false)
          }}>
            {strings.conferenceTexts.changingRoomConfirmationAccept ?? strings.globalTexts.confirm}
          </BootstrapButton>
        </ConfirmButton> */}
      </Modal.Footer>
    </MainModal>
    }
  </CallEntryRoot>
}

const ConferenceOverlayRoot = styled.div`
  position: absolute;
  left: 100px;
  bottom: 109px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 240px;
  cursor: pointer;
  z-index: 1000;

  .customLoader {
    border: none;
  }

  & {
    & > :last-child {
        display: block;
    }
  }

  & video + div {
    display: none; /** no name tag */
  }
`

const StyledContentVideo = styled(ContentVideo)`
  max-height: 100%;
`

const NoAttendees = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${branding.mainInfoColor ?? "black"};
  font-family: ${branding.font1};
  color: #fff;
`
const RoomNameLabel = styled.div`
  display: none;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  padding: 3px 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #000;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledControls = styled(Controls)`
  position: relative;
  bottom: 0px;
  height: 40px;
  margin-top: 2px;

  box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  & button {
    height: 40px;
    width: 40px;
    border-radius: none;

    &:nth-child(5) {
      display: none;
    }
    &:nth-child(6) {
      display: none;
    }
  }
`
interface AttendeeDataWithType extends AttendeeData {
  type: "video" | "avatar"
}

function VideoOverlay() {
  const chime = useChimeContext()
  const audioElement = useRef(null)
  const videoElement = useRef<HTMLVideoElement>(null)
  const history = useHistory()
  const remoteTiles = chime.getRemoteTiles()
  const roster = chime.getRoster()
  const activeSpeakers = chime.getActiveSpeakers()
  const meetingStatus = chime.getMeetingStatus().meetingStatus
  const isMeetingPage = useRouteMatch(meetingPageRoute)
  const [displayedAttendee, setDisplayedAttendee] = useState<null | AttendeeDataWithType>(null)

  const isOverlayVisible = meetingStatus === MeetingStatusCode.Succeeded && !isMeetingPage
  const strings = useLanguageState().getStrings()
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    if (meetingStatus !== MeetingStatusCode.Succeeded)
      return

    if (audioElement && audioElement.current)
      chime.bindAudioElement(audioElement)

  },
    // eslint-disable-next-line
    [meetingStatus])

  const rosterCheck = (roster: { [attendeeId: string]: RosterAttendeeType }, remoteTiles: ShallowVideoTileState[], activeSpeakers: string[], currentAttendee: null | { id: string, type: "video" | "avatar", name?: string }) => {
    const rosterKeys = Object.keys(roster)
    // if there is only one entry in the roster, the local user is it, therefore we are waiting for others
    if (rosterKeys.length <= 1) {
      setDisplayedAttendee(null)
      return
    }
    let rosterKeyToUse: string = ""
    // If we do have a attendee currently and he's still speaking, do nothing
    if (currentAttendee && activeSpeakers.indexOf(currentAttendee.id!) >= 0) {
      return
    }
    // Find the first speaker that is not the local user, and differs from the currentAttendee
    for (let activeSpeaker of activeSpeakers) {
      if (activeSpeaker !== chime.getLocalAttendeeId() && (currentAttendee === null || currentAttendee.id !== activeSpeaker)) {
        rosterKeyToUse = activeSpeaker
        break
      }
    }
    // Nobody speaking? And no currentAttendee? Select the first roster entry
    if (!rosterKeyToUse && (!currentAttendee || currentAttendee.id === "tmp")) {
      for (let rosterKey of rosterKeys) {
        if (rosterKey !== chime.getLocalAttendeeId()) {
          rosterKeyToUse = rosterKey
          break
        }
      }
    }

    // Try to find a corresponding remoteTile
    let remoteTileToUse: ShallowVideoTileState | null = null
    for (let remoteTile of remoteTiles) {
      if (remoteTile && remoteTile.boundAttendeeId === rosterKeyToUse)
        remoteTileToUse = remoteTile
    }
    // If remoteTile found, display it
    if (remoteTileToUse) {
      if (!currentAttendee || currentAttendee.id !== rosterKeyToUse) {
        chime.bindVideoElement(
          remoteTileToUse.tileId!,
          (videoElement.current as HTMLVideoElement)
        )
        setDisplayedAttendee({ id: rosterKeyToUse!, type: "video" })
      }
    }
    // Otherwise show an avatar for the name of the person
    else {
      // Show Avatar
      if (roster[rosterKeyToUse] && (!currentAttendee || currentAttendee.id !== rosterKeyToUse)) {
        setDisplayedAttendee({ ...roster[rosterKeyToUse], type: "avatar" })
      }
    }
  }

  const [throttledRosterCheck] = useThrottledCallback(rosterCheck, 2000);
  // Determine "loudest" speaker on roster changes
  useEffect(() => {
    if (!isOverlayVisible || chime.isScreenShareEnabled())
      return

    if (displayedAttendee)
      throttledRosterCheck(roster, remoteTiles, activeSpeakers, displayedAttendee)
    else
      rosterCheck(roster, remoteTiles, activeSpeakers, displayedAttendee)
  },
    // eslint-disable-next-line
    [history.location, roster, remoteTiles, activeSpeakers]);

  let mode: "empty" | "avatar" | "video" | "screenShare" = "empty"
  if (chime.isScreenShareEnabled())
    mode = "screenShare"
  else if (displayedAttendee?.type === "avatar")
    mode = "avatar"
  else if (displayedAttendee?.type === "video")
    mode = "video"
  else
    mode = "empty"

  let roomName = ""
  if (chime.getKind() === "virtualCafe") {
    for (let group of strings.meetingRoomGroups) {
      for (let meetingRoom of group.meetingRooms) {
        if (meetingRoom.id === chime.getExternalMeetingId()) {
          roomName = meetingRoom.title
          break
        }
      }
      if (roomName)
        break
    }
  }


  return (
    <Draggable bounds="html" onDrag={() => { setIsDragging(true) }} >
      <ConferenceOverlayRoot
        style={{ "display": (isOverlayVisible ? "block" : "none") }}
        onClick={(event: React.MouseEvent) => {
          if (!isDragging) {
            chime.gotoCurrentMeeting()
            event.stopPropagation()
          }

          setIsDragging(false)
        }}
      >
        <div style={{ 
          height: '140px',
          boxShadow: '0px 5px 15px 1px rgba(0, 0, 0, 0.4)', 
           }}
          >
          <audio ref={audioElement} style={{ display: 'none' }} muted={chime.getVolume() === 0} />
          {mode === "screenShare" && <StyledContentVideo guestBannerHeight={0} />}
          {
            // display change instead of dom appending because video must be present when we try to bind it in the useEffect
          }
          <div style={{ display: mode === "video" ? "block" : "none" }}>
            <RemoteVideo
              videoElementRef={videoElement}
              attendeeId={displayedAttendee ? displayedAttendee.id! : null}
            />
          </div>
          {
            mode === "avatar" &&
            <RemoteVideo
              attendeeId={displayedAttendee!.id!}
              attendeeName={displayedAttendee!.name}
              attendeeImg={displayedAttendee!.avatarUrl}
              attendeePosition={displayedAttendee!.position}
              attendeeCompany={displayedAttendee!.company}
              avatarSize={60}
            />
          }
          {mode === "empty" && <NoAttendees>{strings.conferenceTexts.noAttendees}</NoAttendees>}
          {roomName && <RoomNameLabel>{roomName}</RoomNameLabel>}
        </div>
        <StyledControls isDragging={isDragging} hideLocalVideo={true} hideHostedBy={true} smallButtons={true} redirectToRoom={false} />
      </ConferenceOverlayRoot >
    </Draggable >
  )
}

function useThrottledCallback(callback: any, delay: number) {

  const d = callback;

  const callbackfunc = useCallback(throttle(d, delay), []);

  return [callbackfunc]
}