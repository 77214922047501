// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useChimeContext, MeetingStatusCode } from '../context/ChimeContext';


const LocalVideoRoot = styled.video`
  height: 80px;
`

export default function LocalVideo() {
  const [enabled, setEnabled] = useState(false);
  const chime = useChimeContext()
  const videoElement = useRef(null);
  const localTile = chime.getLocalTile()

  useEffect(() => {
    if (chime.getMeetingStatus().meetingStatus !== MeetingStatusCode.Succeeded || localTile === null || !localTile.tileId || !videoElement)
      return
    console.log("localVideo")
    chime.bindVideoElement(
      localTile!.tileId!,
      (videoElement.current as unknown) as HTMLVideoElement
    );
    setEnabled(localTile?.active!);
  }, 
  // eslint-disable-next-line
  [localTile?.active]);

  return (
    <LocalVideoRoot muted ref={videoElement} style={{ display: enabled ? "inline-block" : "none" }} />
  );
}
