import React, { useState, useCallback } from "react"
import { debounce } from "lodash"
import { meetingPageRoute } from "../navigationArea/RoutePaths"
import { useRouteMatch } from "react-router-dom"
import { IconSearch, IconSearchSmall, IconClearSearchItem, IconClearSearchItemLarge } from "./Icons"
import branding from "../branding/branding"
import { TextField } from "./TextField"

interface SearchBarProps {
    setSearchParam: (setSearchParam: string) => void;
    searchValue?: string
    placeholder: string;
    width: string;
    bgColor?: string
    isSideBarSearchBar?: boolean
    largeIcon?: boolean
}

const SearchBar: React.FunctionComponent<SearchBarProps> = (props: SearchBarProps) => {
    const [value, setValue] = useState<string>(props.searchValue ?? "")
    let isMeetingPage = useRouteMatch(meetingPageRoute)

    const handleSearchDebounced = useCallback(
        debounce(searchValue => props.setSearchParam(searchValue), 300),
        [], // will be created only once initially
    );

    function handleSearch(searchValue: string) {
        setValue(searchValue);
        handleSearchDebounced(searchValue)
    }
    function handleClick() {
        setValue("")
        handleSearchDebounced("")
    }

    return <TextField backgroundColor={(props.isSideBarSearchBar && isMeetingPage) ? branding.mainInfoColor ?? "black" : "transparent"} textColor={(props.isSideBarSearchBar && isMeetingPage) ? "white" : branding.mainInfoColor ?? "black"} width={props.width} placeholder={props.placeholder} fontSize="14px" height="25px" fontFamily={branding.font1} value={value} setValue={setValue} search={true} onChange={handleSearch}
        endAdornment={
            <>
                <span style={{ 'color': props.isSideBarSearchBar ? (isMeetingPage ? 'white' : branding.mainInfoColor ?? "black") : branding.mainInfoColor ?? "black", paddingRight: "7px", cursor: "pointer", visibility: value !== "" ? "visible" : "hidden" }} onClick={() => handleClick()} >
                    {props.largeIcon ? IconClearSearchItemLarge({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor }) : IconClearSearchItem({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                </span>
                <span id="searchIcon" style={{ 'color': props.isSideBarSearchBar ? (isMeetingPage ? 'white' : branding.primaryColor ?? "black") : branding.mainInfoColor ?? "black" }}>
                    {props.largeIcon ? IconSearch({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor }) : IconSearchSmall({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                </span>
            </>
        } />

}

export default SearchBar;
