import React from 'react'
import styled from "styled-components"
import { Category } from '../../../backendServices/Types'
import { Row } from "react-bootstrap"
import { HeaderTitle } from './StyledComponents'
import { useLanguageState } from '../../../globalStates/LanguageState'



interface CategoriesProps {
    categories: Category[]
}

const CategoryItem = styled.div<{ borderColor: string }>`
    display: inline-block;
    padding: 5px 10px;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.borderColor};
    margin: 5px;
`

const Categories = (props: CategoriesProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()

    return (
        <Row className="mt-5 pb-5" style={{ marginLeft: "5px", marginRight: "5px" }}>
            <div>
                <HeaderTitle className="mb-3"> {strings.organizationDetailPageContent.categoriesSectionTitle} ({props.categories.length})</HeaderTitle>
                {props.categories.map(category => {
                    return <CategoryItem key={category.id} borderColor={category.colorBorder}>{lang === "de" ? category.nameDe : category.name}</CategoryItem>
                })}
            </div>
        </Row>
    )
}


export default Categories;