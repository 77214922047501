import styled from "styled-components"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import React, { useEffect } from "react"
import AvatarWithPresenceState from "./AvatarWithPresenceState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useUsersPresence } from "../globalStates/ConnectedUsers"
import { getBatchPresenceByUserId, UserBatchPresenceResponse } from "../backendServices/BackendServices"
import { Divider } from "../communicationArea/ContactEntry"
import { defaultLogger as logger } from "../globalStates/AppState"

const ParticipantCellWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
const ParticipantCell = styled(DetailNavLink)`
    display: flex;
    align-items: center;
    padding: 8px 0 8px 16px;
    cursor: pointer;

    &:hover { 
        background-color: hsl(0,0%,94%); 
    }
`
const ParticipantTitle = styled.div`
    font-weight: 300;
`
const ParticipantSubtitle = styled.div`
    font-size: 12px;
`

export interface Participants {
    id: string
    name: string
    pictureUrl?: string
    subtitle?: string
}

interface ParticipantListProps {
    participants: Participants[]
}

const ParticipantList: React.FC<ParticipantListProps> = (props) => {

    const userState = useLoggedInState()
    const profileId = userState.user()?.profileId
    const usersPresence = useUsersPresence()

    // get presence in batch mode
    useEffect(() => {
        if (props.participants && props.participants.length > 0) {
            const listIds = props.participants.map(person => person.id).filter((elem, index, self) => index === self.indexOf(elem))
            if (listIds.length > 0) {
                getBatchPresenceByUserId(listIds).then((result) => {
                    if (result as UserBatchPresenceResponse[]) {
                            try {
                            (result as UserBatchPresenceResponse[]).forEach((user) => {
                                if (user)
                                    usersPresence.create(user.id, user.id === profileId, user.presenceStatus, user.lastConnected)
                            })
                        } catch (e) {
                            logger.error({ message: "batchGetUserPresenceLight", request: "graphql", params: listIds, errorMessage: "Wrong entry in parameter list", errorStack: e });
                        }
                    }
                })
            }
        }
        //eslint-disable-next-line
    }, [props.participants])

    return <>
        {props.participants.map((participant) => (
            <ParticipantCellWrapper key={participant.id}>
                <ParticipantCell type="user" id={participant.id} name={participant.name}>
                    <AvatarWithPresenceState userId={participant.id} avatarSize={56} badgeSize={15} showAvatarBadge={true} badgeRight={0.1} badgeTop={37} content={{ pictureUrl: participant.pictureUrl, alt: participant.name }} />
                    <div style={{ marginLeft: "16px" }}>
                        <ParticipantTitle>{participant.name}</ParticipantTitle>
                        <ParticipantSubtitle>{participant.subtitle}</ParticipantSubtitle>
                    </div>
                </ParticipantCell>
                <Divider style={{ paddingLeft: "16px", margin: "0px" }} />
            </ParticipantCellWrapper>
        ))}
    </>
}


export default ParticipantList
