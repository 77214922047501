import branding from "./branding"
import { defaultLogger as logger } from "../globalStates/AppState"

export enum BasisPremiumType {
    NONE = "NONE",
    BASIC = "BASIC",
    STANDARD = "STANDARD",
    PREMIUM = "PREMIUM",
    SPONSOR = "SPONSOR"
}

export enum TileSize {
    SMALL = "SMALL",
    QUARTER = "QUARTER",
    HALF = "HALF",
    FULL = "FULL"
}

export type BasisPremiumBranding = {
    [key in BasisPremiumType]?: BasisPremiumConfig
}

export interface BasisPremiumConfig {
    tileSize: TileSize
    tileBackgroundImageVisible: boolean
}

export function getDefaultBasisPremiumConfig(): BasisPremiumConfig {
    return {
        tileSize: TileSize.SMALL,
        tileBackgroundImageVisible: false
    }
}

const loggedMissungConfig: BasisPremiumType[] = []
export function getBasisPremiumConfig(basisPremium: BasisPremiumType): BasisPremiumConfig {
    if (branding.basisPremium[basisPremium]) {
        return branding.basisPremium[basisPremium]!!
    }
    if (loggedMissungConfig.indexOf(basisPremium) < 0) {
        logger.error({ message: `No config found for basisPremium value >${basisPremium}<` })
        loggedMissungConfig.push(basisPremium)
    }
    return getDefaultBasisPremiumConfig()
}