import { createState, self, State, useState } from '@hookstate/core';
import { inviteToMeeting, MeetingParticipant, updateMeetingInvite, getMeetingHistoryEntries, MeetingKind, ensureMeeting, ensureChatConversation, PresenceType } from '../../backendServices/BackendServices';
import { API, graphqlOperation } from 'aws-amplify';
import { onMeetingInviteReceived } from '../../graphql/subscriptions';
import { InviteStatus } from '../../API';
import { useChimeContext, getExternalMeetingId } from './ChimeContext';
import { useLoggedInState } from '../../globalStates/LoggedInUser';
import { useAppState, defaultLogger as logger } from "../../globalStates/AppState"
import { v4 as uuidv4 } from 'uuid';
import { onMeetingInviteChangedLight } from '../../graphql/ownSubscriptions';
import branding from '../../branding/branding';

const CALL_TIMEOUT = 60000

interface StateValues {
    outgoingInvites: MeetingParticipant[]
    incomingInvites: MeetingParticipant[]
    participationHistory: MeetingParticipant[]
}

const getStartValues = (): StateValues => {
    return {
        outgoingInvites: [],
        incomingInvites: [],
        participationHistory: [],
    }
}
let activeCalls: string[] = []
const state = createState<StateValues>(getStartValues())
let handleOutgoingInviteTimer: number
let incomingCallSubscription: any
const useStateWrapper = (meeting: State<StateValues>) => {
    const chime = useChimeContext()
    const loggedInUser = useLoggedInState()
    const appState = useAppState()

    const addToHistoryFromInvite = (invite: MeetingParticipant) => {
        state.participationHistory[self].set(prevState => {
            prevState.push(invite)
            return prevState
        })
    }

    return ({
        subscribeToCalls: async (id: string) => {
            // Subscribe to incoming calls
            // TODO use correct type
            if (incomingCallSubscription)
                incomingCallSubscription.unsubscribe()
            incomingCallSubscription = (API.graphql(graphqlOperation(onMeetingInviteReceived, { inviteeId: id })) as any).subscribe({
                next: (resp: any) => {
                    const meetingInvite = resp.value.data.onMeetingInviteReceived
                    if (meetingInvite.inviter === id || appState.presenceState() === PresenceType.DONOTDISTURB)
                        return
                    activeCalls.push(meetingInvite.inviter)
                    meeting.incomingInvites[self].set(prevState => {
                        prevState.push(meetingInvite)
                        return prevState
                    })
                    // Subscribe to incoming call changes, to react to cancelation
                    // TODO use correct type
                    const onInviteChangedListener = (API.graphql(graphqlOperation(onMeetingInviteChangedLight, { id: meetingInvite.id })) as any).subscribe({
                        next: (resp: any) => {
                            const meetingInvite: MeetingParticipant = resp.value.data.onMeetingInviteChanged
                            switch (meetingInvite.status) {
                                case InviteStatus.ACCEPTED:
                                    chime.createOrJoinMeeting(meetingInvite.meeting.id)
                                    break
                                case InviteStatus.CANCELED || InviteStatus.TIMEOUT:
                                    setTimeout(() => {
                                        appState.setMissedCallNotification(true, meetingInvite.inviter.id, meetingInvite.inviter.name)
                                    }, 2000)

                                    break
                                default:
                                    break
                            }
                            // Remove call from current call list
                            meeting[self].set(prevState => {
                                let idToRemove = 0
                                for (; idToRemove < prevState.incomingInvites.length; idToRemove++) {
                                    if (prevState.incomingInvites[idToRemove].id === meetingInvite.id) {
                                        break
                                    }
                                }
                                prevState.incomingInvites.splice(idToRemove, 1)
                                return prevState
                            })
                            activeCalls = activeCalls.filter((value) => value !== meetingInvite.id);
                            onInviteChangedListener.unsubscribe()
                            addToHistoryFromInvite(meetingInvite)
                        }
                    })
                }
            })
            const invites = await getMeetingHistoryEntries(id)
            if (invites) {
                state.participationHistory[self].set(invites)
            }
        },
        sendInvite: async (hailedId: string, meetingData?: { meetingId: string, meetingKind: MeetingKind }) => {
            if (!hailedId)
                return
            let alreadyHailing = false
            for (const otherId of activeCalls) {
                if (otherId === hailedId) {
                    alreadyHailing = true
                    break
                }
            }
            if (alreadyHailing)
                return
            activeCalls.push(hailedId)

            const ensureMeetingPromise = async (externalMeetingId: string) => {
                const result = await ensureMeeting(externalMeetingId)
                if (!result) {
                    throw new Error("failure while starting call")
                }
            }

            const ensureConversationPromise = async (externalMeetingId: string) => {
                const participantLimit = meetingData?.meetingKind === "calenderEntry" ? branding.configuration.calendarEntryParticipantLimit : 10
                const chatConversationAndHailedParticipationExists = await ensureChatConversation(externalMeetingId, loggedInUser.user()!.profileId, hailedId, participantLimit)
                if (!chatConversationAndHailedParticipationExists) {
                    throw new Error("failure while creating chat conversation (call might be full)")
                }
            }

            const externalMeetingId: string = meetingData ? getExternalMeetingId(meetingData.meetingId, meetingData.meetingKind) : getExternalMeetingId(uuidv4(), "call")
            const ensures = (!meetingData || meetingData.meetingKind === "call")
                ? [ensureMeetingPromise(externalMeetingId), ensureConversationPromise(externalMeetingId)]
                : [ensureMeetingPromise(externalMeetingId)]
            await Promise.all(ensures)

            const outgoingInvite = await inviteToMeeting(loggedInUser.user()!.profileId, hailedId, externalMeetingId)
            if (outgoingInvite) {
                meeting[self].set(prevState => {
                    prevState.outgoingInvites.push(outgoingInvite)
                    return prevState
                })

                // Listen for updates on our invite
                // TODO use correct type
                const onInviteChangedListener = (API.graphql(graphqlOperation(onMeetingInviteChangedLight, { id: outgoingInvite.id })) as any).subscribe({
                    next: (resp: any) => {
                        const call: MeetingParticipant = resp.value.data.onMeetingInviteChanged
                        switch (call.status) {
                            // We accepted the invite
                            case InviteStatus.ACCEPTED:
                                chime.createOrJoinMeeting(outgoingInvite!.meeting.id)
                                break
                            // Timeout or other party canceled the invite
                            default:
                                break
                        }
                        meeting[self].set(prevState => {
                            let idToRemove = 0
                            for (; idToRemove < prevState.outgoingInvites.length; idToRemove++) {
                                if (prevState.outgoingInvites[idToRemove].id === outgoingInvite.id) {
                                    break
                                }
                            }
                            prevState.outgoingInvites.splice(idToRemove, 1)
                            return prevState
                        })
                        activeCalls = activeCalls.filter((value) => value !== outgoingInvite.invitee.id);
                        // Either we get an affirmative reaction or a negative, in either case we won't need to listen any longer to this invite 
                        // => Unsubscribe and clear timeoutimer if set
                        if (handleOutgoingInviteTimer)
                            clearTimeout(handleOutgoingInviteTimer)
                        onInviteChangedListener.unsubscribe()
                        addToHistoryFromInvite(outgoingInvite)
                    }
                })
                if (handleOutgoingInviteTimer)
                    clearTimeout(handleOutgoingInviteTimer)
                handleOutgoingInviteTimer = window.setTimeout(() => {
                    updateMeetingInvite(outgoingInvite.id, InviteStatus.TIMEOUT)
                }, CALL_TIMEOUT)
            } else {
                logger.error("failure while starting call")
            }
        },
        acceptInvite: async (id: string) => {
            updateMeetingInvite(id, InviteStatus.ACCEPTED)
        },
        declineInvite: (id: string) => {
            updateMeetingInvite(id, InviteStatus.DECLINED)
        },
        cancelInvite: (id: string) => {
            updateMeetingInvite(id, InviteStatus.CANCELED)
        },
        getOutgoingInvites: () => {
            return meeting[self].value.outgoingInvites
        },
        getIncomingInvites: () => {
            return meeting[self].value.incomingInvites
        },
        getParticipationHistory: () => {
            return meeting[self].value.participationHistory
        },
        getCurrentMeetingParam: () => {
            return chime.getName() ? { meetingId: chime.getName(), meetingKind: chime.getKind() } : undefined
        },
    })
}

export const useMeetingContext = () => useState(state)[self].map(useStateWrapper)
