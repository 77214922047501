import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { IconSchedule } from '../Icons'
import branding, { LocalizedString } from '../../branding/branding'
import { getDefaultHours, getDefaultMinutes, formatSelectedTime, filterHiddenTime, getDisabledTime } from './CrsTimePickerHelper'
import CrsTimePickerColumn from './CrsTimePickerColumn'
import { useLanguageState } from '../../globalStates/LanguageState'

const CrsTimePickerRoot = styled.div<{ width?: string }>`
    position: relative;
    z-index: 5;
    width: ${props => props.width || '100%'};
    overflow: visible;
`

const CrsTimePickerLabel = styled.p`
`
const CrsTimePickerSelector = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${branding.mainInfoColor};
    padding: .5rem 0;
    cursor: pointer;
`

const CrsTimePickerSelection = styled.div`
    color: #8e8e93;
`

const CrsTimePickerIcon = styled.div`
    
`

const CrsTimePickerMenu = styled.div<{ height?: string, width?: number, top?: number, left?: number }>`
    width: ${props => props.width}px;
    height: ${props => props.height || '300px'};
    position: fixed; 
    z-index: 1050;
    background-color: #fff;
    border: 1px solid ${branding.mainInfoColor};
    border-top: none;
    padding: .5rem;
`
const CrsTimePickerMenuContent = styled.div<{ actionContainerHeight?: number }>`
    display: flex; 
    justify-content: center;
    align-items: center;
    padding-bottom: .3rem;
    height: ${props => props.actionContainerHeight ? `calc(100% - ${props.actionContainerHeight}px)` : '100%'};
`

const CrsTimePickerAction = styled.div`
    width: 100%;
    padding: .3rem .5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
`
const CrsTimePickerActionButton = styled.button`
    background-color: ${branding.mainInfoColor};
    color: #fff;
    padding: .3rem .5rem;
    
`
interface CrsTimePickerProps {
    rootClass?: string
    width?: string
    label?: string
    placeholder?: string
    defaultTime?: string, /* Expected format -> HH:mm */
    minutesInterval?: number /* Default value = DEFAULT_MINUTES_INTERVAL */
    hideHours?: (hour: number) => boolean
    hideMinutes?: (hour: number) => boolean
    disableHours?: (hour: number) => boolean
    disableMinutes?: (hour: number) => boolean
    onMenuOpen?: () => void
    /* 
    This is a helper selector for the situation where we want to close a picker dialog on the outside click. 
    There are situations when these clicks are a bit tricky to catch (for example in modal components), 
    because click event are not being propagated and therefore they are imposible to detect. 
    In this case just pass the root component selector and then the click listener will be set to it and not to document. 
     */
    rootPageSelector?: string
    onSelect?: (value: string) => void /* Output format -> HH:mm */
}

export interface AvailableTime {
    value: string
    disabled: boolean
}

export interface CrsTimePickerBranding {
    hoursLabel: LocalizedString
    minutesLabel: LocalizedString
}

const CrsTimePicker: React.FunctionComponent<CrsTimePickerProps> = (props) => {
    const timePickerRef = useRef<HTMLDivElement>(null)
    const timePickerMenuRef = useRef<HTMLDivElement>(null)
    const timePickerActionRef = useRef<HTMLDivElement>(null)
    const [menuActive, setMenuActive] = useState<boolean>(false)

    const [timePickerActionRootHeight, setTimePickerActionRootHeight] = useState<number>(0)


    const [availableHours, setAvailableHours] = useState<Array<AvailableTime>>()
    const [availableMinutes, setAvailableMinutes] = useState<Array<AvailableTime>>()

    const [selectedHours, setSelectedHours] = useState<string | null>(null)
    const [selectedMinutes, setSelectedMinutes] = useState<string | null>(null)
    const [selectedTime, setSelectedTime] = useState('')

    const languageState = useLanguageState()
    const strings = languageState.getStrings()


    /* Setting available hours based on the props hideHours and disableHours */
    const setAvailableHoursLogic = () => {
        let defaultHours: Array<number> = getDefaultHours()
        let disabledHours: Array<number> = []

        if (props.hideHours)
            defaultHours = filterHiddenTime(defaultHours, props.hideHours)

        if (props.disableHours)
            disabledHours = getDisabledTime(defaultHours, props.disableHours)

        setAvailableHours(defaultHours!.map(item => ({ value: item.toString(), disabled: disabledHours.includes(item) })))
    }

    /* Setting available minutes based on the props hideMinutes and disableMinutes */
    const setAvailableMinutesLogic = () => {
        let defaultMinutes: Array<number> = getDefaultMinutes()
        let disabledMinutes: Array<number> = []

        if (props.hideMinutes)
            defaultMinutes = filterHiddenTime(defaultMinutes, props.hideMinutes)

        if (props.disableMinutes)
            disabledMinutes = getDisabledTime(defaultMinutes, props.disableMinutes)

        setAvailableMinutes(defaultMinutes!.map(item => ({ value: item.toString(), disabled: disabledMinutes.includes(item) })))
    }

    /* Closing menu on the outside click */
    const onOutsideMenuClickListener = (e: { target: any }) => {
        if (!timePickerMenuRef || !timePickerMenuRef.current)
            return;
        if (!timePickerMenuRef.current!.contains(e.target)) {
            setMenuActive(false)
        }
    }

    /* If provided setting the root element which will listen to "onOutsideMenuClickListener" */
    useEffect(() => {
        let clickDetectContainer: Document | Element;
        props.rootPageSelector ? clickDetectContainer = document.querySelector(props.rootPageSelector) || document : clickDetectContainer = document

        clickDetectContainer!.addEventListener('click', onOutsideMenuClickListener);
        return () => clickDetectContainer!.removeEventListener('click', onOutsideMenuClickListener);
    }, [props.rootPageSelector])


    useEffect(() => {
        setAvailableHoursLogic()
        // eslint-disable-next-line
    }, [props.disableHours, props.hideHours])


    useEffect(() => {
        setAvailableMinutesLogic()
        // eslint-disable-next-line
    }, [props.disableMinutes, props.hideMinutes])


    /* Making sure that format is always HH:mm. If user select hours, minutes will be autoselected and vice versa */
    useEffect(() => {
        if (selectedHours && !selectedMinutes && availableMinutes)
            setSelectedMinutes(availableMinutes.filter(x => !x.disabled)[0].value || null)
        else if (selectedMinutes && !selectedHours && availableHours)
            setSelectedHours(availableHours.filter(x => !x.disabled)[0].value || null)
        else
            setSelectedTime(formatSelectedTime(selectedHours, selectedMinutes))
        // eslint-disable-next-line
    }, [selectedHours, selectedMinutes])


    /* Setting default time if provided. Expected format -> HH:mm */
    useEffect(() => {
        if (props.defaultTime) {
            setSelectedHours(parseInt(props.defaultTime.split(':')[0]).toString())
            setSelectedMinutes(parseInt(props.defaultTime.split(':')[1]).toString())
        }
    }, [props.defaultTime])


    useEffect(() => {
        if (menuActive && props.onMenuOpen)
            props.onMenuOpen()

        if (timePickerActionRef && timePickerActionRef.current && timePickerActionRef.current.clientHeight)
            setTimePickerActionRootHeight(timePickerActionRef.current.clientHeight)

        // eslint-disable-next-line
    }, [menuActive])


    useEffect(() => {
        if (selectedTime && props.onSelect)
            props.onSelect(selectedTime)
        // eslint-disable-next-line
    }, [selectedTime])

    return (
        <CrsTimePickerRoot ref={timePickerRef} width={props.width} className={props.rootClass}>
            <CrsTimePickerLabel>{props.label}</CrsTimePickerLabel>
            <CrsTimePickerSelector onClick={() => setMenuActive(!menuActive)}>
                <CrsTimePickerSelection>{selectedTime || props.placeholder}</CrsTimePickerSelection>
                <CrsTimePickerIcon>{IconSchedule({fill: branding.sideIconBar.sideIconColorDark, width: '15', height: '15' })}</CrsTimePickerIcon>
            </CrsTimePickerSelector>
            {menuActive && <CrsTimePickerMenu
                ref={timePickerMenuRef}
                width={timePickerRef.current?.clientWidth}>
                <CrsTimePickerMenuContent actionContainerHeight={timePickerActionRootHeight}>
                    {CrsTimePickerColumn({ title: strings.timePickerBranding.hoursLabel, values: availableHours, onValueSelected: setSelectedHours, selectedValue: selectedHours })}
                    {CrsTimePickerColumn({ title: strings.timePickerBranding.minutesLabel, values: availableMinutes, onValueSelected: setSelectedMinutes, selectedValue: selectedMinutes })}
                </CrsTimePickerMenuContent>
                <CrsTimePickerAction ref={timePickerActionRef}>
                    <CrsTimePickerActionButton onClick={() => setMenuActive(false)}>OK</CrsTimePickerActionButton>
                </CrsTimePickerAction>
            </CrsTimePickerMenu>}
        </CrsTimePickerRoot>
    )
}


export default CrsTimePicker