export const DEFAULT_MINUTES_INTERVAL = 5
export const DEFAULT_SECONDS_INTERVAL = 1


export const getDefaultHours = () => {
    let defaultHours = [];
    let index = 0
    for (index; index < 24; index++) {
        defaultHours.push(index)
    }
    return defaultHours;

}

/* Getting default minutes based on the minutes interval */
export const getDefaultMinutes = (minutesInterval?: number) => {
    let defaultMinutes = [];
    let index = 0
    for (index; index < 60; index++) {
        if (index % (minutesInterval || DEFAULT_MINUTES_INTERVAL) === 0)
            defaultMinutes.push(index)
    }
    return defaultMinutes;
}

/* Formating selected time. Format -> HH:mm */
export const formatSelectedTime = (selectedHours: string | null, selectedMinutes: string | null) => {
    let time = '';
    if (selectedHours)
        time += setTimePrefix(selectedHours)
    if (selectedMinutes)
        time += ':' + setTimePrefix(selectedMinutes)

    return time;
}

/* Setting 0 in front of all numbers that are lower than 10 */
export const setTimePrefix = (value: string) => {
    return parseInt(value) < 10 ? '0' + value : value
}

/* Filtering available time values based on the provided filter function */
export const filterHiddenTime = (availableTime: Array<number>, filterFunction: (hour: number) => boolean) => {
    return availableTime.filter(time => !filterFunction(time));
}

/* Returning all disabled time values based on the provided filter function */
export const getDisabledTime = (availableTime: Array<number>, filterFunction: (hour: number) => boolean) => {
    return availableTime.filter(time => filterFunction(time));
}
