import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useLanguageState } from "../globalStates/LanguageState"
import AvatarWithPresenceState from "../ui/AvatarWithPresenceState"
import { useAppState } from "../globalStates/AppState"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { doConnectAction, MakeConnectionResponse, BackendServiceError, setMuteStatus, syncFavorites, listAttendeeInfos, AttendeeData, loadUserData, SpeakerResponse } from "../backendServices/BackendServices"
import CalendarEntryModal, { CalendarEntryModalViewMode } from "../ui/CalendarEntryModal"
import RecommendOrganizationModal from "../ui/RecommendOrganizationModal"
import { IconCamera, IconMuteChat, IconCalendarEntry, IconChevronLeft, IconThreeDotsMenu, IconRecommendBlack, IconBlockContact, IconDecline, IconBookmarkButton, IconBookmarkButtonFilled, IconSettings, IconUnMuteChat, IconDownload, IconReport } from "../ui/Icons"
import { ConversationParticipant } from "./ChatPage"
import { ConversationType } from "../API"
import { useChimeContext, getExternalMeetingId } from "../conference/context/ChimeContext"
import { ShareTargetType } from "../backendServices/Types"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useFavoriteState } from "../globalStates/Favorites"
import moment from "moment"
import { useContactState } from "./ContactState"
import { buildDetailLink, DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { isExplorationOrPostEventPhase } from "../EventPhaseChecker"
import branding from "../branding/branding"
import { saveVCard } from "./VCard"
import ReportModal from "../ui/ReportModal"
import ContextMenu, { MenuItemProps } from "../ui/ContextMenu"

const ChatHeaderContainer = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 1px;
    border-bottom: 1px solid;
    border-color: ${branding.mainInfoColor ?? "black"};
    transition: height 0.2s ease-out;
    color: ${branding.mainInfoColor ?? "black"};
    
    &.privateChat {
        height: 280px;
    }
    &.groupChat {
        height: 240px;
    }
    &.groupChatWithDescription {
        height: 300px;
    }
    &.collapsed {
        height: 62px;
        transition: height 0.2s ease-out;
    }
`
const ChatHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;

    &.collapsed {
        align-items: center;
    }
`
const ChatHeaderBottom = styled.div`
    width: 100%;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    color: ${branding.primaryColor};
`

const ChatHeaderIconButtonsContainer = styled.div`
    display: table;
    table-layout: fixed;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.collapsed {
        display:none;
    }
    & :nth-child(1) {
        animation-delay: 100ms;
    }
    & :nth-child(2) {
        animation-delay: 200ms;
    }
`

const ConversationTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    flex-shrink: 1;
    overflow: hidden;
    cursor: pointer;
`
// TODO 2 line ellipsis
const ConversationTitle = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    text-align: center;
    font-size: 14px;
    color: ${branding.mainInfoColor ?? "black"};
    width: 100%;
`
const ConversationSubtitle = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    text-align: center;
    font-size: 12px;
    color: ${branding.mainInfoColor ?? "black"};
    width: 100%;
`

const ConversationDescription = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 4; 
   -webkit-box-orient: vertical;
    text-align: center;
    font-size: 12px;
    color: ${branding.mainInfoColor ?? "black"};
    width: 100%;
    padding: 10px 10px 0 10px;

    &.collapsed {
        display: none;
    }
`

const HoverButton = styled.div`
  background-color: hsl(0,0%,100%);
  padding: 10px 10px;
  transition: background-color 0.7s;
  border-radius: 25px;
  fill: white;
  cursor: pointer;
  &:hover { background-color: hsl(0,0%,80%); }

  &.invisible {
      visibility: hidden;
  }
  
  & svg {
    color: ${branding.primaryColor};
  }

  &.selected svg {
    fill: ${branding.primaryColor};
  }
`

interface ConversationCreationHeaderProps {
    title: string
    close: () => void
}

export const ConversationCreationHeader: React.FC<ConversationCreationHeaderProps> = (props) => {

    const onNavigateUp = () => {
        props.close()
    }

    const collapsedClassName = "collapsed"
    return (
        <ChatHeaderContainer className={collapsedClassName}>
            <ChatHeaderTop className={collapsedClassName}>
                <HoverButton onClick={onNavigateUp} >{IconChevronLeft({fill: branding.sideIconBar.sideIconColorDark})}</HoverButton>
                <ConversationTitleContainer style={{ cursor: "default" }}>
                    <ConversationTitle>{props.title}</ConversationTitle>
                </ConversationTitleContainer>
                <HoverButton className={"invisible"}>{IconThreeDotsMenu({fill: branding.sideIconBar.sideIconColorDark})}</HoverButton>
            </ChatHeaderTop>
        </ChatHeaderContainer>
    )
}

interface PrivateChatHeaderProps {
    opponent: ConversationParticipant
    isMuted: boolean
    setIsMuted: (isMuted: boolean) => void
    userConversationId: string
}

export const PrivateChatHeader: React.FC<PrivateChatHeaderProps> = (props) => {

    const appState = useAppState()
    const languageState = useLanguageState()
    const userState = useLoggedInState()
    const loggedInUserId = userState.user()?.profileId;
    const favorites = useFavoriteState()
    const contactState = useContactState()
    const chime = useChimeContext()

    const strings = languageState.getStrings()
    const meeting = useMeetingContext()
    const [showCreateCalendarEntry, setShowCreateCalendarEntry] = useState<boolean>(false)
    const [showRecommendOrganizationModal, setShowRecommendOrganizationModal] = useState<boolean>(false)
    const [showTools, setShowTools] = useState<boolean>(false)
    const [isBookmarked, setIsBookmarked] = useState<boolean>(favorites.is("person", props.opponent.id))
    const [isBlocked, setIsBlocked] = useState<boolean>(contactState.getConnectionStatus(props.opponent.id) === "BLOCKING")
    const [connected, setConnected] = useState<boolean>(contactState.getConnectionStatus(props.opponent.id) === "CONNECTED")
    const [showReportModal, setShowReportModal] = useState<boolean>(false)

    const [userIsInMeeting, setUserIsInMeeting] = useState<boolean>(false)

    useEffect(() => {

        const roster = chime.getRoster()
        for (let rosterKey in roster) {
            if (roster[rosterKey].id === props.opponent.id) {
                setUserIsInMeeting(true)
                break;
            }
        }
        // eslint-disable-next-line
    }, [chime.getRoster()])

    useEffect(() => {
        setConnected(contactState.getConnectionStatus(props.opponent.id) === "CONNECTED")
        // eslint-disable-next-line
    }, [contactState])

    const updateBookmarkedStatus = () => {
        favorites.toggle("person", props.opponent.id)

        if (loggedInUserId) {
            syncFavorites({
                profileId: loggedInUserId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    lastSyncTime: favorites.getLastSyncTime(),
                    changedFavorites: [{
                        id: props.opponent.id,
                        kind: ("person").toUpperCase(),
                        deleted: favorites.is("person", props.opponent.id) ? false : true,
                        lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    }]
                }
            }).then((resp) => {
                favorites.setLastSyncTime(new Date())
                setIsBookmarked(favorites.is("person", props.opponent.id))
            }).catch((e: { message: React.SetStateAction<string> }) => {
                // syncFavorites failed, logged in BackendServices
            })
        }
    }

    async function updateBlocked() {
        const isBlocking = contactState.getConnectionStatus(props.opponent.id) === 'BLOCKING'
        if (loggedInUserId) {
            const response = await doConnectAction({
                profileId: loggedInUserId,
                targetProfileId: props.opponent.id,
                action: isBlocking ? 'cancel' : 'block',
                message: null
            })

            if ((response as BackendServiceError).httpStatus) {
                // TODO ERROR
            } else {
                const newStatus = (response as MakeConnectionResponse).content.profile.myConnectionStatus;
                contactState.setConnectionStatus(props.opponent.id, newStatus)
                setIsBlocked(contactState.getConnectionStatus(props.opponent.id) === "BLOCKING")
            }
        }
    }

    const link = buildDetailLink(props.opponent.id, `/person/${props.opponent.name.split(" ")[0]}_${props.opponent.name.split(" ")[1]}`, "user")

    const title = props.opponent.name
    const subtitle = [props.opponent.position, props.opponent.organization].join(" ")

    const onFavoriteButtonClicked = () => {
        updateBookmarkedStatus()
    }
    const onMeetingButtonClicked = () => {
        setShowCreateCalendarEntry(true)
    }
    const onVideoButtonClicked = () => {
        const meetingParam = meeting.getCurrentMeetingParam();
        if (meetingParam) {
            meeting.sendInvite(props.opponent.id, meetingParam)
        } else {
            meeting.sendInvite(props.opponent.id)
        }
    }
    const onRecommendButtonClicked = () => {
        setShowRecommendOrganizationModal(true)
    }
    const onMuteButtonClicked = async () => {
        const userConversationId = props.userConversationId;
        if (userConversationId) {
            const isMutedNew = await setMuteStatus(userConversationId, !props.isMuted)
            if (isMutedNew !== undefined) {
                props.setIsMuted(isMutedNew)
            }
        }
    }
    const onBlockButtonClicked = () => {
        updateBlocked()
    }

    const onDownloadClick = () => {
        loadUserData({
            targetProfileId: props.opponent.id,
            loggedInUserId: userState.user()?.profileId ?? ""
        }).then(opponent => {
            saveVCard([(opponent as SpeakerResponse).content])
        })
    }

    const onReportClick = () => {
        setShowReportModal(true)
    }

    const collapsedClassName = showTools ? "expanded" : "collapsed"
    return (
        <ChatHeaderContainer className={collapsedClassName + " privateChat"}>
            <ChatHeaderTop className={collapsedClassName}>
                <HoverButton onClick={() => appState.setShowChatsTab(null)} >{IconChevronLeft({fill: branding.sideIconBar.sideIconColorDark})}</HoverButton>
                <ConversationTitleContainer>
                    {showTools &&
                        <div style={{ paddingLeft: "19px" }}>
                            <DetailNavLink type="user" id={props.opponent.id} name={props.opponent.name}>
                                <AvatarWithPresenceState userId={props.opponent.id} initPresenceByList={false} avatarSize={36} showAvatarBadge={true} badgeSize={12} badgeRight={18} badgeTop={22} content={{ pictureUrl: props.opponent.pictureUrl, alt: title }} />
                            </DetailNavLink>
                        </div>
                    }
                    {/* // TODO  Border for user type will be shown after adding props.opponent.type inside opponent*/}
                    <ConversationTitle onClick={() => setShowTools(!showTools)}>{title}</ConversationTitle>
                    {showTools && <ConversationSubtitle>{subtitle}</ConversationSubtitle>}
                </ConversationTitleContainer>
                <HoverButton onClick={() => setShowTools(!showTools)} className={showTools ? "selected" : ""}>{IconThreeDotsMenu({fill: branding.sideIconBar.sideIconColorDark})}</HoverButton>
            </ChatHeaderTop>
            <ChatHeaderBottom>
                <ChatHeaderIconButtonsContainer className={collapsedClassName}>

                    <ContextMenu collapsed={!showTools} items={() => {
                        const menuItems: MenuItemProps[] = []

                        menuItems.push({
                            title: isBookmarked ? strings.communicationArea.chatToolFavorite : strings.contactEntry.unbookmarkTitle,
                            icon: isBookmarked ? IconBookmarkButtonFilled({fill: branding.sideIconBar.sideIconColorDark}) : IconBookmarkButton({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onFavoriteButtonClicked()
                            }
                        })

                        menuItems.push({
                            title: strings.communicationArea.chatToolCalendarEntry,
                            icon: IconCalendarEntry({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onMeetingButtonClicked()
                            }
                        })

                        menuItems.push({
                            disabled: userIsInMeeting || isExplorationOrPostEventPhase,
                            title: strings.communicationArea.chatToolVideoChat,
                            icon: IconCamera({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onVideoButtonClicked()
                            }
                        })

                        menuItems.push({
                            title: strings.communicationArea.chatToolRecommend,
                            icon: IconRecommendBlack({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onRecommendButtonClicked()
                            }
                        })

                        menuItems.push({
                            title: props.isMuted ? strings.communicationArea.chatToolUnmute : strings.communicationArea.chatToolMute,
                            icon: props.isMuted ? IconUnMuteChat({fill: branding.sideIconBar.sideIconColorDark}) : IconMuteChat({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onMuteButtonClicked()
                            }
                        })

                        menuItems.push({
                            title: isBlocked ? strings.contactEntry.unblockPersonTitle : strings.contactEntry.blockPersonTitle,
                            icon: isBlocked ? IconDecline({fill: branding.sideIconBar.sideIconColorDark}) : IconBlockContact({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onBlockButtonClicked()
                            }
                        })

                        menuItems.push({
                            disabled: !connected,
                            title: strings.contactEntry.downloadVCardTitle,
                            icon: IconDownload({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onDownloadClick()
                            }
                        })

                        menuItems.push({
                            title: strings.communicationArea.reportText,
                            icon: IconReport({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onReportClick()
                            }
                        })

                        return menuItems
                    }} />
                </ChatHeaderIconButtonsContainer>
            </ChatHeaderBottom>
            {showCreateCalendarEntry && <CalendarEntryModal viewMode={CalendarEntryModalViewMode.CREATE} sotUser={[{ id: props.opponent.id, firstName: props.opponent.name, logoUrl: props.opponent.pictureUrl }]} close={() => { setShowCreateCalendarEntry(false) }} />}
            {showRecommendOrganizationModal && <RecommendOrganizationModal targetId={props.opponent.id} type={ShareTargetType.PERSON} link={`https://${window.location.hostname}` + link} sotUser={[]} close={() => setShowRecommendOrganizationModal(false)}></RecommendOrganizationModal>}
            {showReportModal && <ReportModal show={showReportModal} onHide={() => setShowReportModal(false)} userToReportId={props.opponent.id} />}
        </ChatHeaderContainer>
    )
}

interface GroupChatHeaderProps {
    conversationId: string
    conversationType: ConversationType
    conversationDescription?: string
    conversationName?: string
    opponents: ConversationParticipant[]
    isMuted: boolean
    toggleMuteGroup?: () => Promise<boolean>
    userConversationId?: string
    clickableTitle?: boolean
    showDetails: boolean
    setShowDetails: (showDetails: boolean) => void
}

export const GroupChatHeader: React.FC<GroupChatHeaderProps> = (props) => {

    const appState = useAppState()
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const meeting = useMeetingContext()
    const chime = useChimeContext()
    const [showCreateCalendarEntry, setShowCreateCalendarEntry] = useState<boolean>(false);
    const [showTools, setShowTools] = useState(false)

    const defaultName = props.conversationType === ConversationType.CALL ? strings.chatBranding.callChatDefaultName : strings.chatBranding.groupChat
    const title = props.conversationName ?? defaultName + " (" + (props.opponents.length + 1) + ")"
    const subtitle = props.opponents.map((op) => op.name).concat(strings.chatBranding.youText).join(", ")

    const onMeetingButtonClicked = () => {
        setShowCreateCalendarEntry(true)
    }
    const onVideoButtonClicked = async () => {
        if (props.conversationType === ConversationType.CALENDARENTRY) {
            chime.createOrJoinMeeting(props.conversationId, 'calenderEntry')
        } else {
            const resp = await listAttendeeInfos(getExternalMeetingId(props.conversationId, "call"))
            if ((resp as BackendServiceError).httpStatus) { return }
            const attendees = resp as AttendeeData[]
            if (attendees.length <= 0) {
                props.opponents.forEach(opponent => {
                    meeting.sendInvite(opponent.id, { meetingId: props.conversationId, meetingKind: "call" })
                })
            } else {
                chime.createOrJoinMeeting(props.conversationId, 'call')
            }
        }
    }
    const onMuteButtonClicked = async () => {
        if (!props.toggleMuteGroup) {
            return
        }
        const success = await props.toggleMuteGroup();
        if (!success) {
            // TODO Error handling
        }
    }
    const onConversationTitleClicked = () => {
        if (!props.showDetails) {
            setShowTools(!showTools)
        }
    }
    const onConversationDetailButtonClicked = () => {
        props.setShowDetails(true);
        setShowTools(false)
    }
    const onNavigateUp = () => {
        if (props.showDetails) {
            props.setShowDetails(false)
        } else {
            appState.setShowChatsTab(null)
        }
    }

    const collapsedClassName = showTools ? "expanded" : "collapsed"
    const groupChatClassName = props.conversationDescription ? "groupChatWithDescription" : "groupChat"

    return (
        <ChatHeaderContainer className={collapsedClassName + " " + groupChatClassName} >
            <ChatHeaderTop className={collapsedClassName}>
                <HoverButton onClick={onNavigateUp} >{IconChevronLeft({fill: branding.sideIconBar.sideIconColorDark})}</HoverButton>
                <ConversationTitleContainer onClick={onConversationTitleClicked}>
                    <ConversationTitle>{title}</ConversationTitle>
                    <ConversationSubtitle>{subtitle}</ConversationSubtitle>
                    <ConversationSubtitle> </ConversationSubtitle>
                </ConversationTitleContainer>
                <HoverButton onClick={() => setShowTools(!showTools)} className={props.showDetails ? "invisible" : showTools ? "selected" : ""}>{IconThreeDotsMenu({fill: branding.sideIconBar.sideIconColorDark})}</HoverButton>
            </ChatHeaderTop>
            {props.conversationDescription && <ConversationDescription className={collapsedClassName}>{props.conversationDescription}</ConversationDescription>}
            <ChatHeaderBottom>
                <ChatHeaderIconButtonsContainer className={collapsedClassName}>
                    <ContextMenu collapsed={!showTools} itemsPerRow={2} items={() => {
                        const menuItems: MenuItemProps[] = []

                        menuItems.push({
                            title: strings.communicationArea.chatToolCalendarEntry,
                            icon: IconCalendarEntry({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onMeetingButtonClicked()
                            }
                        })

                        menuItems.push({
                            disabled: isExplorationOrPostEventPhase,
                            title: strings.communicationArea.chatToolVideoChat,
                            icon: IconCamera({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onVideoButtonClicked()
                            }
                        })

                        menuItems.push({
                            title: props.isMuted ? strings.communicationArea.chatToolUnmute : strings.communicationArea.chatToolMute,
                            icon: props.isMuted ? IconUnMuteChat({fill: branding.sideIconBar.sideIconColorDark}) : IconMuteChat({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onMuteButtonClicked()
                            }
                        })

                        menuItems.push({
                            title: strings.chatBranding.detailsText,
                            icon: IconSettings({fill: branding.sideIconBar.sideIconColorDark}),
                            onClick: () => {
                                onConversationDetailButtonClicked()
                            }
                        })

                        return menuItems
                    }} />
                </ChatHeaderIconButtonsContainer>
            </ChatHeaderBottom>
            {showCreateCalendarEntry && <CalendarEntryModal viewMode={CalendarEntryModalViewMode.CREATE} sotUser={props.opponents.map(op => { return { id: op.id, firstName: op.name, logoUrl: op.pictureUrl } })} close={() => { setShowCreateCalendarEntry(false) }} />}
        </ChatHeaderContainer>
    )
}