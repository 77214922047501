import { BasisPremiumType } from "../branding/BasisPremiumBranding";

export interface Stand {
    hallNr: string,
    standNr: string,
    type: string,
    hallName: string,
    hallNameShort: string,
    standName: string,
    standNameShort: string,
    categories: Category[]
}
export interface Contact {
    showroomStandby: any;
    id: string,
    initials: string,
    logoUrl: string,
    firstName: string,
    middleName: string,
    infotext: string,
    lastName: string,
    phone: string,
    mobile?: string,
    email: string,
    fax: string,
    position: string,
    company: string,
    organization: string,
    comment: string,
    stands: Stand[],
    socialMedias: SocialMedia[],
    categories: Category[],
    exhibitors: Exhibitor[],
    eventDates: EventDate[],
    interests?: Array<String>,
    facebook?: string,
    linkedIn?: string,
    googleplus?: string,
    youTube?: string,
    twitter?: string,
    xing?: string,
    pinterest?: string,
    instagram?: string,
    organizations?: Array<{ id: string, name: string }>,
    sotUserId?: string,
    person?: string,
    myConnectionStatus: string,
    type: string,
    topics?: string[],
    description?: Description,
    visible: boolean,
    title: string
}
export interface News {
    id: string,
    initials: string,
    logoURL: string,
    name: string,
    text: string,
    orderkey: string,
    categories: Category[],
    descriptionTeaser: string
    description: {
        title: string
        text: string
        teaser: string
    }
}
export interface Exhibitor {
    id: string,
    type: "organization",
    orderkey: string,
    initials: string,
    email: string,
    name: string,
    countryCode: string,
    country: string,
    city: string,
    postCode: string,
    basisPremium: BasisPremiumType,
    pictureURL: string,
    pictureText: string,
    backgroundImageURL: string
    teaser: string,
    stands: Stand[],
    contacts: Contact[],
    categories: Category[],
    products: Product[]
    news: News[]
    eventdates: EventDate[];
}
export interface Description {
    title: string,
    text: string,
    teaser: string
}
export interface SocialMedia {
    link: string,
    type: string
}
export interface Link {
    id: string,
    url: string,
    text: string
}
export interface Office {
    adress: string,
    city: string,
    country: string,
    postBoxPostCode: string
}
export interface Category {
    id: string,
    alias: string,
    name: string,
    nameDe: string,
    orderkey: string,
    orderkeyDe: string,
    badgeName: string,
    badgeType: string,
    code: string,
    parent: string,
    parentDe: string,
    iconSmallUrl: string,
    iconMediumUrl: string,
    iconLargeUrl: string,
    colorBooth: string,
    colorBorder: string,
    colorFill: string,
    colorText: string
}
export interface Product {
    id: string
    type: "product"
    productid: string
    initials: string
    name: string
    logoUrl: string
    pictureURL: string
    pictureText: string
    description: Description
    categories: Category[]
    attachments: Attachment[]
    exhibitors: Exhibitor[]
    persons: Person[]
    links: Link[]
    organizations: Company[]
}
export interface Coupon {
    id: string
    initials: string
    title: string
    teaser: string
    pictureURL: string
    pictureText: string
    url: string
    validStart: string
    validEnd: string
    company: {
        id: string
        name: string
        pictureURL: string
    }
}
export interface Attachment {
    id: string
    url: string
    title: string
    description: string
    largeThumbnailUrl: string
    smallThumbnailUrl: string
    contentType: string
}
export interface Trademark {
    id: string
    type: "trademark"
    initials: string
    name: string
    logoUrl: string
    pictureURL: string
    pictureText: string
    description: Description
    categories: Category[]
    attachments: Attachment[]
    links: Link[]
    organizations: Company[]
}
export interface DescriptionCompany {
    description: Description
}
export interface Company {
    exhibitors: Exhibitor[];
    id: string
    alias: string
    orderkey: string
    initials: string
    name: string
    basisPremium: string
    adress1: string
    postCode: string
    postBoxCity: string
    web: string
    description: Description
    pictureURL: string
    pictureText: string
    backgroundImageURL: string
    foundingYear: string
    size: string
    industry: string
    socialMedias: SocialMedia[]
    links: Link[]
    offices: Office[]
    stands: Stand[]
    persons: Person[]
    products: Product[]
    trademarks: Trademark[]
    coupons: Coupon[]
    city: string
    country: string
    phone: string
    email: string
    news: News[]
    categories: Category[]
    attachments: Attachment[]
    eventDates: EventDate[]
    headerVideoUrl: string
    headerVideoImageUrl: string
    children: Company[]
    parent: Company
}
export interface Person {
    id: string,
    userId: string,
    userLogoUrl: string,
    initials: string,
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    personFunctionIdRef: string,
    personFunctionName?: string,
    personFunctionTargetName?: string,
    position: string,
    organization: string,
    logoUrl: string,
    categories: Category[],
    topic: any,
    visible: boolean,
    showroomStandby: boolean,
    name: string,
    title: string
}
export interface EventDate {
    id: string,
    location: string,
    date: string,
    start: string,
    startHour: number,
    dateTimeStart: string,
    startMinutes: number,
    end: string,
    endHour: number,
    endMinutes: number,
    dateTimeEnd: string,
    name: string,
    persons: Person[],
    teaser: string,
    text: string,
    formattedDate: string,
    logoText: string
    logoUrl: string
    attachments: Attachment[]
    organizationId?: string
    organizationLogoUrl?: string
    channel?: Channel
    liveStreamingPreviewUrl: string,
    videoOnDemandUrl: string,
    type?: string
    order?: number
    categories?: Array<string>
    roundTableAccessStatus?: string
}

export interface Channel {
    id: string,
    name: string,
    slidoName: string,
    url: string,
    thumbnailUrl: string
}
export interface Suggestion {
    id: string,
    value: string,
    title: string,
    subtitle: string,
    icon: string,
    type?: EntityType
}
export interface SuggestGroup {
    id: string,
    name: string,
    trackingName: string,
    maxSuggestions: number,
    suggestions: Suggestion[],
}

export interface Organization {
    id: string,
    name: string
    city: string,
    country: string,
    logo: string
}
export interface NetworkingUser {
    id: string,
    firstName: string,
    lastName?: string,
    logoUrl?: string,
    loaded: boolean,
    position?: string,
    company?: string,
    speaker?: number,
    contactOfExhibitor?: number,
    showLoader?: boolean,
    rating?: number,
    organizations?: Array<Organization>,
    interests?: Array<string>
    myConnectionStatus?: string,
    person?: string
    type?: string
}
export interface InvitePerson {
    id: string,
    firstName: string,
    lastName?: string,
    logoUrl?: string,
    userId?: string
}

export interface PrivacyUserAnswer {
    sotUserId: string
    questionText: string
    answerText: string
    accepted: boolean
    questionId: string
}

export interface VirtualCafeAccessUser {
    id: string
    firstName: string
    lastName: string
    logoUrl: string
    position: string
}

export interface NewsItem {
    id: string
    title: string
    text: string
    subtitle: string
    date: string
    imageurl: string
    links: { link_text: string, link_url: string }[]
}

export enum ShareTargetType {
    ORGANIZATION = "ORGANIZATION",
    PERSON = "PERSON",
    SOTUSER = "SOTUSER",
    EVENTDATE = "EVENTDATE"
}

export type EntityType = "eventdate" | "person" | "organization" | "product" | "trademark" | "news" | "networking_user"