import React, { useState, useEffect, ChangeEvent } from "react"
import { Button, Form, Modal, DropdownButton, Dropdown, NavLink } from "react-bootstrap"
import { CalendarEntry, createNewCalendarEntry, deleteCalendarEntryForUser, updateCalendarEntryById, deleteCalendarEntryParticipationById, createNewCalendarEntryParticipation, updateCalendarEntryParticipationStatus, trackVisit } from "../backendServices/BackendServices";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import { useLanguageState } from "../globalStates/LanguageState";
import styled from "styled-components";
import branding, { LocalizedString } from "../branding/branding";
import moment from "moment";
import { InvitePerson } from "../backendServices/Types";
import { CalendarEntryParticipationStatus } from "../API";
import SearchPersonInput from "../communicationArea/SearchPersonInput";
import PersonChip from "./PersonChip";
import { IconCloseFilled, IconCalendarEntry, IconClose } from "./Icons";
import { useChimeContext } from "../conference/context/ChimeContext";
import { useAppState } from "../globalStates/AppState";
import { ChatConversationParam } from "../communicationArea/ChatPage";
import { ConversationType } from "../API";
import { isExplorationOrPostEventPhase } from "../EventPhaseChecker"
import ReactDOM from "react-dom";
import CrsTimePicker from "./CrsTimePicker/CrsTimePicker";

const WhenTimeContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a:focus {
        opacity: 0.5;
    }

    .rs-picker-date {
        border: none;
        border-bottom: 1px solid ${branding.mainInfoColor};
        border-radius: 0px;
    }

    .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn {
        border:none
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        border: none
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-left: 0px;
    }

    .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
        color: ${branding.calendarEntryModalPageContent.datePickerMenuHeaderColor ?? "#1675e0"};
    }

`
const TitleImage = styled.div`
    font-size: 16px;
    margin-top: 6px;
    svg {
        color: ${branding.primaryColor};
    }
`
const Title = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 20px;
    margin-top: 9px;
    font-size: 16px;
    font-family: ${branding.font1};
`
const BelowTitle = styled.div`
    margin-top: 20px;
    font-size: 14px;
    font-family: ${branding.font1};
`
const SubmitButtonContainerLinks = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 12px;
    font-family: ${branding.font1};
`

const SubmitButtonContainer = styled.div`
    /* display: flex;
    justify-content: space-between;
    margin-left: -35px;
    margin-right: -35px;
    margin-top: 30px;
    margin-bottom: 32px; */
    width: 100%;
    justify-content: space-between;
    display: inline-flex;
    margin-bottom: 20px;
    margin-top: 30px;
`

const SelectDateRoot = styled.div`
margin-bottom: 24px;
width: 100%;

.btn {
    text-align: left;
    width: 600px;
    box-shadow: none;
} 

 .btn-primary{
    border: none;
    border-bottom: 1px solid ${branding.mainInfoColor};
    border-radius: 0px;
    padding-left: 0px;
    font-size: inherit;
    cursor: default;
    color: #8e8e93;
    background-color: #fff;
    box-shadow: none;
}

.btn-primary:hover {
    background-color: #fff;
}

.btn-primary a:hover{
    background: ${branding.mainInfoColor};
}

.btn-primary:active,
.btn-primary.active {
    outline: unset;
    border: none;
    background-color: #fff;
}

.btn .btn-primary:focus {
    outline:unset !important;
    box-shadow: none;
    border: none;
}
.btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: ${branding.mainInfoColor};
    background-color: #fff; 
    border-color: #fff; 
    border: none;
}
.dropdown-item {
    width: 600px;
    font-size: 14px;
}
.dropdown-item:focus {
 background-color: #fff; 
 color: ${branding.mainInfoColor};
}

.dropdown-toggle::after{
   content: none
}
`
const PickersGroupRoot = styled.div`
    margin-bottom: 24px;

    .form-control {
        border: none;
        border-bottom: 1px solid ${branding.mainInfoColor};
        border-radius: 0px;
        padding-left: 0px;
        font-size: inherit;
        cursor: default;
    }

    .form-control:focus {
        outline: none;
        box-shadow: none;
    }

    .rs-picker-date {
        border-bottom: 1px solid ${branding.mainInfoColor};
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-left: 0px;
    }

    .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn {
        border:none;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        border: none;
    }
`

const StyledNotesInput = styled(Form.Control)`
    border: 1px solid ${branding.mainInfoColor};
    border-radius: 0px;
    resize: vertical;
    width: 100%;
    cursor: default;
    padding: 8px;

    :focus {
        outline: none;
    }
`

const PeopleDisplayArea = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
`
const SubmitButtonDanger = styled(Button) <{ backgroundColor: string }>`
    display: block;
    width: 40%;
    /* margin: auto; */
    border-radius: 20px;
    font-size: 12px;
    background-color: ${props => props.backgroundColor};
    /* color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
    border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"}; */

`
const SubmitButton = styled(Button) <{ backgroundColor: string }>`
    display: block;
    width: 40%;
    /* margin: auto; */
    border-radius: 20px;
    font-size: 12px;
    background-color: ${props => props.backgroundColor};
    color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
    border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"};
     
    :hover {
      background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor ?? "#e8e8e8"} !important; 
      color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor ?? "#000"} !important;}
      border: 2px solid ${props => props.backgroundColor};
`

const StyledSearchPersonInput = styled(SearchPersonInput)`
    border-bottom: 1px solid ${branding.mainInfoColor};
    line-height: 36px;
    height: 36px;
    margin-bottom: 24px;
`

const CalendarEntryMainModal = styled(Modal)`
    color: ${branding.mainInfoColor};
    font-family: ${branding.font1};
    .modal-dialog {
        width: 700px !important;
        max-width: 700px !important;
        min-width: 700px !important;
    }
 
    .modal-content {
        padding-left: 32px;
        padding-right: 32px;
        overflow: auto;
        border-radius: 0px;
    }

    .modal-header .modal-title {
        color: ${branding.mainInfoColor};
        font-weight: 300;
    }

    .modal-header {
        padding-top: 25px;
        border-bottom: none;
        display: block;
    }

    .modal-header .modal-title {
        width: 100%;
        overflow: visible;
        white-space: normal;
    }

`

const CloseButton = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 0px;
    padding-right: 1rem;
    cursor: pointer;
    svg {
            color: ${branding.mainInfoColor};
        }
`
const DisagreeButton = styled.div`
    width: 30%;
    height: 30px;
    cursor: pointer;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
    
    &:hover{
        text-decoration: none;
    }
`
const ButtonLinks = styled(NavLink)`
  /* text-decoration: underline; */
  padding: 0px;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid ${branding.mainInfoColor};
  color: ${branding.primaryColor};
  &:hover {
    text-decoration: none;
    color: ${branding.mainInfoColor};
}
`
const Label = styled.div`
    margin-bottom: 4px;
`

const NotesContainer = styled.div`
    margin-top: 50px;
`

const DeleteModal = styled(Modal)`
    background-color: rgba(0,0,0,0.3);
`

const DeleteButton = styled(Button)`
    margin-left: 16px;
    border-radius: 20px;
    font-size: 12px;
    
`
const DeleteMeetingTitle = styled.span`
 font-family: ${branding.font1};
 color: ${branding.mainInfoColor ?? "#000"};
`
const DeleteMeetingRoot = styled.div`
 font-family: ${branding.font1};
 color: ${branding.mainInfoColor ?? "#000"};
`
const MAX_NOTES_LENGTH = 200

export interface CalendarEntryModalBranding {
    createTitle: LocalizedString
    descriptionTitle: LocalizedString
    descriptionTitleAfter: LocalizedString
    descriptionTitleOrganization: LocalizedString
    editTitle: LocalizedString
    name: LocalizedString
    namePlaceholder: LocalizedString
    searchPerson: LocalizedString
    searchPersonPlaceholder: LocalizedString
    dateSelectionPlaceholder: LocalizedString
    searchPersonIsInvited: LocalizedString
    notFound: LocalizedString
    startDatePlaceholder: LocalizedString
    endDatePlaceholder: LocalizedString
    day: LocalizedString
    start: LocalizedString
    end: LocalizedString
    dayPlaceholder: LocalizedString
    notes: LocalizedString
    notesPlaceholder: LocalizedString
    sendInvite: LocalizedString
    saveChanges: LocalizedString
    deleteMeeting: LocalizedString
    dayFormat: LocalizedString
    calendarEntryParticipationStatusAccepted: LocalizedString
    calendarEntryParticipationStatusDeclined: LocalizedString
    calendarEntryParticipationStatusRequested: LocalizedString
    cancel: LocalizedString
    cancelText: LocalizedString
    datePickerMenuButtonColor: string
    datePickerMenuButtonHoverColor: string
    datePickerMenuActiveListColor: string
    datePickerMenuHeaderColor: string
    timePickerDefaultMinutes: number
}

enum CalendarEntryParticipationStatusColor {
    ACCEPTED = '#00B300',
    DECLINED = '#FF0000',
    REQUESTED = '#000000',
}

export enum CalendarEntryModalViewMode {
    CREATE,
    EDIT,
    VIEW
}
interface CalendarEntryModalProps {
    sotUser?: InvitePerson[]
    organization?: string
    calendarEntry?: CalendarEntry
    viewMode: CalendarEntryModalViewMode
    onUpdated?: (calendarEntry: CalendarEntry) => void
    onRemovedFromList?: () => void
    close: () => void
}
const CalendarEntryModal = (props: CalendarEntryModalProps) => {
    const participantLimit = branding.configuration.calendarEntryParticipantLimit
    const userlink = useLoggedInState()
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const [createButtonDisabled, setCreateButtonDisabled] = useState(true)
    const [joinMeetingDisabled, setJoinMeetingDisabled] = useState(false)
    const [title, setTitle] = useState("")
    const currentDateEnd = new Date()
    const currentDate = new Date()
    currentDate.setMinutes(currentDate.getMinutes() + 30)
    const [start, setStart] = useState<Date | null>(null)
    currentDateEnd.setMinutes(currentDateEnd.getMinutes() + 60)
    const [end, setEnd] = useState<Date | null>(null)
    const [day, setDay] = useState<Date | null>(null)
    const [selectedDate, setSelectedDate] = useState<string | undefined>()
    const [notes, setNotes] = useState("")
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const chimeContext = useChimeContext()
    const appState = useAppState()
    const [closeModal, setCloseModal] = useState<boolean>(false)

    const [eventDates, setEventDates] = useState<string[]>(branding.eventTiming.eventDays)

    const canEdit = props.viewMode === CalendarEntryModalViewMode.CREATE || props.viewMode === CalendarEntryModalViewMode.EDIT
    const user = userlink.user()
    const profileId = user?.profileId
    const organizer: InvitePerson = {
        id: profileId ?? "",
        firstName: user?.firstName ?? "",
        lastName: user?.lastName,
        logoUrl: user?.logoUrl
    }

    const org = (props.calendarEntry ? props.calendarEntry.organizationId : props.organization)
    const myParticipationStatus = props.calendarEntry?.participants.items.find(participation => profileId === participation.userId)?.status

    const invited = props.sotUser || []
    if (!invited.find(item => item.id === organizer.id)) {
        invited.unshift(organizer)
    }

    const [contactsInvited, setContactsInvited] = useState<InvitePerson[]>(invited)
    useEffect(() => {
        if (closeModal) {
            onCloseModalView()
        }
        // eslint-disable-next-line
    }, [closeModal]);

    useEffect(() => {
        let missingDates: string[] = []
        let eventDatesFormated = languageState.getLanguage() === "en" ? branding.eventTiming.eventDaysFormated : branding.eventTiming.eventDaysFormatedDe
        eventDatesFormated.forEach(edf => {
            if (eventDates.map(ed => moment(ed).format(dayFormat)).indexOf(edf) < 0) {
                missingDates.push(moment(edf).toISOString())
            }
        })
        if (missingDates.length > 0)
            setEventDates(eventDates.concat(missingDates))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const calendarEntry = props.calendarEntry
        if (calendarEntry && (props.viewMode !== CalendarEntryModalViewMode.CREATE)) {
            setStart(moment(calendarEntry.start).toDate())
            setEnd(moment(calendarEntry.end).toDate())
            setDay(moment(calendarEntry.start).toDate())
            setTitle(calendarEntry.title)
            const participations = calendarEntry.participants.items
            // Shift organizer participation to first index
            participations.unshift(participations.splice(participations.findIndex(participation => participation.userId === calendarEntry.userId), 1)[0])
            setContactsInvited(participations.map(participation => {
                return {
                    logoUrl: participation.user.pictureUrl,
                    id: participation.user.id,
                    firstName: participation.user.name,
                    status: participation.status
                }
            }))
            if (calendarEntry.description) {
                setNotes(calendarEntry.description)
            }
        }

        // eslint-disable-next-line
    }, [props.calendarEntry, props.viewMode])

    function onCloseModalView() {
        if (profileId === undefined) {
            return;
        }
        if (props.viewMode === CalendarEntryModalViewMode.CREATE) {
            setContactsInvited(props.sotUser ? [organizer].concat(props.sotUser) : [organizer])
            setNotes("")
            setTitle("")
            setSelectedDate(selectedDate);
            if (props.calendarEntry) {
                setStart(moment(props.calendarEntry.start).toDate())
                setEnd(moment(props.calendarEntry.end).toDate())
                setDay(moment(props.calendarEntry.start).toDate())

            } else {
                const resetStartDate = new Date()
                const resetEndDate = new Date()
                resetEndDate.setMinutes(resetStartDate.getMinutes() + 30)
                setStart(resetStartDate)
                setEnd(resetEndDate)
                setDay(new Date())
                setSelectedDate("")
            }
            setCreateButtonDisabled(true)
        }

        props.close()
    }

    useEffect(() => {
        if (title.length > 0 && (contactsInvited.length > 0 || org) && !isDateInValid(day, start, end)) {
            setCreateButtonDisabled(false)
        } else {
            setCreateButtonDisabled(true)
        }
    }, [day, start, end, contactsInvited, title, org])

    useEffect(() => {
        if (!isValidDay(day!)) {
            setJoinMeetingDisabled(true)
        } else {
            setJoinMeetingDisabled(false)
        }
    }, [day])

    //moment(day).dayOfYear() < moment().dayOfYear()
    function isDateInValid(day: Date | null, startDate: Date | null, endDate: Date | null): Boolean {
        if (day == null || startDate == null || endDate == null) {
            return true
        }
        return startDate > endDate
    }

    function chatWithParticipants() {
        onChatWithParticipants()
    }

    function onChatWithParticipants() {
        appState.setShowChatsTab(ChatConversationParam.conversationByConversationId(ConversationType.CALENDARENTRY, props.calendarEntry!.id));
        props.close()
    }

    function joinMeeting() {
        onCallParticipants()
    }

    async function onCallParticipants() {
        if (props.calendarEntry) {
            setCloseModal(true)
            chimeContext.createOrJoinMeeting(props.calendarEntry.id, "calenderEntry")
        }
    }

    async function createOrUpdateCalendarEntry() {
        if (profileId != null && start != null && end != null && day != null) {
            const startDateMoment = moment(day).tz(appState.timezone)
            startDateMoment.set("date", day.getDate())
            startDateMoment.set("hour", start.getHours())
            startDateMoment.set("minute", start.getMinutes())

            const endDateMoment = moment(day).tz(appState.timezone)
            endDateMoment.set("date", day.getDate())
            endDateMoment.set("hour", end.getHours())
            endDateMoment.set("minute", end.getMinutes())

            const startDate = startDateMoment.toDate()
            const endDate = endDateMoment.toDate()

            var result: CalendarEntry | null = null
            const calendarEntry = props.calendarEntry
            const participantsId = contactsInvited.map(contact => { return contact.id })
            if (props.viewMode === CalendarEntryModalViewMode.CREATE && contactsInvited != null) {
                result = await createNewCalendarEntry(profileId, participantsId, title, startDate, endDate, notes, org)
                // TODO create groupchat conversation
            } else if (props.viewMode === CalendarEntryModalViewMode.EDIT && calendarEntry != null) {
                await Promise.all(calendarEntry.participants.items.map(async oldParticipation => {
                    // Delete removed participations
                    if (!participantsId.includes(oldParticipation.userId)) {
                        await deleteCalendarEntryParticipationById(oldParticipation.id)
                    } else {
                        // Update existing participation when startDate, endDate or title was changed
                        if (calendarEntry.start !== startDate.toISOString() || calendarEntry.end !== endDate.toISOString() || calendarEntry.title !== title) {
                            await updateCalendarEntryParticipationStatus(oldParticipation.id, oldParticipation.userId, startDate)
                        }
                    }
                }))

                const oldParticipatsIds = calendarEntry.participants.items.map(participation => { return participation.userId })
                await Promise.all(participantsId.map(async participantId => {
                    // Create new participations
                    if (!oldParticipatsIds.includes(participantId)) {
                        await createNewCalendarEntryParticipation(calendarEntry.id, participantId, CalendarEntryParticipationStatus.REQUESTED, startDate)
                    }
                }))

                result = await updateCalendarEntryById(calendarEntry, title, startDate, endDate, notes)
            }

            if (result != null) {
                if (props.viewMode === CalendarEntryModalViewMode.CREATE && props.organization) {
                    trackVisit(profileId, props.organization, 'CALENDARENTRY#SENT')
                }
                onCloseModalView()
                if (props.onUpdated) {
                    props.onUpdated(result)
                }
            } else {
                // TODO ERROR WHAT TO SHOW HERE?
            }
        }
    }

    async function deleteCalendarEntry() {
        setShowConfirmationDialog(false)
        if (profileId != null && props.calendarEntry?.id != null) {
            const result = await deleteCalendarEntryForUser(profileId, props.calendarEntry.id)

            if (result != null) {
                onCloseModalView()
                if (props.onRemovedFromList) {
                    props.onRemovedFromList()
                }
            } else {
                // TODO ERROR WHAT TO SHOW HERE?
            }
        }
    }

    const formatDateFromTime = (time: string) => {
        let date = new Date();
        const [hours, minutes] = time.split(':').map(value => parseInt(value));
        date.setHours(hours)
        date.setMinutes(minutes)
        date.setSeconds(0)
        return date
    }

    function onStartSelected(time: string) {
        //const meetingLength = getMeetingLength(start, end);
        let date = formatDateFromTime(time)
        setStart(date);
        setEnd(moment(date).add(10, "m").toDate())
    }


    function onStartEntered() {
        if (props.viewMode === CalendarEntryModalViewMode.CREATE) {
            const resetStartDate = new Date();
            resetStartDate.setHours(resetStartDate.getHours() + Math.ceil(resetStartDate.getMinutes() / 60));
            resetStartDate.setMinutes(0, 0, 0);
            setStart(resetStartDate);
            setEnd(moment(resetStartDate).add(10, "m").toDate())
        }
    }


    function onEndSelected(time: string) {
        let date = formatDateFromTime(time)
        setEnd(date)
    }

    const dayFormat = strings.eventTiming.eventDaysFormatPatternShort

    function onDaySelected(dateString: string) {
        setSelectedDate(dateString);
        setDay(moment(dateString, dayFormat).toDate());
        const resetStartDate = new Date()
        const resetEndDate = new Date()
        resetStartDate.setHours(resetStartDate.getHours() + Math.ceil(resetStartDate.getMinutes() / 60));
        resetStartDate.setMinutes(0, 0, 0);
        resetEndDate.setHours(resetEndDate.getHours() + Math.ceil(resetEndDate.getMinutes() / 60));
        resetEndDate.setMinutes(resetStartDate.getMinutes() + 10)
        setStart(resetStartDate);
        setEnd(resetEndDate);
    }

    function onTitleChanged(event: ChangeEvent<HTMLInputElement>) {
        setTitle(event.target.value)
    }

    function onNotesChanged(event: ChangeEvent<HTMLTextAreaElement>) {
        if (event.target.value.length > MAX_NOTES_LENGTH) {
            return
        }
        setNotes(event.target.value)
    }

    function onParticipantInvited(item: any) {
        if (contactsInvited.length >= participantLimit) {
            return
        }
        setContactsInvited(contactsInvited.concat(item))
    }

    function removePerson(item: any) {
        setContactsInvited(contactsInvited.filter(it => it.id !== item.id))
    }

    function isValidDay(day: Date) {
        if (day === null) {
            return false
        }
        return (day.getFullYear() === moment().year() && moment(day).dayOfYear() >= moment().dayOfYear())
    }

    /*function numberOfAcceptedParticipants(): number {
        if (props.calendarEntry && props.calendarEntry?.participants.items.length > 0)
            return props.calendarEntry.participants.items.filter(x => x.status === CalendarEntryParticipationStatus.ACCEPTED).length

        return 0
    }*/
    const defaultMinutesInterval = branding.calendarEntryModalPageContent.timePickerDefaultMinutes

    return (<>
        <CalendarEntryMainModal backdrop="static" animation={false} show>
            <div onClick={(e) => e.stopPropagation()}>
                <Modal.Header style={{ paddingRight: 0, paddingTop: 25 }}>
                    <Modal.Title style={{ display: "inline-flex" }}>
                        <TitleImage>
                            {IconCalendarEntry({ width: "20", height: "20", fill: branding.sideIconBar.sideIconColorDark })}
                        </TitleImage>
                        <Title>{props.viewMode === CalendarEntryModalViewMode.EDIT ? strings.calendarEntryModalPageContent.editTitle : props.viewMode === CalendarEntryModalViewMode.CREATE ? strings.calendarEntryModalPageContent.createTitle : title}</Title>
                        <CloseButton onClick={(event) => {
                            event.stopPropagation()
                            onCloseModalView()
                        }}>{IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "12", height: "12" })}</CloseButton>
                    </Modal.Title>
                    <BelowTitle>{props.viewMode === CalendarEntryModalViewMode.EDIT ? '' : props.viewMode === CalendarEntryModalViewMode.CREATE ? (org ? strings.calendarEntryModalPageContent.descriptionTitleOrganization : `${strings.calendarEntryModalPageContent.descriptionTitle} ${participantLimit - 1} ${strings.calendarEntryModalPageContent.descriptionTitleAfter}`) : ""}</BelowTitle>
                </Modal.Header>
                <Modal.Body>
                    {props.viewMode !== CalendarEntryModalViewMode.VIEW &&
                        <PickersGroupRoot>
                            <Label>{strings.calendarEntryModalPageContent.name}</Label>
                            <Form.Control as="input" type="text" value={title} disabled={!canEdit} onChange={onTitleChanged} placeholder={strings.calendarEntryModalPageContent.namePlaceholder}></Form.Control>
                        </PickersGroupRoot>}
                    {!org && <Label style={{ marginTop: "auto", marginBottom: "auto" }}>{strings.calendarEntryModalPageContent.searchPerson + ` (${contactsInvited.length} / ${participantLimit})`}</Label>}
                    <PeopleDisplayArea>
                        {contactsInvited.map((contact, index) => {
                            const statusColor = (contact as any).status === CalendarEntryParticipationStatus.ACCEPTED ? CalendarEntryParticipationStatusColor.ACCEPTED :
                                (contact as any).status === CalendarEntryParticipationStatus.DECLINED ? CalendarEntryParticipationStatusColor.DECLINED : CalendarEntryParticipationStatusColor.REQUESTED
                            const statusLabel = (contact as any).status === CalendarEntryParticipationStatus.ACCEPTED ? strings.calendarEntryModalPageContent.calendarEntryParticipationStatusAccepted :
                                (contact as any).status === CalendarEntryParticipationStatus.DECLINED ? strings.calendarEntryModalPageContent.calendarEntryParticipationStatusDeclined :
                                    (contact as any).status === CalendarEntryParticipationStatus.REQUESTED ? strings.calendarEntryModalPageContent.calendarEntryParticipationStatusRequested : undefined

                            return (
                                <PersonChip key={index} userId={contact.id}
                                    label={[contact.firstName, contact.lastName].filter(Boolean).join(" ")}
                                    sublabel={statusLabel}
                                    logoUrl={contact.logoUrl}
                                    icon={contact.id !== profileId && canEdit ? IconCloseFilled({ stroke: "#FFFFFF", fill: statusColor }) : undefined}
                                    onIconClick={() => removePerson(contact)}
                                    labelColor={statusColor}
                                    backgroundColor={'#FFF'}>
                                </PersonChip>
                            );
                        })}
                    </PeopleDisplayArea>
                    {!org && props.viewMode !== CalendarEntryModalViewMode.VIEW &&
                        <StyledSearchPersonInput placeholder={strings.calendarEntryModalPageContent.searchPersonPlaceholder} personClicked={onParticipantInvited} excludeIds={contactsInvited.map((p) => p.id)} />
                    }
                    <Label>{strings.calendarEntryModalPageContent.day}</Label>
                    <SelectDateRoot>
                        <DropdownButton id="dropdown-date-button" disabled={props.viewMode === CalendarEntryModalViewMode.VIEW} title={props.viewMode === CalendarEntryModalViewMode.CREATE && selectedDate !== undefined ? selectedDate :
                            day !== null ? moment(day).format(dayFormat) : strings.calendarEntryModalPageContent.dateSelectionPlaceholder}>
                            {eventDates && eventDates.map((dateString, key) => {
                                return <Dropdown.Item key={key} onSelect={() => onDaySelected(moment(dateString).format(dayFormat))}>{moment(dateString).format(dayFormat)}</Dropdown.Item>
                            })}
                        </DropdownButton>
                    </SelectDateRoot>
                    <WhenTimeContent>
                        <CrsTimePicker
                            width={'48%'}
                            rootClass={'mt-2'}
                            onMenuOpen={() => { if (!start) onStartEntered() }}
                            label={strings.calendarEntryModalPageContent.start}
                            placeholder={strings.calendarEntryModalPageContent.startDatePlaceholder}
                            hideMinutes={minute => minute % defaultMinutesInterval !== 0}
                            hideHours={hour => hour > 23}
                            defaultTime={start ? moment(start).format('HH:mm') : undefined}
                            rootPageSelector={'.modal'}
                            onSelect={onStartSelected} />

                        <CrsTimePicker
                            width={'48%'}
                            rootClass={'mt-2'}
                            label={strings.calendarEntryModalPageContent.end}
                            placeholder={strings.calendarEntryModalPageContent.endDatePlaceholder}
                            hideMinutes={minute => minute % defaultMinutesInterval !== 0}
                            hideHours={hour => hour > 23}
                            defaultTime={end ? moment(end).format('HH:mm') : undefined}
                            rootPageSelector={'.modal'}
                            onSelect={onEndSelected} />

                    </WhenTimeContent>
                    <NotesContainer>
                        <Label>{strings.calendarEntryModalPageContent.notes + " (" + notes.length + " / " + MAX_NOTES_LENGTH + ")"}</Label>
                        <StyledNotesInput rows={3} spellCheck={false} style={{ pointerEvents: canEdit ? "auto" : "none" }} value={notes} onChange={onNotesChanged} as="textarea" placeholder={canEdit ? strings.calendarEntryModalPageContent.notesPlaceholder : ""} />
                    </NotesContainer>
                    {(props.viewMode === CalendarEntryModalViewMode.VIEW || props.viewMode === CalendarEntryModalViewMode.EDIT) &&
                        <SubmitButtonContainerLinks>
                            <ButtonLinks disabled={isExplorationOrPostEventPhase || joinMeetingDisabled || (myParticipationStatus && myParticipationStatus !== CalendarEntryParticipationStatus.ACCEPTED)} onClick={joinMeeting}>{strings.calendarEntryListView.callButtonTitle}</ButtonLinks>
                            <ButtonLinks onClick={chatWithParticipants}>{strings.calendarEntryListView.chatButtonTitle}</ButtonLinks>
                            {/* <ButtonLinks disabled={numberOfAcceptedParticipants() <= 1 || (myParticipationStatus && myParticipationStatus !== CalendarEntryParticipationStatus.ACCEPTED)} onClick={chatWithParticipants}>{strings.calendarEntryListView.chatButtonTitle}</ButtonLinks> */}
                        </SubmitButtonContainerLinks>}
                    {(props.viewMode === CalendarEntryModalViewMode.CREATE || props.viewMode === CalendarEntryModalViewMode.EDIT) &&
                        <SubmitButtonContainer>
                            {props.viewMode === CalendarEntryModalViewMode.CREATE && <DisagreeButton onClick={() => props.close()} className="d-flex align-items-center">{strings.globalTexts.cancel}</DisagreeButton>}
                            {props.viewMode === CalendarEntryModalViewMode.CREATE &&
                                <SubmitButton backgroundColor={branding.sayHelloModal.submitBtnPrimaryBgColor} variant="dark" disabled={createButtonDisabled} onClick={createOrUpdateCalendarEntry}>
                                    {strings.calendarEntryModalPageContent.sendInvite}
                                </SubmitButton>
                            }

                            {props.viewMode === CalendarEntryModalViewMode.EDIT &&
                                <SubmitButtonDanger variant="outline-danger" onClick={() => { setShowConfirmationDialog(true) }} >
                                    {strings.calendarEntryModalPageContent.deleteMeeting}
                                </SubmitButtonDanger>
                            }
                            {props.viewMode === CalendarEntryModalViewMode.EDIT &&
                                <SubmitButton backgroundColor={branding.sayHelloModal.submitBtnPrimaryBgColor} variant="dark" disabled={createButtonDisabled} onClick={createOrUpdateCalendarEntry} >
                                    {strings.calendarEntryModalPageContent.saveChanges}
                                </SubmitButton>
                            }
                        </SubmitButtonContainer>
                    }
                </Modal.Body>
            </div>
        </CalendarEntryMainModal>
        <DeleteModal
            centered
            backdrop="static"
            animation={false}
            show={showConfirmationDialog}
        >
            <Modal.Header>
                <Modal.Title>
                    <DeleteMeetingTitle>{strings.calendarEntryModalPageContent.deleteMeeting}</DeleteMeetingTitle>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body><DeleteMeetingRoot>{strings.calendarEntryModalPageContent.cancelText}</DeleteMeetingRoot></Modal.Body>
            <Modal.Footer>
                <DeleteMeetingRoot>
                    <DeleteButton variant="outline-dark" onClick={() => { setShowConfirmationDialog(false) }}>
                        {strings.calendarEntryModalPageContent.cancel}
                    </DeleteButton>
                    <DeleteButton variant="danger" style={{ backgroundColor: branding.dangerButtonColor }} onClick={deleteCalendarEntry} >
                        {strings.calendarEntryModalPageContent.deleteMeeting}
                    </DeleteButton>
                </DeleteMeetingRoot>
            </Modal.Footer>
        </DeleteModal>
    </>
    )
}

export default CalendarEntryModal



export const useCalendarEntryModal = () => {
    const [isVisible, setIsVisible] = useState(false)
    const [data, setData] = useState<{ organizationId: string, sotUser: InvitePerson[] | undefined }>({ organizationId: "", sotUser: undefined })

    const showCalendarEntryModal = (organizationId: string, sotUser: InvitePerson[] | undefined) => {
        setData({ organizationId: organizationId, sotUser: sotUser })
        setIsVisible(true)
    }

    const CalendarModal = () => (
        ReactDOM.createPortal(<React.Fragment>
            {isVisible && <CalendarEntryModal viewMode={CalendarEntryModalViewMode.CREATE} organization={data.organizationId} sotUser={data.sotUser} close={() => setIsVisible(false)} />}
        </React.Fragment>, document.body)
    )

    return {
        showCalendarEntryModal,
        CalendarModal,
    }
}
