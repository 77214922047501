import React, { ChangeEvent } from "react"
import styled from "styled-components"
import branding from "../branding/branding"

const TextInputAreaContainer = styled.div<{ width: string }>`
    position: relative;
    display:inline-block;
    width: ${props => props.width};
`

const StartInputAdornment = styled.div`
    position: absolute;
    z-index:9999;
    left: 3px;
    bottom: 9px;
`

const EndInputAdornment = styled.div`
    position: absolute;
    z-index: 999;
    right: 3px;
    bottom: 6px;
`

const TextInputArea = styled.input<{ width: string, height: string, fontFamily: string, fontSize: string, containsStartAdornment: boolean, backgroundColor: string, color: string }>`
    border: 0px;
    left: 10px;
    display:inline-block;
    word-break: break-word;
    white-space: pre-line;
    resize: none;
    color: ${props => props.color};
    height: ${props => props.height};
    background-color: ${props => props.backgroundColor};
    width: 100%;
    font-family: ${props => props.fontFamily};
    font-size: ${props => props.fontSize};
    border-bottom: 1px solid ${props => props.color};
    padding-left:  ${props => props.containsStartAdornment ? "30px" : "0px"};
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;    
    box-sizing: border-box;

    &:hover {
        outline: none;
        border-bottom: 2px solid ${props => props.color};
    }

    &:focus {
        outline: none;
        border-bottom: 2px solid ${props => props.color};
    }
`

const MultiLineTextInputArea = styled.textarea<{ width: string, height: string, fontFamily: string, fontSize: string }>`
    border: 0px;
    height: ${props => props.height};
    word-break: break-word;
    white-space: pre-line;
    resize: none;
    color: ${branding.mainInfoColor};
    outline: 1px solid ${branding.mainInfoColor};
    width: ${props => props.width ?? "100%"};
    font-family: ${props => props.fontFamily};
    font-size: ${props => props.fontSize};
    -moz-outline-radius: 5px;

    &:hover {
        outline: 2px solid ${branding.mainInfoColor};
    }

    &:focus {
        outline: 2px solid ${branding.mainInfoColor};
    }
`

interface TextFieldProps {
    rows?: number
    maxLength?: number
    placeholder?: string
    value?: string
    width?: string
    height?: string
    fontFamily?: string
    fontSize?: string
    setValue?: (value: string) => void
    onChange?: (value: string) => void
    startAdornment?: any
    endAdornment?: any
    backgroundColor?: string
    textColor?: string
    search?: boolean
    disabled?: boolean
}

export const TextField: React.FunctionComponent<TextFieldProps> = React.memo((props: TextFieldProps) => {
    return <TextInputAreaContainer width={props.width ?? "100%"}>
        {props.startAdornment && <StartInputAdornment>
            {props.startAdornment}
        </StartInputAdornment>}
        {<TextInputArea disabled={props.disabled} type="text" backgroundColor={props.backgroundColor ?? "transparent"} color={props.textColor ?? branding.mainInfoColor} spellCheck={false} containsStartAdornment={props.startAdornment ?? false} width={props.width ?? "100%"} height={props.height ?? "30px"} fontFamily={props.fontFamily ?? branding.font1} fontSize={props.fontSize ?? "15px"} value={props.value} placeholder={props.placeholder} onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (props.setValue)
                props.setValue(e.target.value)

            if (props.onChange)
                props.onChange(e.target.value)
        }} />}
        {props.endAdornment && <EndInputAdornment>
            {props.endAdornment}
        </EndInputAdornment>}
    </TextInputAreaContainer>
})

export const MultiLineTextField: React.FunctionComponent<TextFieldProps> = React.memo((props: TextFieldProps) => {

    return <MultiLineTextInputArea width={props.width ?? "100%"} height={props.height ?? "70px"} spellCheck={false} fontFamily={props.fontFamily ?? branding.font1} fontSize={props.fontSize ?? "16px"} maxLength={props.maxLength || 1000} rows={props.rows ?? 5} max-rows={10} value={props.value} placeholder={props.placeholder ?? ""} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
        if (props.setValue)
            props.setValue(e.target.value)
    }} >{props.value}</MultiLineTextInputArea>
})